import { PurchaserPortalSettings } from '../purchaser-portal';
import { FileRef, FormCode, FormCodeUnion, SigningPartyType } from '../../property';
import { Maybe } from '@property-folders/common/types/Utility';
import { StandardCondition } from '@property-folders/common/workflow-rules/WorkflowTriggerTemplates';
import { emailRecipientOptions } from '../../property/options';

/**
 * If you're creating a new Y doc, then load this template into it first.
 */
export const EntitySettingsSchemaTemplate = 'AQKHjcfpAQAnAQZFbnRpdHkSbWFya2V0aW5nVGVtcGxhdGVzACcBBkVudGl0eQtzYWxlc3Blb3BsZQAA';

export enum AgencyVendorMap {
  DEFAULT = 0,
  AGENCY = 1,
  VENDOR = 2
}

export enum EntitySettingsRootKey {
  /**
   * Root key for shape defined by {@link EntitySettingsEntity}
   */
  Main = 'Entity'
}

export enum YesNoNoPreference {
  Yes = 'Yes',
  No = 'No',
  NoPreference = 'NoPreference'
}

export type EntitySettingsSalesperson = {
  id: number;
  name: string;
  phone: string;
  email: string;
  tradeName?: string;
  rla?: string;
  abn?: string;
  isSalesperson: boolean;
  isPropertyManager: boolean;
  isAuctioneer: boolean;
  gnafId: string;
  searchesCreditCents?: number;
  whoPaysTransactionFee?: AgencyVendorMap;
  whoPaysForm1PrepFee?: AgencyVendorMap;
  promoteEC?: YesNoNoPreference;
  form1?: {
    serviceFaxOrEmail?: string;
    serviceAddress?: string;
    serviceAddressIsRla?: boolean;
  }
  isAssocAgent?: boolean
  assocCompanyName?: string
  assocAbn?: string
  assocRla?: string
};

export type EntityBrandButtonConfig = {
  foregroundColour: string;
  backgroundColour: string;
};

export type EntityBrandContentConfig = {
  foregroundColour: string;
  backgroundColour: string;
};

export type EntityBrandFormConfig = {
  lineColour: string;
  headingColour: string;
  agencyContact?: AgencyContact;
};

export type EntityBrandSigningConfig = {
  button: EntityBrandButtonConfig;
  remoteCompletion?: {
    borderColour: string;
  }
};

export type EntityBrandEmailConfig = {
  button: EntityBrandButtonConfig;
  content: EntityBrandContentConfig;
};

export type EntityBrandPurchaserRegistrationConfig = {
  page: EntityBrandContentConfig;
  stripe: EntityBrandContentConfig;
  headerImageS3Uri: string;
};

export type EntityBrandPortalConfig = {
  highlightColour: string;
};

export type AgencyContact = {
  agencyEmail: string,
  agencyName: string,
  agencyPhone: string,
  agencyRla: string,
  agencyAddress: {
    singleLine: string,
    parts?: {
      streetAddr?: string,
      suburb?: string,
      postcode?: string,
      state?: string
    }
  }
};

export type EntitySettingsBrand = {
  /**
   * @deprecated use email.button
   */
  button: EntityBrandButtonConfig;
  /**
   * @deprecated use email.content
   */
  content: EntityBrandContentConfig;
  form?: EntityBrandFormConfig;
  email?: EntityBrandEmailConfig;
  signing?: EntityBrandSigningConfig;
  purchaserRegistration?: EntityBrandPurchaserRegistrationConfig;
  portal?: EntityBrandPortalConfig;
  agencyContact?: AgencyContact;
};

export type EntitySettingsSigningOptions = {
  allowPaper?: boolean;
  remindersEnable?: boolean;
  firstReminderDay?: number;
  subsequentReminderDays?: number;
  expiryDays?: number;
  expiryReminderDays?: number;
  requireIdRemote?: boolean;
  requireIdHosted?: boolean;
  defaultIdRemote?: boolean;
  defaultIdHosted?: boolean;
  autoServeForm1?: boolean; // It is worth noting, this doesn't affect the users ability to automatically serve Form 1s. But should it?
  formSpecific?: Record<FormCode, Maybe<GreatformsSigningDefaults>>;
};

export enum WorkflowConditionTriggerType {
  CoolingOffBegun = 'coolingOffBegun',
  CoolingOffWaived = 'coolingOffWaived',
  CoolingOffNotApplicable = 'coolingOffNotApplicable',
  DocumentDistributed = 'documentDistributed'
}

export enum WorkflowConditionType {
  Or = 'or',
  And = 'and',
  Field = 'field',
  MappedField = 'mappedField',
  Document = 'document',
}

export enum Operator {
  Includes = 'includes',
  NotIncludes = 'notIncludes',
  In = 'in',
  NotIn = 'notIn',
}

export const operatorOptions: Record<Operator, string> = {
  [Operator.Includes]: 'includes',
  [Operator.NotIncludes]: 'doesn\'t include',
  [Operator.In]: 'is',
  [Operator.NotIn]: 'is not'
};

export enum MappingFunction {
  planTypes = 'planTypes',
  salespersons = 'salespersons',
}

export type WorkflowConditionLeafType = { id: string, name: StandardCondition, value?: any } & (
    { type: WorkflowConditionType.Field, field: string, operator: Operator }
  | { type: WorkflowConditionType.MappedField, mappingFn: MappingFunction, operator: Operator }
  | { type: WorkflowConditionType.Document, operator: Operator }
  );

export type WorkflowConditionJoinType = { id: string } & (
    { type: WorkflowConditionType.Or, conditions: WorkflowCondition[] }
  | { type: WorkflowConditionType.And, conditions: WorkflowCondition[] }
);

export type WorkflowCondition = | WorkflowConditionJoinType | WorkflowConditionLeafType;

export type WorkflowConditionDefinitionJoinType =
  { type: WorkflowConditionType.Or, conditions: WorkflowConditionDefinition[] }
  | { type: WorkflowConditionType.And, conditions: WorkflowConditionDefinition[] };

export type WorkflowConditionDefinitionLeafType = Omit<WorkflowConditionLeafType, 'id'> & {
  options?: Record<string, string> | 'salespeople';
  operators?: Operator[];
  label?: string;
};

export type WorkflowConditionDefinition = WorkflowConditionDefinitionJoinType | WorkflowConditionDefinitionLeafType;

export enum WorkflowActionType {
  Email = 'email'
}

export type WorkflowActionPartyEnum = 'user' | 'email' | 'team' | 'role';

export type WorkflowActionPartyType = |
  { type: 'role', id: keyof typeof emailRecipientOptions } |
  { type: 'user', id: number } |
  { type: 'team', id: number } |
  { type: 'email', id: string };

export enum EmailLinkType {
  External = 'external',
  File = 'file',
  Document = 'document'
}

export type EmailLink = { url?: string, text?: string, id?: string } & ({ type: EmailLinkType.File, file?: FileRef & { name: string } } | { type: EmailLinkType.External } | { type: EmailLinkType.Document, formFamily?: FormCodeUnion });

export type WorkflowAction = |
  {
    type: WorkflowActionType.Email,
    to: WorkflowActionPartyType[],
    bcc?: WorkflowActionPartyType[],
    subject?: string,
    links?: EmailLink[],
    body?: string,
    smsNotification?: {
      enabled: boolean,
      message?: string
    }
    cta?: {
      enabled: boolean,
      preamble?: string,
      button1?: EmailLink,
      button2?: EmailLink
    }
  };

export type WorkflowRule = {
  id: string,
  name: string,
  modified: number,
  enabled?: boolean,
  settings?: {
    suppressForPaperSignInPast: boolean,
  },
  /**
   * What triggers this rule to be evaluated?
   */
  trigger?: WorkflowConditionTriggerType,
  /**
   * List of conditions that all must be met to process the rule
   */
  conditions?: WorkflowCondition[],
  /**
   * What will happen?
   */
  actions?: WorkflowAction[]
};

export type DisclosureOfBenefitsTemplateItem = {
  id: string;
  nature?: string;
  source?: string;
  amount?: string;
  recipient?: string;
};

export type DisclosureOfBenefitsTemplate = {
  id: string;
  name: string;
  modified: number;
  items: DisclosureOfBenefitsTemplateItem[]
};

export type EmailEvent = {
  id: string;
  entityUuid: string;
  modified: number;
  name: string;
};

export type MarketingTemplate = {
  id: string;
  //old reaforms documentId from migration
  documentId?: number;
  entityUuid: string;
  modified: number;
  name: string;
  headerImage?: FileRef;
  headerImageScale?: ImageScaleType;
  headerText?: string;
  backgroundColour?: string;
  textColour?: string;
  sectionBackgroundColour: string;
  sectionTextColour: string;
  sectionLineColour: string;
  sections: MarketingTemplateSection[];
  users: number[];
};

export type MarketingTemplateSection = {
  id: string;
  defaultState: MarketingTemplateItemState;
  enabled?: boolean;
  locked?: boolean;
  type: MarketingTemplateSectionType;
  name: string;
  backgroundColour?: string;
  textColour?: string;
  lineColour?: string;
  price?: number;
  items?: MarketingTemplateItem[]
};

export enum MarketingTemplateSectionType {
  individual = 'individual',
  package = 'package'
}

export enum MarketingTemplateItemState {
  unchecked = 'unchecked',
  checked = 'checked',
  required = 'required'
}

export enum ImageScaleType {
  none = 'none',
  fit = 'fit',
  stretch = 'stretch'
}

export type MarketingTemplateItem = {
  id: string;
  defaultState: MarketingTemplateItemState;
  enabled: boolean;
  locked: boolean;
  description: string;
  price: number;
};

export type EntitySettingsEntity = {
  entityId: number;
  name: string;
  tradeName: string;
  address1: string;
  address2: string;
  addressDisplay: string;
  suburb: string;
  state: string;
  postcode: string;
  rla: string;
  abn: string;
  phone: string;
  mobile: string;
  email: string;
  profileName: string;
  fax: string;
  logoToShow: number;
  logoS3Uri: string;
  logoMd5Hash: string;
  logoX?: number;
  logoY?: number;
  emailLogoS3Uri?: string; // null means use other logo, empty string means don't use a logo
  emailLogoX?: number;
  emailLogoY?: number;
  lastLogoUpdateInSession?: number;
  brand?: EntitySettingsBrand;
  useNewPropertyTransactions: boolean;
  usePfRemoteCompletion?: boolean;
  teamManagementVisible?: boolean;
  useMyFiles?: boolean;
  useSigningPortal?: boolean;
  campaignAgentVpa?: boolean;
  shortSAA?: boolean;
  salespeople: EntitySettingsSalesperson[] | null;
  gnafId?: string;
  signingOptions: EntitySettingsSigningOptions;
  purchaserPortalSettings: PurchaserPortalSettings;
  enableRealtyAssist?: boolean;
  reaformsTransactionFeeCents?: number;
  searchesCreditCents?: number;
  whoPaysTransactionFee?: AgencyVendorMap;
  whoPaysForm1PrepFee?: AgencyVendorMap;
  waiveTransactionFee?: boolean;
  promoteEC?: boolean;
  form1?: {
    company?: string;
    address?: string;
    serviceFaxOrEmail?: string;
    serviceAddress?: string;
    serviceAddressIsRla?: boolean;
  }
  /**
   * @deprecated
   */
  showLegalLandDescriptionOverride?: boolean;
  /**
   * @deprecated
   */
  marketingTemplateDisableFirstRow?: boolean;
  archived?: boolean;
  epfAgencyId?: number;
  workflowRules?: WorkflowRule[];
  dobTemplates?: DisclosureOfBenefitsTemplate[];
  dobDefaultTemplateId?: string;
  requireComparableSales?: boolean;
  marketingTemplates?: MarketingTemplate[];
  integrationEnabled?: boolean;
  trustAccount?: {
    name?: string;
    bsb?: string;
    number?:string;
  }
};

export type GreatformsSigningDefaults = {
  enable?: SigningPartyType[];
  disable?: SigningPartyType[];
  default?: SigningPartyType;
  automaticDistribution?: boolean;
  notificationBlock?: boolean;
  noVoidNotify?: boolean;
};
