
import { AgencyContact, EntityBrandFormConfig, EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { footerCompanyName } from '../formatting';
import { defaultFormBrandConfig } from './constants';

// In general we would prefer this get used, as this doesn't have to be updated in the ydoc, if the
// agent session has been updated, we will render the new details
export function deriveAgencyDataFromEntitySettings(data?: EntitySettingsEntity): AgencyContact | undefined {
  if (!data) return undefined;

  return {
    agencyEmail: data?.email ?? '',
    agencyName: footerCompanyName(data?.name, data?.tradeName) ?? '',
    agencyPhone: data?.phone ?? '',
    agencyRla: data?.rla ?? '',
    agencyAddress: {
      singleLine: data?.addressDisplay,
      parts: {
        streetAddr: data.address1,
        state: data.state,
        postcode: data.postcode,
        suburb: data.suburb
      }
    }
  };
}

export function deriveFormBrandAgencyFromSettings(data: undefined): EntityBrandFormConfig;
export function deriveFormBrandAgencyFromSettings(data: EntitySettingsEntity): EntityBrandFormConfig;
export function deriveFormBrandAgencyFromSettings(data: EntitySettingsEntity | undefined): EntityBrandFormConfig;
export function deriveFormBrandAgencyFromSettings(data: EntitySettingsEntity | undefined): EntityBrandFormConfig {
  const contactIfAvailable = data ? { agencyContact: deriveAgencyDataFromEntitySettings(data) } : {};
  const rval = {
    ...contactIfAvailable,
    ...(data?.brand?.form || defaultFormBrandConfig)
  };
  return rval;
}