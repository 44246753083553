import { MaterialisedPropertyData } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';

export const settlementSetReset: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData, variationsSnapshot, history, previousState, furtherProps) => {
  if (!updatedPath.startsWith('contractSettlement')) return;
  if (!immerProxy.contractSettlement) return;
  const pathSegs = normalisePathToStrArray(updatedPath);
  const changedCheck = pathSegs[1];
  const newValue = getValueByPath(pathSegs, immerProxy);
  if (!newValue) return; // Only interested when something is switched on
  switch (changedCheck) {
    case 'noPref':
      immerProxy.contractSettlement.onCondition = false;
      immerProxy.contractSettlement.onDate = false;
      immerProxy.contractSettlement.onContract = false;
      immerProxy.contractSettlement.other = false;
      break;
    case 'onCondition':
    case 'onDate':
    case 'onContract':
    case 'other':
      immerProxy.contractSettlement.noPref = false;
      break;
  }

};
