import { AuthorityParty,
  InstanceHistory,
  MaterialisedPropertyData,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { SignatureSection } from '@property-folders/common/util/pdfgen/sections/signatureSection';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Binder } from 'immer-yjs/src/immer-yjs';
import { FormUtil } from '@property-folders/common/util/form';
import { Maybe } from '@property-folders/common/types/Utility';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';
import { Predicate } from '@property-folders/common/predicate';
import { propertyFolderMaskDataNotRelevant, transformPropertyFolderDataForDisplay } from '@property-folders/common/util/pdfgen/display-transformations';
import { preparePartySigningDiff } from './sales-agreement-variation-pdf';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { residentialSalesContractVariation } from '@property-folders/common/util/pdfgen/definitions';
import { CustomObjects, DefinitionMode, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { BelongingEntityMeta } from '../../../../redux-reducers/entityMeta';

type SourceAuthorityMap = Map<SigningPartySourceType, AuthorityParty[]>;

export function mergeSigningMaps(...maps: SourceAuthorityMap[]) {
  const keys = maps.map(map=>[...map.keys()]).flat();
  const result: SourceAuthorityMap = new Map();
  for (const mergeKey of keys) {
    const vals = maps.map(map=>map.get(mergeKey)).flat().filter(Predicate.isTruthy);
    result.set(mergeKey, vals);
  }
  return result;
}

export class SaleContractVariationPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<Binder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean,
  ) { }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  getCoverPage() {
    return Promise.resolve(undefined);
  }

  async getCoverPageDefinitionForPdfWorker(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    lastSignedSnapData?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride: TransactionMetaData
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts ?? {};
    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const familyState = FormUtil.getFormFamilyState(this.formCode, meta);
    const formInstance = FormUtil.getFormInstanceFromFamilyState(familyState, this.formId);
    const clausesId = familyState?.clauseChildId;
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formInstance) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const previousInstance = snapshotHistory?.instanceList?.[snapshotHistory.instanceList.length-1];

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const { adding: addVend, existing: existVend, removing: removeVend } = preparePartySigningDiff('vendors', property, lastSignedSnapData, SigningPartySourceType.Vendor);
    const { adding: addPurchase, existing: existPurchase, removing: removePurchase } = preparePartySigningDiff('purchasers', property, lastSignedSnapData, SigningPartySourceType.Purchaser);

    const existing = mergeSigningMaps(existVend, existPurchase);
    const removing = mergeSigningMaps(removeVend, removePurchase);
    const adding = mergeSigningMaps(addVend, addPurchase);

    const signers = mode === DefinitionMode.Signing
      ? await SignatureSection.buildSignersFromSigningSession(formInstance, propertyRaw, { previousInstance })
      : await SignatureSection.buildSignersForPreview({
        authorityParties: existing,
        removedAuthorityParties: removing,
        addedAuthorityParties: adding,
        previousInstance,
        propertyData: propertyRaw,
        formCode: this.formCode,
        instHistory: snapshotHistory,
        previousData: lastSignedSnapData,
        memberEntities
      });

    return {
      clausesId,
      formType: 'ResidentialSalesContractVariationPDF',
      signers,
      previousFormInstance: undefined,
      objects,
      changeSet,
      property,
      propertyRaw,
      lastSignedSnapData,
      brand,
      annexures,
      snapshotHistory,
      noBoldContentMode: noBoldContentMode??false,
      memberEntities,
      formFamilyState: familyState,
      currentFormInstance: formInstance
    };
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    lastSignedSnapData?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions> {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      lastSignedSnapData,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );

    return residentialSalesContractVariation(definition);
  }
}
