import { padCanvas } from './pad-canvas';
import { PaddingConfig } from './padding-config';
import { trimCanvas } from './trim-canvas';
import { Canvas, CanvasRenderingContext2D, NodeCanvasRenderingContext2DSettings, createCanvas } from 'canvas';
import { removeCanvas } from './remove-canvas';

type CleanupFn = () => void;
export type VirtualCanvas = { width: number, height: number };
type RenderFn = (
  virtualCanvas: VirtualCanvas,
  context: CanvasRenderingContext2D,
  // function which takes a function for execution during cleanup phase
  submitCleanupJob: (cleanupFn: CleanupFn) => void,
  realCanvas: Canvas
) => void;
export function renderWithCanvasContext(renderFn: RenderFn, opts?: {
  width?: number,
  height?: number,
  trim?: boolean,
  padding?: PaddingConfig,
  /**
   * for supersampling. if not specified, it is effectively 1
   */
  scale?: number
}) {
  const canvas = createCanvas(opts?.width || 300, opts?.height || 300);

  // the real canvas will be scaled as specified,
  // and its width/height shouldn't be used for calculating layouts in the render function.
  // so, capture pre-scaled dimensions for render functions to reference.
  const virtualCanvas: VirtualCanvas = {
    width: canvas.width,
    height: canvas.height
  };

  const scale = opts?.scale ?? 1;
  canvas.width = Math.floor(canvas.width * scale);
  canvas.height = Math.floor(canvas.height * scale);

  const cleanups: CleanupFn[] = [];
  try {
    const context = canvas.getContext('2d', { willReadFrequently: true } as NodeCanvasRenderingContext2DSettings);
    if (!context) {
      return undefined;
    }

    context.scale(scale, scale);

    renderFn(virtualCanvas, context, (fn: CleanupFn) => cleanups.push(fn), canvas);

    context.scale(1, 1);

    if (opts?.trim) {
      trimCanvas(canvas, context);
    }

    if (opts?.padding) {
      padCanvas(canvas, context, scale !== 1
        ? {
          left: opts.padding.left * scale,
          right: opts.padding.right * scale,
          top: opts.padding.top * scale,
          bottom: opts.padding.bottom * scale,
          toWidth: opts.padding.toWidth ? opts.padding.toWidth * scale : undefined,
          toHeight: opts.padding.toHeight ? opts.padding.toHeight * scale : undefined,
          vCenter: opts.padding.vCenter
        }
        : opts.padding);
    }

    return canvas.toDataURL('image/png');
  } finally {
    removeCanvas(canvas);
    for (const cleanupFn of cleanups) {
      try {
        cleanupFn();
      } catch { /**/ }
    }
  }
}
