import { MappingFunction } from '@property-folders/contract/yjs-schema/entity-settings';
import { MaterialisedPropertyData } from '@property-folders/contract';

export const EmailTemplateRuleMappingFunctions = {
  [MappingFunction.planTypes]: (property: MaterialisedPropertyData) => {
    return [...new Set(property?.saleTitles?.flatMap(title => {
      return title?.descriptionOfLand?.map(d => d.plan?.planType);
    }))];
  },
  [MappingFunction.salespersons]: (property: MaterialisedPropertyData) => {
    return property?.agent?.flatMap(a => a?.salesp?.flatMap(sp => sp.linkedSalespersonId));
  }
};
