import { FormCode, MaterialisedPropertyData, VPAIncluded } from '@property-folders/contract';
import { canonicalisers } from './formatting';

export const noGreatformsCost = (props: {
  property: MaterialisedPropertyData|undefined
} | {
  whoSearches: string | undefined,
  waiveFee: boolean | undefined
}) => {
  const whoSearches = 'property' in props ? props?.property?.form1AndSearches?.whoSearches : props.whoSearches;
  const waiveFee = 'property' in props ? props?.property?.transactionFee?.waiveFee : props.waiveFee; // This is a denormalisation set in the TransactionFeeSection
  const useEpfToOrderSearches = whoSearches === 'eckermanns';
  return waiveFee || useEpfToOrderSearches;
};

export function getTotalForVPA({
  property, included = {}
}: {
  property: MaterialisedPropertyData|undefined,
  included: VPAIncluded
}) {
  let runningTotal = 0;
  const robj = {
    marketing: { cost: NaN, isAvailable: false, isIncluded: false },
    adminFee: { cost: NaN, isAvailable: false, isIncluded: false },
    auctionFee: { cost: NaN, isAvailable: false, isIncluded: false },
    greatformsFee: { cost: NaN, isAvailable: false, isIncluded: false },
    epfFee: { cost: NaN, isAvailable: false, isIncluded: false }
  };
  if (!property?.id) {
    return {
      initial: true,
      total: runningTotal,
      ...robj
    };
  }
  if (typeof property?.marketingTemplate?.id === 'string' || (!property.marketingFeesOptions?.noFees && !property.marketingFeesOptions?.externalFeeScheduleAnnexureRef?.[`family_${FormCode.RSAA_SalesAgencyAgreement}`])) {
    const cstruct = canonicalisers.audWithNegative(property?.marketingFeesOptions?.denormalisedTotalValidation);
    const cost = cstruct.valid ? cstruct.canonical : NaN;
    robj.marketing.isAvailable = true;
    robj.marketing.cost = cost;
    if (included.marketing) {
      runningTotal += cost; // We want it to be invalid if they selected it
      robj.marketing.isIncluded = true;
    }
  }

  if (property.adminFee?.enable && property.adminFee.setPrice) {
    const cstruct = canonicalisers.audWithNegative(property.adminFee.setPrice);
    const cost = cstruct.valid ? cstruct.canonical : NaN;
    robj.adminFee.cost = cost;
    robj.adminFee.isAvailable = true;
    if (included.adminFee) {
      runningTotal += cost;
      robj.adminFee.isIncluded = true;
    }
  }
  if (property.auctionFeeSwitches?.applicable && property.auctionFee) {
    const cstruct = canonicalisers.audWithNegative(property.auctionFee);
    const cost = cstruct.valid ? cstruct.canonical : NaN;
    robj.auctionFee.cost = cost;
    robj.auctionFee.isAvailable = true;
    if (included.auctionFee) {
      runningTotal += cost;
      robj.auctionFee.isIncluded = true;
    }
  }

  if (!noGreatformsCost({ property }) && property?.transactionFee?.transactionFeeAmountCents) {
    const cstruct = canonicalisers.audWithNegative(property?.transactionFee?.transactionFeeAmountCents/100);
    const rawCost = cstruct.valid ? cstruct.canonical : NaN;
    const cost = typeof rawCost === 'string' ? parseFloat(rawCost): rawCost;
    robj.greatformsFee.cost = cost;
    robj.greatformsFee.isAvailable = true;
    if (included.greatformsFee) {
      runningTotal += cost;
      robj.greatformsFee.isIncluded = true;
    }
  }

  if (property.form1AndSearches?.whoSearches === 'eckermanns') {
    const cost = Number(property.form1AndSearches.lastEpfAutoQuote);
    robj.epfFee.cost = cost;
    robj.epfFee.isAvailable = true;
    if (included.epfFee) {
      runningTotal += cost;
      robj.epfFee.isIncluded = true;
    }
  }

  return {
    total: runningTotal,
    ...robj
  };
}

type a = ReturnType<typeof getTotalForVPA>;