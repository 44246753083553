// If the current value and the target value are both lists, they must not
// contain any common items. The common evaluator might be settable in future

import { PathType } from '@property-folders/contract/yjs-schema/model';
import { CustomPointValidationFunction } from '@property-folders/contract/yjs-schema/model/custom-point-validator';
import { getValueByPath } from '../../../../util/pathHandling';

// but for now we will compare them with a standard equality
export const noCommonValues: CustomPointValidationFunction = (
  nodeTree,
  updatedPath,
  params?: {otherListPath: PathType}
) => {
  const thisList = getValueByPath(updatedPath, nodeTree, true);
  if (!params?.otherListPath) return [];
  const otherList = getValueByPath(params.otherListPath, nodeTree);

  if (!(Array.isArray(thisList) && Array.isArray(otherList))) return [];
  const baseSet = new Set(thisList);
  for (const val of otherList) {
    if (baseSet.has(val)) return ['hasCommonValues'];
  }
  return [];
};