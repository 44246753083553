import { ContractScheduleType, Exclusions, InclExclOptions, Inclusions, PoolCompliance, TenantAgreement } from '@property-folders/contract';
import { itemSection } from '..';
import { combinedInclusionsExclusionsSection, encroachSection, encumbrancesSection, gstWithholdingSection, notiWorksSection, poolSection, tenantSection, vendorWorksSection } from './particularsSection';

export function contractParticularsSection(
  itemNo: number,
  chattelObj: Inclusions | undefined,
  exclusionObj: Exclusions| undefined,
  tenant: object | undefined,
  encroach: string | undefined,
  notiWorks: string | undefined,
  vendorWorks: string | undefined,
  cgtEnable: boolean | undefined,
  gstWithholdEnable: boolean | undefined,
  poolCompliance: PoolCompliance | undefined,

  depositPayAt: string | undefined,
  encroachEnable: boolean | undefined,
  notiWorksEnable: boolean | undefined,
  vendorWorksEnable: boolean | undefined,
  moreData: {
    tenantList: TenantAgreement[] | undefined,
    encumbrances: ContractScheduleType | undefined,
    inclExclOpts: InclExclOptions
  }
) {
  const sectionItems = [];
  sectionItems.push(...combinedInclusionsExclusionsSection({ inclusions: chattelObj, exclusions: exclusionObj, inclExclOpts: moreData?.inclExclOpts },{ contractMode: true }));

  sectionItems.push(...encroachSection({ enable: encroachEnable, content: encroach }));
  sectionItems.push(...notiWorksSection({ enable: notiWorksEnable, content: notiWorks }));
  sectionItems.push(...vendorWorksSection({ enable: vendorWorksEnable, content: vendorWorks }));
  sectionItems.push(...poolSection(poolCompliance?.present, poolCompliance?.complyState, poolCompliance?.nonComplyWorks, { contractMode: true }));
  sectionItems.push(...tenantSection(tenant, moreData.tenantList, true));
  sectionItems.push(...gstWithholdingSection({ gstWithholdEnable }, { contractMode: true }));
  sectionItems.push(...encumbrancesSection({ encumbrances: moreData.encumbrances }));

  return itemSection({ itemNo: itemNo, itemTitleParam: 'Property and Sale Particulars'.toUpperCase(), bookmark: 'bookmark_contractSchedule', stackContent: sectionItems });
}
