import { PDFDocument, PDFFont } from '@cantoo/pdf-lib';
import { fromBase64 } from 'lib0/buffer';
import {
  courierPrimeRegular,
  dejaVuSansCheckmarks,
  openSansRegular,
  playfairDisplayRegular,
  robotoRegular
} from '../pdfgen/fonts';
import fontkit from '@pdf-lib/fontkit';

export class FontEmbedder {
  private loadedFonts = new Map<string, PDFFont>();
  private fkEmbedded = false;

  constructor(
    private pdf: PDFDocument
  ) {}

  private initFontkit() {
    if (this.fkEmbedded) return;
    this.pdf.registerFontkit(fontkit);
    this.fkEmbedded = true;
  }

  public async embed (name?: string, subset?: boolean) {
    const key = mapFontName(name);
    const existing = this.loadedFonts.get(key);
    if (existing) return existing;

    console.log('embed new font', name, subset, key);
    const font = await this.embedInPdf(key, subset || false);
    this.loadedFonts.set(key, font);
    return font;
  }

  public async embedBatch(fonts: {name?: string, subset: boolean }[])  {
    console.log('embedBatch raw', fonts);
    // per font, the goal is to only embed its subset if all requests for that font are to be subsetted.
    const embedList = new Map<SupportedFontFamily, boolean>();
    for (const { name, subset } of fonts) {
      const key = mapFontName(name);
      if (!embedList.has(key) || embedList.get(key) === true) {
        embedList.set(key, subset);
      }
    }

    console.log('embedBatch embedding...', [...embedList.entries()]);
    for (const [key, subset] of embedList.entries()) {
      this.loadedFonts.set(
        key,
        await this.embedInPdf(key, subset));
    }
  }

  private embedInPdf(key: SupportedFontFamily, subset: boolean) {
    this.initFontkit();
    return this.pdf.embedFont(getFontData(key), { subset });
  }
}

export enum SupportedFontFamily {
  RobotoRegular = 'Roboto-Regular',
  OpenSansRegular = 'OpenSans-Regular',
  CourierPrimeRegular = 'CourierPrime-Regular',
  PlayfairDisplayRegular = 'PlayfairDisplay-Regular',
  DejaVuSansCheckmarks = 'DejaVuSans-Checkmarks',
}

export function mapFontName(source?: string | SupportedFontFamily): SupportedFontFamily {
  switch (source?.toLowerCase()) {
    case 'courier':
    case 'courierprime':
    case 'courier prime':
    case 'courier new':
    case SupportedFontFamily.CourierPrimeRegular.toLowerCase():
      return SupportedFontFamily.CourierPrimeRegular;
    case 'open-sans':
    case 'open sans':
    case SupportedFontFamily.OpenSansRegular.toLowerCase():
      return SupportedFontFamily.OpenSansRegular;
    case 'playfair':
    case 'playfair display':
    case 'playfair-display':
    case SupportedFontFamily.PlayfairDisplayRegular.toLowerCase():
      return SupportedFontFamily.PlayfairDisplayRegular;
    case 'dejavusans-checkmarks':
    case SupportedFontFamily.DejaVuSansCheckmarks.toLowerCase():
      return SupportedFontFamily.DejaVuSansCheckmarks;
    case 'roboto':
    case SupportedFontFamily.RobotoRegular.toLowerCase():
    default:
      return SupportedFontFamily.RobotoRegular;
  }
}

export function getFontData(name: SupportedFontFamily, forceTransformFn?: (b64: string) => Uint8Array): Uint8Array {
  const encodedFontData = getEncodedFontData(name);
  return (forceTransformFn??fromBase64)(encodedFontData);
}

function getEncodedFontData(name: SupportedFontFamily): string {
  switch (name) {
    case SupportedFontFamily.OpenSansRegular:
      return openSansRegular;
    case SupportedFontFamily.CourierPrimeRegular:
      return courierPrimeRegular;
    case SupportedFontFamily.PlayfairDisplayRegular:
      return playfairDisplayRegular;
    case SupportedFontFamily.DejaVuSansCheckmarks:
      return dejaVuSansCheckmarks;
    default:
    case SupportedFontFamily.RobotoRegular:
      return robotoRegular;
  }
}
