import {
  AdminFeeType,
  ItemiserItem,
  FeeCalcMechanism,
  PaymentTermsType,
  PressBudgetType,
  ProfessionalFeeType,
  UpliftType,
  FeeScaleType,
  FixedFeePayableWhen, FeeEnables
} from '@property-folders/contract';
import { Predicate } from '../../../predicate';
import {
  fieldFocus,
  itemSubsection,
  itemSection,
  lineIfEmpty,
  spaceStackLinesSideEffect, fieldsSpacing, generateCheckboxRows, drawUnderline
} from '..';
import { FieldPlaceholderStyle } from '../standards';

import type { FieldPlaceholderStyleValue } from '../standards';
import { canonicalisers } from '../../formatting';

function slidingScaleRows(scale: FeeScaleType, fieldPlaceholder: FieldPlaceholderStyleValue) {
  const rows = scale.levels;
  const renderedRows: {text: any[]}[] = rows.map((row, i, all) => {
    const previousRow = all[i-1];
    const previousLimitCanon = canonicalisers.aud(previousRow?.limit);
    const previousLimit = previousLimitCanon.valid ? canonicalisers.aud(previousLimitCanon.canonical + 0.01).display : null;

    if (i >= all.length-1) {
      return { text: [
        'From ',
        previousLimit? { text: previousLimit, style: 'content' } : drawUnderline(FieldPlaceholderStyle.Price),
        ' and above ',
        { text: row.amount ? { text: row.amount, style: 'content' } : drawUnderline(fieldPlaceholder) },
        ' including GST.'
      ] };
    } else if (i === 0) {
      return { text: [
        'Up to ',
        row.limit ? { text: row.limit, style: 'content' } :drawUnderline(FieldPlaceholderStyle.Price),
        '  ',
        row.amount ? { text: row.amount, style: 'content' } : drawUnderline(fieldPlaceholder),
        ' including GST;']
      };
    } else {
      return { text: [
        'From ',
        previousLimit ? { text: previousLimit, style: 'content' } : drawUnderline(FieldPlaceholderStyle.Price),
        ' up to ',
        row.limit ? { text: row.limit, style: 'content' } :drawUnderline(FieldPlaceholderStyle.Price),
        '  ',
        { text: row.amount ? { text: row.amount, style: 'content' } : drawUnderline(fieldPlaceholder) },
        ' including GST;'
      ] };
    }

  });
  return renderedRows;

}

function proFeeNoMode (
  mode: FeeCalcMechanism | undefined,
  values: {
    discount: string | undefined,
    lowerOrNormal: string | undefined,
    upper: string | undefined,
  },
  texts: {
    none: string,
    set: string,
    rangeSuffix: string,
    scaleDesc1: string,
    scaleDesc2: string,
  },
  fieldPlaceholder: FieldPlaceholderStyleValue,
  enables: FeeEnables = { set: true, range: true }
) {
  const { discount, lowerOrNormal, upper } = values;
  const rangeMode = mode === FeeCalcMechanism.Range;
  const setMode = mode === FeeCalcMechanism.Set;
  const discountMode = mode === FeeCalcMechanism.Discounted;

  const RangeStyle = rangeMode ? 'content' : undefined;
  const SetStyle = setMode ? 'content' : undefined;
  const DiscountStyle = discountMode ? 'content' : undefined;
  const allEnabled = Object.values(enables).every(a=>!a);

  return {
    stack: spaceStackLinesSideEffect([
      generateCheckboxRows([
        ((allEnabled || enables.set) && {
          other: false,
          selectMatch: FeeCalcMechanism.Set.toString(),
          label: {
            text: [
              { text: texts.set + ' ', style: SetStyle },
              { text: setMode && lowerOrNormal ? lowerOrNormal : drawUnderline(fieldPlaceholder), style: 'content' },
              ' (inc GST)'
            ]
          }
        }),
        ((allEnabled || enables.discount) && {
          other: false,
          selectMatch: FeeCalcMechanism.Discounted.toString(),
          label: {
            text: [
              { text: 'Normally ', style: DiscountStyle },
              { text: discountMode && lowerOrNormal ? lowerOrNormal : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' discounted to ', style: DiscountStyle },
              { text: rangeMode && discount ? discount : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' (inc GST)' }

            ]
          }
        }),
        ((allEnabled || enables.range) && {
          other: false,
          selectMatch: FeeCalcMechanism.Range.toString(),
          label: {
            text: [
              { text: 'Range ', style: RangeStyle },
              { text: rangeMode && lowerOrNormal ? lowerOrNormal : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' (inc GST) to ', style: RangeStyle },
              { text: rangeMode && upper ? upper : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' '+texts.rangeSuffix }
            ]
          }
        }),
        ((allEnabled || enables.scale) && {
          other: false,
          selectMatch: FeeCalcMechanism.Scale.toString(),
          label: {
            text: [
              { text: 'Sliding scale (detailed below)' }
            ]
          }
        })
      ].filter(Predicate.isTruthy), Predicate.isNotNullish(mode) ? [mode.toString()] : [], 1),
      ((allEnabled || enables.scale) && { text: `Should Sliding scale be selected, the Vendor and Agent agree that the ${texts.scaleDesc1} payable by the Vendor to the Agent will be determined by the total sale price of the Property (including GST if applicable). Based on the total sale price of the Property (including GST if applicable), the ${texts.scaleDesc2} shall be as follows:` }),
      ((allEnabled || enables.scale) && {
        stack: spaceStackLinesSideEffect([

          { text: ['Up to ', drawUnderline(FieldPlaceholderStyle.Price), '  ', drawUnderline(fieldPlaceholder, ), ' including GST;'] },
          { text: ['From ', drawUnderline(FieldPlaceholderStyle.Price), ' up to ', drawUnderline(FieldPlaceholderStyle.Price), '  ', drawUnderline(fieldPlaceholder), ' including GST;'] },
          { text: ['From ', drawUnderline(FieldPlaceholderStyle.Price), ' and above ', drawUnderline(fieldPlaceholder), ' including GST.'] }
        ], 1)
      })
    ].filter(Predicate.isTruthy))
  };
}

function rangeOneLiner (
  lower: string | undefined,
  upper: string | undefined,
  texts: {suffix: string, lowerSuffix?: string;},
  fieldPlaceholder: FieldPlaceholderStyleValue,
) {
  return {
    text: [
      { text: lower ? lower : drawUnderline(fieldPlaceholder), style: 'content' },
      { text: ` ${texts.lowerSuffix ?? ''}to ` },
      { text: upper ? upper : drawUnderline(fieldPlaceholder), style: 'content' },
      { text: ' '+texts.suffix }
    ]
  };
}

function proFeeSection(professFee?: ProfessionalFeeType, uplift?: UpliftType, isVariation?: boolean) {
  const {
    fixed,
    fixedUpper,
    commis,
    commisUpper,
    fixedMode,
    commissionMode,
    commisDiscount,
    fixedDiscount,
    commisScale,
    fixedScale,
    fixedPayableWhen: fixedPayableWhenRaw,
    enabledModes
  } = professFee || {};
  const sectionItems = [{
    text: 'The Vendor will pay the agreed Professional Fees to the Agent on the terms for services and/or Commissions as set out below and in accordance with General Condition 9.',
    lineHeight: 1.2
  }];

  const fixedPayableWhen = fixedMode === FeeCalcMechanism.Scale ? FixedFeePayableWhen.SaleEffected : fixedPayableWhenRaw;

  const fixedNoneOrUnset = !fixedMode || fixedMode === FeeCalcMechanism.None;
  const commisNoneOrUnset = !commissionMode || commissionMode === FeeCalcMechanism.None;

  const bothUnset = fixedNoneOrUnset && commisNoneOrUnset;
  const bothNotVisible = enabledModes?.fixedFee?.none && enabledModes?.commis?.none;

  const commissionSubsection = () => {
    const subsectionItems = [];
    switch (commissionMode) {
      case FeeCalcMechanism.Set: {
        subsectionItems.push({
          text: [
            { text: commis ? commis : drawUnderline(FieldPlaceholderStyle.Percentage), style: 'content' },
            ' (inc GST)'
          ]
        });
        break;
      }
      case FeeCalcMechanism.Discounted: {
        if (commis && commisDiscount) {
          subsectionItems.push(
            {
              text: [
                {
                  text: commis ? commis : drawUnderline(FieldPlaceholderStyle.Percentage),
                  decoration: 'lineThrough'
                }, ' ', {
                  text: commisDiscount ? commisDiscount : drawUnderline(FieldPlaceholderStyle.Percentage),
                  bold: true
                }, ' (Inc GST)']
            },
          );
        } else {
          subsectionItems.push(
            {
              text: ['Normally ', {
                text: commis ? commis : drawUnderline(FieldPlaceholderStyle.Percentage),
                style: 'content'
              }, ' discounted to ', {
                text: commisDiscount ? commisDiscount : drawUnderline(FieldPlaceholderStyle.Percentage),
                style: 'content'
              }, ' (Inc GST)']
            },
          );
        }
        break;
      }
      case FeeCalcMechanism.Range: {
        subsectionItems.push(
          rangeOneLiner(commis, commisUpper, {
            suffix: '(inc GST) at Vendor’s discretion, to be determined at time of Sale, and to be calculated on the total sale price of the Property.',
            lowerSuffix: '(inc GST) '
          },
          FieldPlaceholderStyle.Percentage)
        );
        break;
      }
      case FeeCalcMechanism.Scale: {
        subsectionItems.push({
          stack: spaceStackLinesSideEffect([
            { text: 'The Vendor and Agent agree that the Commission percentage payable by the Vendor to the Agent will be determined by the total sale price of the Property (including GST if applicable). Based on the total sale price of the Property (including GST if applicable), the Commission percentage shall be as follows:' },
            { stack: spaceStackLinesSideEffect(slidingScaleRows(commisScale, FieldPlaceholderStyle.Percentage), 1) }
          ])
        });
        break;
      }
      default: {
        if (bothUnset) {
          subsectionItems.push(proFeeNoMode(
            commissionMode,
            { discount: commisDiscount, lowerOrNormal: commis, upper: commisUpper },
            {
              none: 'No Commission',
              set: 'Set %',
              rangeSuffix: '(inc GST) at Vendor’s discretion',
              scaleDesc1: 'Commission percentage',
              scaleDesc2: 'Commission percentage'
            },
            FieldPlaceholderStyle.Percentage,
            bothNotVisible ? undefined : enabledModes?.commis
          ));
        }
      }
    }

    return subsectionItems;
  };

  const fixedFeeSubsection = () => {
    const subsectionItems = [];

    switch (fixedMode) {
      case FeeCalcMechanism.Set: {
        subsectionItems.push({
          text: [
            { text: fixed ? fixed : drawUnderline(FieldPlaceholderStyle.Price), style: 'content' },
            ' (inc GST)'
          ]
        });
        break;
      }
      case FeeCalcMechanism.Discounted: {
        if (fixed && fixedDiscount) {
          subsectionItems.push(
            {
              text: [
                {
                  text: fixed ? fixed : drawUnderline(FieldPlaceholderStyle.Price),
                  decoration: 'lineThrough'
                }, ' ', {
                  text: fixedDiscount ? fixedDiscount : drawUnderline(FieldPlaceholderStyle.Percentage),
                  bold: true
                }, ' (Inc GST)']
            },
          );
        } else {
          subsectionItems.push(
            {
              text: ['Normally ', {
                text: fixed ? fixed : drawUnderline(FieldPlaceholderStyle.Price),
                style: 'content'
              }, ' discounted to ', {
                text: fixedDiscount ? fixedDiscount : drawUnderline(FieldPlaceholderStyle.Price),
                style: 'content'
              }, ' (Inc GST)']
            },
          );
        }
        break;
      }
      case FeeCalcMechanism.Range: {
        subsectionItems.push(
          rangeOneLiner(fixed, fixedUpper, {
            suffix: `(inc GST) at Vendor’s discretion${fixedPayableWhen === FixedFeePayableWhen.SaleEffected ? '' : ', to be determined at the earlier of time of Sale, or expiry of the Term'}.`,
            lowerSuffix: '(inc GST) '
          },
          FieldPlaceholderStyle.Price)
        );
        break;
      }
      case FeeCalcMechanism.Scale: {
        subsectionItems.push({
          stack: spaceStackLinesSideEffect([
            { text: 'The Vendor and Agent agree that the Fixed Fee payable by the Vendor to the Agent will be determined by the total sale price of the Property (including GST if applicable). Based on the total sale price of the Property (including GST if applicable), the Fixed Fee shall be as follows:' },
            { stack: spaceStackLinesSideEffect(slidingScaleRows(fixedScale, FieldPlaceholderStyle.Price), 1) }
          ])
        });

        break;
      }
      default: {
        if (bothUnset) {
          subsectionItems.push(proFeeNoMode(
            fixedMode,
            { discount: fixedDiscount, lowerOrNormal: fixed, upper: fixedUpper },
            {
              none: 'No Fixed Fee',
              set: 'Set Amount',
              rangeSuffix: '(inc GST) at Vendor’s discretion',
              scaleDesc1: 'Fixed Fee',
              scaleDesc2: 'Fixed Fee'
            },
            FieldPlaceholderStyle.Price,
            bothNotVisible ? undefined : enabledModes?.fixedFee
          ));
        }
      }
    }

    if ((!fixedNoneOrUnset || bothUnset) && !enabledModes?.fixedFee?.none) {
    // Checking fixedNoneOrUnset again may look contradictory, but it's an or, and the case
    // we're avoiding is when someone is unsetting both fee types, but left the when type
    // without unselecting it
      if (fixedNoneOrUnset || ![FixedFeePayableWhen.SaleEffected, FixedFeePayableWhen.SaleEffectedOrNot].includes(fixedPayableWhen)) {
        subsectionItems.push({
          stack: [
            'The Fixed Fee will be payable:',
            generateCheckboxRows([
              {
                other: false,
                selectMatch: FixedFeePayableWhen.SaleEffected.toString(),
                label: {
                  text: 'Only in the event of a Sale being effected'
                }
              },
              {
                other: false,
                selectMatch: FixedFeePayableWhen.SaleEffectedOrNot.toString(),
                label: {
                  text: 'Whether or not a Sale is effected'
                }
              }
            ], [], 1)
          ]
        });
      } else {
        subsectionItems.push({ text: `The Fixed Fee will be payable ${
          fixedPayableWhen === FixedFeePayableWhen.SaleEffected
            ? 'only in the event of a Sale being effected'
            : 'whether or not a Sale is effected'
        }.`
        });

      }
    }
    return subsectionItems;
  };

  if (!(!bothNotVisible && enabledModes?.commis?.none) && !(!bothUnset && commisNoneOrUnset)) {
    sectionItems.push(itemSubsection({
      subsectionTitle: 'Commission',
      titleBottomMargin: 4,
      subsectionContent: spaceStackLinesSideEffect(commissionSubsection()),
      isVariation
    }));
  }
  if (!(!bothNotVisible && enabledModes?.fixedFee?.none) && !(!bothUnset && fixedNoneOrUnset)) {
    sectionItems.push(itemSubsection({
      subsectionTitle: 'Fixed Fee',
      titleBottomMargin: 4,
      subsectionContent: spaceStackLinesSideEffect(fixedFeeSubsection()),
      isVariation
    }));

  }

  return itemSubsection({
    subsectionContent: spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-profess-fee', ...fieldFocus('professFee')],
    isVariation
  });
}

const modeEnabledTypes = [FeeCalcMechanism.Set, FeeCalcMechanism.Range];

function upliftSection(uplift?: UpliftType, professFee?: ProfessionalFeeType, isVariation?: boolean) {
  const { enable, thresh, portion, strike, fixed, displaySuppressed } = uplift || {};
  if (displaySuppressed) {
    return [];
  }
  const { commissionMode, fixedMode, enabledModes } = professFee??{};
  const anyFeeVisible = !enabledModes?.fixedFee?.none;
  const anyCommissionVisible = !enabledModes?.commis?.none; // Because if absolutely nothing is selected, we show all, only none removes visibility
  const sectionItems = [];
  const percentageOrFixed = true;
  const fixedEnabled = modeEnabledTypes.includes(fixedMode);
  const commissionEnabled = modeEnabledTypes.includes(commissionMode);

  const bothEnabled = anyFeeVisible && fixedEnabled && anyCommissionVisible && commissionEnabled;
  const neitherSelected = !fixedEnabled && !commissionEnabled;
  const feeString = (neitherSelected && anyCommissionVisible && !anyFeeVisible) || !(anyFeeVisible && fixedEnabled) && (anyCommissionVisible && commissionEnabled)
    ? 'Commission'
    : (neitherSelected && !anyCommissionVisible && anyFeeVisible) || (anyFeeVisible && fixedEnabled) && !(anyCommissionVisible && commissionEnabled)
      ? 'Fixed Fee'
      : 'Fixed Fee or Commission';

  sectionItems.push({
    text: [
      'Should the total sale price of the Property exceed ',
      lineIfEmpty(enable ? thresh : '', FieldPlaceholderStyle.Price),
      ' (‘',
      { text: 'Agreed Higher Sales Threshold', bold: true },
      '’)',
      ' then in consideration of the work done and the sale price achieved by the Agent, an additional Commission will be payable by the Vendor to the Agent, being an additional ',
      lineIfEmpty(enable ? (percentageOrFixed ? portion : fixed) : '', percentageOrFixed ? FieldPlaceholderStyle.Percentage : FieldPlaceholderStyle.Price),
      ' (inc GST)',
      percentageOrFixed
        ? ' calculated and payable only on the amount achieved above the Agreed Higher Sales Threshold.'
        : ', irrespective of by how much the sale price exceeds the Agreed Higher Sales Threshold.',
      '\n\n',
      'This Commission Uplift is in addition to the '+(bothEnabled ? 'Fixed Fee and Commission' : feeString)+' agreed above. ',
      percentageOrFixed ? '\n\n' : '',
      percentageOrFixed ? { italics: true, text: 'By example if the Agreed Higher Sales Threshold is set at $500,000 and the Contract sale price is $600,000 then the Commission Uplift (if @ 20%) is 20% of the increase ($100,000) – namely $20,000 (inc GST), in addition to any '+feeString+' agreed above.' } : ''
    ].filter(Predicate.isTruthy),
    lineHeight: 1.2,
    margin: [0, fieldsSpacing, 0, 0]
  });

  return itemSubsection({
    sectionRemove: Predicate.boolFalse(enable) && Predicate.boolFalse(strike),
    subsectionTitle: 'Commission Uplift',
    titleLineContent: undefined,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? [{ text: 'Not applicable' }] : spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-uplift', ...fieldFocus('uplift')],
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function professionalFeeSection(
  itemNo: number,
  marketingFees?: ItemiserItem[],
  pressBudget?: PressBudgetType,
  searchFees?: ItemiserItem[],
  professFee?: ProfessionalFeeType,
  uplift?: UpliftType,
  adminFee?: AdminFeeType,
  saleMethod?: string,
  auctionFee?: string,
  payTerm?: PaymentTermsType,
  isVariation?: boolean
) {
  const sectionItems = [];
  sectionItems.push(proFeeSection(professFee, uplift, isVariation));
  sectionItems.push(upliftSection(uplift, professFee, isVariation));
  // sectionItems.push(payTermsSection(payTerm, isVariation));
  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'Professional Fee'.toUpperCase(),
    bookmark: 'bookmark_fees_professional',
    stackContent: sectionItems,
    isVariation
  });
}
