import { PartyType, SaleAddress, VendorParty as AuthorityParty, jointTypes, multiRepAuthority } from '@property-folders/contract';
import { Predicate } from '../../../predicate';
import { jointPartyConfig, partyFieldConfig } from '../../formatting/constants';
import { contactAddressDerivation } from '../../contactAddressDerivation';
import { canonicalisers } from '../../formatting';
import {
  arbitraryFieldsRowTable,
  fieldFocus,
  fieldLabelInTable,
  freeTextContinuationArea,
  generateCheckboxRows,
  itemSection,
  itemSubsection,
  singleFieldOrFreeTextContinuationArea,
  singleFieldTable,
  spaceStackLinesSideEffect
} from '..';
import { minimumFontSize } from '../constants';
import { ArrayUtil } from '../../array';
import { FieldPlaceholderStyle } from '../standards';

const multiAuthorityPartySection = (
  vidx: number,
  primaryContact: boolean,
  vendorInner: object[],
  partyLabel = 'Vendor',
  partyDataPath?: string,
  isVariation = false,
  contractMode = false) => {
  return itemSubsection({
    subsectionTitle: `${partyLabel} ${vidx+1}`,
    titleLineContent: primaryContact && !contractMode ? {
      text: '(Primary contact)',
      style: 'content',
      fontSize: isVariation ? minimumFontSize : undefined
    } : undefined,
    subsectionContent: [{
      margin: [0, 4, 0, 0],
      stack: vendorInner
    }],
    unbreakable: true,
    bookmark: partyDataPath ? [...fieldFocus(partyDataPath), `subsection-${partyDataPath}`] : [],
    isVariation
  });
};

const contactLabelGen = (partOfMultiple: boolean) => partOfMultiple
  ? 'Primary contact\'s address'
  : 'Contact\'s address';

const noAuthorityGenerator = (primary: boolean,
  partOfMultiple: boolean,
  contractMode: boolean,
  purchaserMode?: boolean,
  isVariation = false) => {
  const elemList = [
    singleFieldTable({
      fieldName: 'Full legal name',
      isVariation,
      fieldPlaceholder: FieldPlaceholderStyle.Name
    })
  ];

  if (purchaserMode) {
    elemList.push(
      arbitraryFieldsRowTable({ fields: [['ABN/ACN (if applicable)', '', { fieldPlaceholder: FieldPlaceholderStyle.Abn }]] }),
      arbitraryFieldsRowTable({ fields: [['Contact person (if a corporation)', '', { fieldPlaceholder: FieldPlaceholderStyle.Name }]] }),
      arbitraryFieldsRowTable({
        fields: [
          ['Email', '', { fieldPlaceholder: FieldPlaceholderStyle.Email }],
          ['Mobile', '', { fieldPlaceholder: FieldPlaceholderStyle.Mobile }]
        ]
      })
    );
  } else if (contractMode) {
    elemList.push(
      arbitraryFieldsRowTable({ fields: [['ABN/ACN (if applicable)', '', { fieldPlaceholder: FieldPlaceholderStyle.Abn }]] })
    );
  } else {
    elemList.push(
      singleFieldTable({ fieldName: 'As Trustee/Executor/Administrator for (if applicable)', fieldValue: '', fieldPlaceholder: FieldPlaceholderStyle.Name }),
      arbitraryFieldsRowTable({ fields: [['Contact person if a corporation', '', { fieldPlaceholder: FieldPlaceholderStyle.Name }]] }),
      singleFieldTable({ fieldName: 'Email', fieldValue: '', fieldPlaceholder: FieldPlaceholderStyle.Email }),
      arbitraryFieldsRowTable({
        fields: [
          ['Mobile', '', { fieldPlaceholder: FieldPlaceholderStyle.Mobile }],
          ['ABN (if applicable)', '', { fieldPlaceholder: FieldPlaceholderStyle.Abn }]
        ]
      }),
    );
  }
  primary && elemList.push(freeTextContinuationArea('Postal address', '', { linesIfEmpty: 2 }));
  primary && !purchaserMode && elemList.push(
    generateCheckboxRows([{ other: false, selectMatch: 'addrSame', label: 'Postal address same as Property' }], [], 1)
  );
  return spaceStackLinesSideEffect(elemList);
};

/**
 *
 * @param vendor
 * @param vidx False if a single vendor, number if one of many
 * @param primaryVendorId
 * @returns
 */
function renderAuthorityPartyField(
  vendor: AuthorityParty,
  saleAddrs: SaleAddress[],
  vidx: number | false,
  primaryVendorId?: string,
  partyLabel = 'Vendor',
  contractMode = false,
  purchaserMode = false,
  dataModelPath?: string,
  isVariation = false) {
  const v = vendor || {};
  const { partyType, authority } = (v || {}) as {partyType?: string, authority?: string};
  const configuration = jointTypes.includes(partyType)
    ? jointPartyConfig[partyType]
    : (partyType && authority && partyFieldConfig[partyType][authority]);
  const {
    legalName,
    trustOrAppointedName,
    email1,
    phone1,
    abnTitle,
    streetAddr,
    appointedAlwaysApplicable,
    abnAlwaysApplicable,
    positionContact1,
    positionContact2,
    addressLinePos,
    abnTitleDetection,
    person1Name,
    person1NameMergedPdf,
    person2Name,
    email2,
    phone2
  } = configuration || {};
  const isPrimary = v.id === primaryVendorId;
  const partOfMultiple = typeof vidx === 'number';
  const dualContact = positionContact1 && positionContact2;
  const isBehalf = v.inTrust || appointedAlwaysApplicable;
  const isMortgagee = [PartyType.MortgageeNatural, PartyType.MortgageeCompany].includes(partyType);
  const appointedFieldTitleFinal = trustOrAppointedName||'!on behalf of';

  const isAdministrator = vendor?.partyType === PartyType.AdministratorCompany || vendor?.partyType === PartyType.AdministratorNatural;
  const showProbate = false; //Dont show on contract

  const position1Final = positionContact1||'!Contact 1';
  const position2Final = positionContact2||'!Contact 2';
  const contact1Primary = isPrimary && dualContact && v.primarySubcontact === 0;
  const contact2Primary = isPrimary && dualContact && v.primarySubcontact === 1;

  let executorNames: string | null = null;
  let shownContact: {phone: string, email: string, abn: string, name: string} | null = null;

  // General rule for showing the sub contact for a vendor. If they are not marked as the primary
  // contact at the top level, the primary for that vendor is going to be the first of the contact
  // sublists
  if (jointTypes.includes(v.partyType)) {
    executorNames = ((v.namedExecutors) as AuthorityParty[])
      .filter(e=>e.partyType && e.authority)
      .map(e=>e.fullLegalName)
      .filter(Predicate.isTruthy)
      .join(', ');
    const primarySubExecId = isPrimary ? v.primaryNamedExecutor : v.namedExecutors?.[0]?.id;
    const primarySubExec = ((v.namedExecutors) as AuthorityParty[]).find(ap=>ap.id === primarySubExecId) ?? ((v.namedExecutors) as AuthorityParty[])?.[0];
    const multiSubRepMode = multiRepAuthority.includes(primarySubExec?.authority);

    if (multiSubRepMode && primarySubExec) {

      const primaryRepId = isPrimary ? primarySubExec.primarySubcontactId : primarySubExec.legalRepresentatives?.[0]?.id;
      const primaryRep = primarySubExec.legalRepresentatives?.find(lr=>lr.id === primaryRepId) ?? primarySubExec.legalRepresentatives?.[0]; // In case the ID is invalid, just take [0]
      const repNameComposite = [...(primarySubExec.legalRepresentatives??[])].sort((lr1, lr2)=>{
        const primary1 = lr1.id === primaryRepId;
        const primary2 = lr2.id === primaryRepId;
        if (primary1) return -1;
        if (primary2) return 1;
        return 0;
      }).map(lr => lr.name).filter(Predicate.isTruthy).join(', ');
      shownContact = {
        name: repNameComposite,
        abn: primaryRep?.abn,
        email: primaryRep?.email,
        phone: primaryRep?.phone
      };
    } else if (!multiSubRepMode && primarySubExec) {
      shownContact = {
        abn: primarySubExec.abn,
        name: primarySubExec.personName1,
        email: primarySubExec.email1,
        phone: primarySubExec.phone1
      };
    }
  } else if (multiRepAuthority.includes(v?.authority)) {
    const primaryRepId = isPrimary ? v.primarySubcontactId : v.legalRepresentatives?.[0]?.id;
    const primaryRep = v.legalRepresentatives?.find(lr=>lr.id === primaryRepId) ?? v.legalRepresentatives?.[0]; // In case the ID is invalid, just take [0]
    const repNameComposite = [...(v.legalRepresentatives??[])].sort((lr1, lr2)=>{
      const primary1 = lr1.id === primaryRepId;
      const primary2 = lr2.id === primaryRepId;
      if (primary1) return -1;
      if (primary2) return 1;
      return 0;
    }).map(lr => lr.name).filter(Predicate.isTruthy).join(', ');
    shownContact = {
      name: repNameComposite,
      abn: primaryRep?.abn,
      email: primaryRep?.email,
      phone: primaryRep?.phone
    };
  }

  const showAbnApplicable = !(abnAlwaysApplicable || v.abn || shownContact?.abn);
  const abnUnderMultiRep = multiRepAuthority.includes(v?.authority) && !v.abn && shownContact?.abn;
  let abnFinalTitle = (showAbnApplicable ? (abnTitle||'!ABN')+' (if applicable)' : (abnTitle||'!ABN')).replace('{{partyLabel}}', partyLabel);
  const abnCanon = canonicalisers.abnacn((abnUnderMultiRep ? shownContact?.abn : v.abn)??'');
  if (typeof abnTitleDetection === 'string' && abnCanon.valid && typeof abnCanon?.components?.type === 'string') {
    abnFinalTitle = abnTitleDetection.replace('${}', abnCanon.components.type.toUpperCase());
  }

  const vendorInner = executorNames || (v.partyType && v.authority)
    ? (() => {
      const fieldRows = [];
      fieldRows.push(singleFieldTable(
        {
          fieldName: (legalName || '!').replace('{{partyLabel}}', partyLabel),
          fieldValue: executorNames ?? v.fullLegalName,
          _: undefined,
          fieldColons: true,
          labelStyleOverride: undefined,
          blankPrefix: '',
          blankSuffix: '',
          isVariation: isVariation,
          contentStyleOverride: 'content',
          fieldPlaceholder: FieldPlaceholderStyle.Name
        }
      ));

      isBehalf && fieldRows.push(singleFieldTable(
        {
          fieldName: appointedFieldTitleFinal,
          fieldValue: v.onBehalfOf,
          _: undefined,
          fieldColons: true,
          labelStyleOverride: 'tableFieldLabelLessened',
          blankPrefix: '',
          blankSuffix: '',
          isVariation: isVariation,
          contentStyleOverride: 'content',
          fieldPlaceholder: FieldPlaceholderStyle.Name
        }));

      //Probate/Letters of Administration
      contractMode && showProbate && fieldRows.push(generateCheckboxRows([
        {
          label: isAdministrator ? 'Letters of Administration have been granted; or' : 'Probate has been granted; or',
          selectMatch: 'probateGranted'
        },
        {
          other: true,
          label: isAdministrator ? 'Letters of Administration to be granted by' : 'Probate to be granted by',
          content: Predicate.boolFalse(vendor?.probateGranted) ? vendor?.probateByDate : undefined,
          selectMatch: 'probateNotGranted'
        }
      ], Predicate.isNotNullish(vendor?.probateGranted) ? [vendor?.probateGranted ? 'probateGranted' : 'probateNotGranted'] : [], 1 ));

      //Mortgage Number
      isMortgagee && fieldRows.push(singleFieldTable(
        {
          fieldName: 'as mortgagee exercising power of sale pursuant to mortgage number',
          fieldValue: v.mortgageNumber,
          _: undefined,
          fieldColons: true,
          labelStyleOverride: 'tableFieldLabelLessened',
          blankPrefix: '',
          blankSuffix: '',
          isVariation: isVariation
        }));

      const addrContainment = [];
      (!v.fullLegalName || v.abn || abnAlwaysApplicable) && !abnUnderMultiRep && fieldRows.push(singleFieldTable({
        fieldName: abnFinalTitle,
        fieldValue: v.abn,
        isVariation
      }));

      const { contactAddress, useAllowed: saleAddrAllowed, isUsed: saleAddrUsed } = contactAddressDerivation(v.addressSingleLine, v.addrSameAsSale, saleAddrs);
      isPrimary && addrContainment.push(singleFieldOrFreeTextContinuationArea(
        (streetAddr || contactLabelGen(partOfMultiple)).replace('{{partyLabel}}', partyLabel),
        contactAddress || '',
        2,
        isVariation
      ));
      !purchaserMode && isPrimary && !contactAddress && addrContainment.push(generateCheckboxRows([{ other: false, selectMatch: 'addrSame', label: 'Postal address same as Property' }], v.addrSameAsSale && saleAddrAllowed ? ['addrSame']: [], 1));
      addressLinePos === 'underName' && fieldRows.push(...addrContainment);

      positionContact1 && !contractMode && fieldRows.push(contact1Primary
        ? singleFieldTable({
          fieldName: position1Final,
          fieldValue: 'Primary contact',
          fieldColons: false,
          isVariation,
          contentStyleOverride: 'content'
        })
        : fieldLabelInTable(position1Final)
      );
      (!v.fullLegalName || abnAlwaysApplicable || shownContact?.abn) && abnUnderMultiRep && fieldRows.push(singleFieldTable({
        fieldName: abnFinalTitle,
        fieldValue: shownContact?.abn,
        isVariation
      }));
      positionContact1 && (!contractMode || purchaserMode) && fieldRows.push(singleFieldTable({
        fieldName: person1NameMergedPdf || person1Name || 'Name',
        fieldValue: shownContact?.name || v.personName1,
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Name
      }));
      const contact1ContactFields = arbitraryFieldsRowTable({
        fields: [
          [email1 || 'Email', shownContact ? shownContact.email : v.email1, { fieldPlaceholder: FieldPlaceholderStyle.Email }],
          [phone1 || 'Mobile', shownContact ? shownContact.phone : v.phone1, { fieldPlaceholder: FieldPlaceholderStyle.Mobile }]
        ].filter(Predicate.isTruthy),
        isVariation,
        contentStyleOverride: 'content'
      });
      (!contractMode || purchaserMode) && fieldRows.push(contact1ContactFields);
      positionContact2 && !shownContact && !contractMode && fieldRows.push(contact2Primary
        ? singleFieldTable({
          fieldName: position2Final,
          fieldValue: 'Primary contact',
          fieldColons: false,
          isVariation,
          contentStyleOverride: 'content'
        })
        : fieldLabelInTable(position2Final)
      );
      positionContact2 && (!contractMode || purchaserMode) && fieldRows.push(singleFieldTable({
        fieldName: person2Name || 'Name',
        fieldValue: v.personName2,
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Name
      }));
      positionContact2 && (!contractMode || purchaserMode) && fieldRows.push(arbitraryFieldsRowTable({
        fields: [
          [email2 || 'Email', v.email2, { fieldPlaceholder: FieldPlaceholderStyle.Email }],
          [phone2 || 'Mobile', v.phone2, { fieldPlaceholder: FieldPlaceholderStyle.Mobile }]
        ].filter(Predicate.isTruthy),
        isVariation,
        contentStyleOverride: 'content'
      }
      ));
      (!addressLinePos || addressLinePos === 'end') && fieldRows.push(...addrContainment);

      return spaceStackLinesSideEffect(fieldRows);
    })()
    : noAuthorityGenerator(isPrimary, partOfMultiple, contractMode, purchaserMode, isVariation);
  if (partOfMultiple) {
    return multiAuthorityPartySection(vidx, !dualContact && isPrimary, vendorInner, partyLabel, `${dataModelPath}.[${v.id}]`, isVariation, contractMode);
  } else {
    return itemSubsection({ subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: vendorInner, unbreakable: true, bookmark: `subsection-${dataModelPath}.[${v.id}]` });
  }
}

export function partySection(
  itemNo: number,
  parties: AuthorityParty[],
  saleAddrs: SaleAddress[],
  primaryVendorId?: string,
  partyLabel = 'Vendor',
  contractMode = false,
  purchaserMode = false,
  dataModelPath = 'vendors',
  isVariation = false) {
  const considerFilledConditions = [
    parties.length > 0,
    parties?.[0]?.partyType,
    (parties?.[0]?.authority || jointTypes.includes(parties?.[0]?.partyType))
  ].map(c=>!!c);
  const emptyForm = !considerFilledConditions.every(a=>a);
  const emptyFormCount = Math.max(2, parties.length);
  let valueList = [...parties];

  const mIdx = valueList.findIndex(val=> val.id === primaryVendorId);
  if (mIdx) {
    const firstElem = valueList.splice(mIdx,1);
    valueList = [...firstElem, ...valueList];
  }

  const authorityPartyContent = !emptyForm
    ? valueList.map((vendor, vidx) => renderAuthorityPartyField(
      vendor,
      saleAddrs,
      parties.length > 0 ? vidx : false,
      primaryVendorId,
      partyLabel,
      contractMode,
      purchaserMode,
      dataModelPath,
      isVariation))
    : ArrayUtil
      .range(emptyFormCount - 1)
      .map(x => multiAuthorityPartySection(
        x,
        x === 0,
        noAuthorityGenerator(x === 0, true, contractMode, purchaserMode, isVariation),
        partyLabel,
        '',
        isVariation,
        contractMode));

  return itemSection(
    { itemNo: itemNo, itemTitleParam: partyLabel, bookmark: [`bookmark_${partyLabel?.toLocaleLowerCase()}`, `field_focus_${dataModelPath}`], stackContent: authorityPartyContent, superTitle: false, isVariation: isVariation });
}
