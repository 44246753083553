import { renderText } from './render-text';
//chrome will not load a font unless its drawn on a canvas of greater than 100px width
export function preloadFonts(names: string[]) {
  for (const name of names) {
    renderText({
      text: '.',
      canvas: {
        height: 10,
        width: 101
      },
      font: {
        size: 72,
        name: name
      }
    });
  }
}
