import { Canvas, CanvasRenderingContext2D, Image } from 'canvas';
import { renderWithCanvasContext, VirtualCanvas } from './render-with-canvas-context';
import { trimImage } from './trim-image';

/**
 *
 * @param signature image with the user's signature - it will be scaled to fit within the available frame space, and may be stripped of any existing padding.
 * @param topText text that will appear at the top of the frame
 * @param bottomText text that will appear at the bottom of the frame
 * @param canvas expected canvas dimensions
 * @param padding how to pad the resulting image
 * @returns
 */
export function frameSignature(
  // image type
  signature: Image,// CanvasImageSource | undefined | HTMLImageElement,
  /**
   * e.g. signed by
   */
  topText: string | undefined,
  /**
   * e.g. short-id
   */
  bottomText: string | undefined,
  /**
   * the size of the signature
   */
  box?: { width: number, height: number },
  /**
   * the size of the box containing the signature (pad it out to this)
   */
  containingBox?: { width: number, height: number },
) {
  const { width: boxWidth, height: boxHeight } = box ?? { width: 600, height: 150 };
  // 2.4 results in a width of 14*12,
  // allowing about enough space for the monospace text and the signature
  const boxRatio = Math.max(boxWidth / boxHeight, 2.4);
  // scale the box dimensions to the static unscaled height
  const height = (14*5);
  const width = height * boxRatio;
  const pad = 2;
  return renderWithCanvasContext((canvas, ctx, submitCleanupJob) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = '#da6027';
    ctx.lineWidth = 1;
    ctx.font = `${12}px serif`;

    const { trimmed, cleanup } = signature ? trimImage(signature) : { };
    if (cleanup) submitCleanupJob(cleanup);

    // position values
    const frameTextFontSize = 10;
    const xPad = 2;
    const yPad = 7;
    const radiusLine = 3;
    const extensionLine = 5;
    const xTextStart = xPad + extensionLine + 6;
    const yTextMiddleLine = 8;
    const xSignaturePad = 10;
    const ySignaturePad = 15;

    // draw signature image in vcentre
    if (trimmed) {
      drawImageInFrame({ canvas, ctx, xPad: xSignaturePad, yPad: ySignaturePad, image: trimmed });
    }

    // draw lines
    drawFrameLine({ canvas, ctx, radius: radiusLine, extension: extensionLine, xPad, yPad, thickness: 2 });
    // draw top / bottom text
    drawFrameText({ canvas, ctx, text: topText, baseline: 'middle', fontSize: frameTextFontSize, x: xTextStart, y: yTextMiddleLine });
    drawFrameText({ canvas, ctx, text: bottomText, baseline: 'middle', fontSize: frameTextFontSize, x: xTextStart, y: height - (yTextMiddleLine - 1) });
  }, {
    width,
    height,
    trim: true,
    padding: {
      vCenter: true,
      toWidth: containingBox ? containingBox.width
        : undefined,
      toHeight: containingBox ? containingBox.height
        : undefined,
      bottom: pad,
      top: pad,
      left: pad,
      right: pad
    },
    scale: 4
  });
}

export function frameInitials(
  // image type
  signature: Image | undefined,
  /**
   * e.g. signed by
   */
  topText: string | undefined,
  /**
   * e.g. short-id
   */
  bottomText: string | undefined,
  containingBox: { width: number, height: number }
) {
  const width = (14*5);
  const height = (14*5);
  const pad = 2;

  return renderWithCanvasContext((canvas, ctx, submitCleanupJob) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = '#da6027';
    ctx.lineWidth = 1;
    ctx.font = `${12}px serif`;

    const { trimmed, cleanup } = signature ? trimImage(signature) : { };
    if (cleanup) submitCleanupJob(cleanup);

    // position values
    const frameTextFontSize = 10;
    const xPad = 2;
    const yPad = 7;
    const radiusLine = 3;
    const extensionLine = 5;
    const xTextStart = xPad + extensionLine + 4;
    const yTextMiddleLine = 8;
    const xSignaturePad = 10;
    const ySignaturePad = 15;

    // draw signature image in vcentre
    if (trimmed) {
      drawImageInFrame({ canvas, ctx, xPad: xSignaturePad, yPad: ySignaturePad, image: trimmed });
    }

    // draw lines
    drawFrameLine({ canvas, ctx, radius: radiusLine, extension: extensionLine, xPad, yPad, thickness: 2 });
    // draw top / bottom text
    drawFrameText({ canvas, ctx, text: topText, baseline: 'middle', fontSize: frameTextFontSize, x: xTextStart, y: yTextMiddleLine });
    drawFrameText({ canvas, ctx, text: bottomText, baseline: 'middle', fontSize: frameTextFontSize, x: xTextStart, y: height - yTextMiddleLine });
  }, {
    width,
    height,
    trim: true,
    padding: {
      vCenter: true,
      toWidth: containingBox.width,
      toHeight: containingBox.height,
      bottom: pad,
      top: pad,
      left: pad,
      right: pad
    },
    scale: 2
  });
}

function drawFrameLine({ canvas, ctx, radius, yPad, xPad, thickness, extension, debug }: {
  canvas: VirtualCanvas,
  ctx: CanvasRenderingContext2D,
  radius: number,
  yPad: number,
  xPad: number,
  thickness: number,
  extension: number,
  debug?: boolean
}) {
  ctx.lineWidth = thickness;

  ctx.beginPath();
  ctx.moveTo(xPad + radius + extension, yPad);
  ctx.lineTo(xPad + radius, yPad);
  ctx.arc(radius + xPad, radius + yPad, radius, Math.PI * 1.5, Math.PI * 1, true);
  ctx.lineTo(xPad, yPad + radius + extension);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(xPad, canvas.height - (yPad + radius + extension));
  ctx.lineTo(xPad, canvas.height - (yPad + radius));
  ctx.arc(xPad + radius, canvas.height - (yPad + radius), radius, Math.PI * 1, Math.PI * 0.5, true);
  ctx.lineTo(xPad + radius + extension, canvas.height - yPad);
  ctx.stroke();

  if (debug) {
    // where is the end of the canvas?
    ctx.beginPath();
    ctx.moveTo(canvas.width - 1, 0);
    ctx.lineTo(canvas.width - 1, canvas.height);
    ctx.stroke();
  }
}

function drawFrameText({ canvas, ctx, x, y, baseline, text, fontSize }: {
  canvas: VirtualCanvas,
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  baseline: CanvasTextBaseline,
  fontSize: number,
  text?: string
}) {
  if (!text) return;

  ctx.textBaseline = baseline;
  ctx.font = `${fontSize}px monospace`;
  ctx.fillText(text, x, y);
}

function drawImageInFrame({ canvas: { width, height }, ctx, xPad, yPad, image }: {
  canvas: VirtualCanvas,
  ctx: CanvasRenderingContext2D,
  xPad: number,
  yPad: number,
  image: Image | Canvas
}) {
  // calculate the image width/height so it fits within the bounding rectangle,
  // and then multiply by the overall image scale
  const imageWidthBase = ('width' in image && typeof image.width === 'number') ? image.width : width;
  const imageHeightBase = ('height' in image && typeof image.height === 'number') ? image.height : height;
  const drawWidth = width - (xPad * 2);
  const drawHeight = height - (yPad * 2);
  const xImageFitScale = drawWidth / imageWidthBase;
  const yImageFitScale = drawHeight / imageHeightBase;
  const imageFitScale = Math.min(xImageFitScale, yImageFitScale);
  const imageWidth = imageFitScale * imageWidthBase;
  const imageHeight = imageFitScale * imageHeightBase;

  const x = xPad;
  const y = (height - imageHeight) / 2; // vertical centering

  ctx.drawImage(image, x, y, imageWidth, imageHeight);
}
