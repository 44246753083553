import { PaddingConfig } from './padding-config';
import { renderWithCanvasContext } from './render-with-canvas-context';

export function renderText(opts: {
  text?: string,
  canvas?: {
    height?: number,
    width?: number
  },
  font?: {
    name: string,
    size: number
  },
  padding?: PaddingConfig
}): string | undefined {
  const text = opts.text;
  const fontName = opts.font?.name || 'Helvetica';
  const fontSize = opts.font?.size || 72;

  return renderWithCanvasContext((canvas, context) => {
    if (!text) {
      // doing it here at least means we end up with a blank canvas
      return;
    }
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'black';
    context.font = `${fontSize}px ${fontName}`;
    context.fillText(text, (canvas.width / 20), (canvas.height / 3) * 2, canvas.width - 100);
  }, {
    width: opts.canvas?.width || 800,
    height: opts.canvas?.height || 100,
    trim: true,
    padding: opts.padding
  });
}

// given a font size and a box do a bit of math
export function calcRenderTextPadding(opts: {
  defaultPad?: number,
  containingBox: { width: number, height: number },
  fontSize?: number,
  resolutionScale?: number
}): PaddingConfig {
  const scale = opts.resolutionScale ?? 4;
  const pad = scale * (opts.defaultPad ?? 2);
  const fontSize = opts.fontSize ?? 12;
  return {
    left: pad,
    top: pad*2,
    right: pad,
    bottom: Math.max(pad, scale * (opts.containingBox.height - fontSize)),
    toHeight: scale * opts.containingBox.height,
    toWidth: scale * opts.containingBox.width,
    vCenter: true
  };
}

export function renderTextSimple(opts: {
  text: string | undefined,
  fontSize: number | undefined,
  containingBox: { width: number, height: number },
}) {
  const resolutionScale = 4;
  const defaultPad = 6;
  const fontSize = opts.fontSize ?? 12;
  return renderText({
    text: opts.text,
    font: {
      name: 'Helvetica',
      size: resolutionScale * fontSize
    },
    // this is just to adjust how freaking massive the text would appear otherwise
    padding: calcRenderTextPadding({
      containingBox: opts.containingBox,
      defaultPad,
      fontSize,
      resolutionScale
    })
  });
}
