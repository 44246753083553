import { Canvas, CanvasRenderingContext2D } from 'canvas';
import { PaddingConfig } from './padding-config';

export function padCanvas(canvas: Canvas, context: CanvasRenderingContext2D, config: PaddingConfig) {
  const width = canvas.width;
  const height = canvas.height;
  const imgData = context.getImageData(0, 0, width, height);

  const newWidth = config.toWidth
    ? Math.max(config.toWidth, width + config.left + config.right)
    : width + config.left + config.right;
  const newHeight = config.toHeight
    ? Math.max(config.toHeight, height + config.top + config.bottom)
    : height + config.top + config.bottom;

  const pTop = config.vCenter
    ? (newHeight - height) / 2
    : config.top;

  canvas.width = Math.floor(newWidth);
  canvas.height = Math.floor(newHeight);
  context.putImageData(imgData, config.left, pTop);
}
