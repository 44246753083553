import { Operator, WorkflowCondition, WorkflowConditionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { MaterialisedProperty } from '@property-folders/contract';
import { get } from 'lodash';
import { EmailTemplateRuleMappingFunctions } from './EmailTemplateRuleMappingFunctions';
import { WorkflowEvent } from '@property-folders/services/workflow-event/workflow-event';

export function evaluateCondition(condition: WorkflowCondition, property: MaterialisedProperty, event: WorkflowEvent): boolean {
  if (!condition) return false;
  const { data } = property??{};
  console.log('evaluating condition', JSON.stringify(condition));

  try {
    switch (condition.type) {
      case WorkflowConditionType.And:
        return condition.conditions.every(c => evaluateCondition(c, property, event));
      case WorkflowConditionType.Or:
        return condition.conditions.some(c => evaluateCondition(c, property, event));

      case WorkflowConditionType.Field:
        return isTrue(condition.value, condition.operator, get(data, condition.field));

      case WorkflowConditionType.MappedField:
        return isTrue(condition.value, condition.operator, EmailTemplateRuleMappingFunctions[condition.mappingFn]?.(data));

      case WorkflowConditionType.Document:
        return isTrue(condition.value, condition.operator, event.formCode);

      default:
        return false;
    }

  } catch (e) {
    console.log('Error evaluating condition', e);
    return false;
  }
}

function isTrue(left: any, operator: Operator, right: any) {
  console.log({ left: left, operator: operator, right: right });
  switch (operator) {
    case Operator.In:
      return left?.includes(right);
    case Operator.NotIn:
      return !left?.includes(right);
    case Operator.Includes:
      return right?.some((v:any) => left?.includes(v));
    case Operator.NotIncludes:
      return !right?.some((v:any) => left?.includes(v));
    default:
      return false;
  }
}
