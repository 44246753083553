import { MaterialisedPropertyData } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';

/**Sets the deposit option if the sale method is changed
 *
 * input only fields: sale.saleMethod
 * output only fields: contractPrice.depositPayAt
 */
export const NaSwitchEncumbrance: FieldGroupFn = (fieldId, updatedPath, immerProxy: MaterialisedPropertyData) => {
  if (!immerProxy.contractSchedule) return;
  if (fieldId === 'contractSchedule.notApplicable' && immerProxy.contractSchedule?.notApplicable) {
    immerProxy.contractSchedule.anyNotDischarged = false;
    immerProxy.contractSchedule.newEncumbrances = false;
    immerProxy.contractSchedule.existingEncumbrances = false;
    return;
  }

  const upSegs = normalisePathToStrArray(updatedPath);
  const newValue = getValueByPath(updatedPath, immerProxy);
  if (['anyNotDischarged', 'newEncumbrances', 'existingEncumbrances'].includes(upSegs[upSegs.length-1]) && newValue) {
    immerProxy.contractSchedule.notApplicable = false;
  }

};
