import {
  LookupCanShareResult,
  LookupCanSignResult,
  LookupClassicFormsQueryParams, LookupClassicFormsResult,
  LookupClassicTemplatesQueryParams,
  LookupClassicTemplatesResult, LookupContactsQueryParams, LookupContactsResult,
  LookupEntitiesQueryParams,
  LookupEntitiesResult, LookupPropertiesQueryParams, LookupPropertiesResult,
  LookupTeamsResult,
  LookupUsersResult
} from '@property-folders/contract';
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';

export class Lookups {
  public static lookupProperties(params: LookupPropertiesQueryParams) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupPropertiesResult>(
        LinkBuilder.restApi(`/lookups/properties?${LinkBuilder.buildQueryString(params)}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupEntities(params: LookupEntitiesQueryParams) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupEntitiesResult>(
        LinkBuilder.restApi(`/lookups/entities?${LinkBuilder.buildQueryString(params)}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupClassicTemplates(params: LookupClassicTemplatesQueryParams) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupClassicTemplatesResult>(
        LinkBuilder.restApi(`/lookups/classic-templates?${LinkBuilder.buildQueryString(params)}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupClassicForms(params: LookupClassicFormsQueryParams) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupClassicFormsResult>(
        LinkBuilder.restApi(`/lookups/classic-forms?${LinkBuilder.buildQueryString(params)}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupContacts(params: LookupContactsQueryParams) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupContactsResult>(
        LinkBuilder.restApi(`/lookups/contacts?${LinkBuilder.buildQueryString(params)}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupCanShare(entityId: number) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupCanShareResult>(
        LinkBuilder.restApi(`/lookups/can-share?entityId=${entityId}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupCanSign(entityId: number) {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupCanSignResult>(
        LinkBuilder.restApi(`/lookups/can-sign?entityId=${entityId}`),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupUsers() {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupUsersResult>(
        LinkBuilder.restApi('/lookups/users'),
        {
          signal: ac.signal
        }
      )
    };
  }

  public static lookupTeams() {
    const ac = new AbortController();
    return {
      ac,
      results: WrappedFetch.json<LookupTeamsResult>(
        LinkBuilder.restApi('/lookups/teams'),
        {
          signal: ac.signal
        }
      )
    };
  }
}
