import { trimCanvas } from './trim-canvas';
import { Canvas, Image, NodeCanvasRenderingContext2DSettings, createCanvas } from 'canvas';
import { removeCanvas } from './remove-canvas';

export function trimImage(image: Canvas | Image) {
  const canvas = createCanvas(
    typeof image.width === 'number' ? image.width : 300,
    typeof image.height === 'number' ? image.height : 300
  );

  const context = canvas.getContext('2d', { willReadFrequently: true } as NodeCanvasRenderingContext2DSettings);

  if (!context) {
    return {
      trimmed: image,
      cleanup: () => {}
    };
  }

  context.drawImage(image, 0, 0, canvas.width, canvas.height);
  trimCanvas(canvas, context);

  return {
    trimmed: canvas,
    cleanup: () => removeCanvas(canvas)
  };
}
