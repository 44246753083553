import { Canvas, createCanvas } from 'canvas';

/**
 * Scales a canvas by a given factor
 * @param canvas
 * @param factor
 */
export function scaleCanvas(canvas: Canvas, factor: number) {
  const tempCanvas= createCanvas(canvas.width, canvas.height);
  const tempCtx= tempCanvas.getContext('2d');
  if (!tempCtx) return;

  const cw= canvas.width;
  const ch= canvas.height;
  tempCanvas.width = cw;
  tempCanvas.height = ch;
  tempCtx.imageSmoothingEnabled = true;
  if ('imageSmoothingQuality' in tempCtx) {
    (tempCtx as unknown as CanvasRenderingContext2D).imageSmoothingQuality = 'high';
  } else {
    tempCtx.patternQuality = 'best';
  }
  tempCtx.drawImage(canvas,0,0);
  canvas.width *= factor;
  canvas.height *= factor;
  const ctx= canvas.getContext('2d');
  ctx?.drawImage(tempCanvas,0,0,cw,ch,0,0,cw*factor,ch*factor);
}
