import * as PdfGen from '@property-folders/common/util/pdfgen';
import { reaformsLogo, transparentPixel, reaformsImageDims as reaformsImageDims } from '@property-folders/common/util/pdfgen/logos';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { generatePartiesSummaryString } from '@property-folders/common/util/pdfgen/sections/statement-of-parties';
import { formatBI } from '@property-folders/common/util/pdfgen/formatBI';
import { friendlyDateFormatter } from '../../../formatting';
import { FormTypes } from '../../../../yjs-schema/property/form';
import { FormCode } from '@property-folders/contract';

export function salesContractTerminationExternal({ property, propertyRaw, signers, objects, brand, clausesId, annexures, snapshotHistory, lastSignedSnapData, formFamilyState, annexureBlobs }: PdfWorkerDocumentDefinition) {

  const useCoverPage = false;
  const title = 'External Termination';

  // If signers come out empty, we still want to see the annexure
  const names = generatePartiesSummaryString(signers) || ' ';

  const terminationTime = new Date(formFamilyState?.terminatedTime??0);

  const annexureOfDocumentAttached = !!annexures?.length;

  const agencyLogoData = objects?.agencyLogoImage || transparentPixel;
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: agencyLogoData
    },
    content: [
      ...PdfGen.documentTitle(title, 1, brand, false),
      {
        stack: PdfGen.spaceStackLinesSideEffect([
          { text: formatBI(`The Agent has confirmed that the *-*${FormTypes[FormCode.RSC_ContractOfSale].label}*-* between *-*${names}*-* was terminated on the *-*${friendlyDateFormatter(terminationTime)}*-*.`) },
          { text: annexureOfDocumentAttached
            ? 'A copy of the termination document is attached as an annexure.'
            : 'A copy of the termination document was not uploaded.'
          }
        ]),
        margin: [36,4*PdfGen.fieldsSpacing, 36, 0]
      }
    ],
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, useCoverPage),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, useCoverPage, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact, { ourProductLogoSvgDims: reaformsImageDims, agencyLogoData })
  });

}
