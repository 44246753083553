import { db } from './db';
import { Maybe } from '../types/Utility';
import { AccompanyingObjects, ContentType, ManifestData } from '@property-folders/contract';
import { BelongingEntityMeta, markLogoUpdated } from '../redux-reducers/entityMeta';
import { Predicate } from '../predicate';
import { AnyAction, Store } from 'redux';
import { checkIfIndexedDBShimNeeded } from '@property-folders/components/shims/IndexedDB/checkIfIndexedDBShimNeeded';
import {
  SaveObjectContainingBlobs
} from '@property-folders/components/shims/IndexedDB/IDBObjectStore/SaveObjectContainingBlobs';
import {
  FetchObjectContainingBlobs
} from '@property-folders/components/shims/IndexedDB/IDBObjectStore/FetchObjectContainingBlobs';
import { Doc } from 'yjs';
import { applyMigrationsV2_1 } from '../yjs-schema';
import { FileTrackDocRoot, FileTrackState, PropertyRootKey, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { addAvailableFile } from '../redux-reducers/available-files';

export enum StorageItemFileStatus {
  Unavailable,
  Failed,
  Available
}

export enum StorageItemSyncStatus {
  None = 0,
  PendingDownload = 1,
  PendingUpload = 2,
  TerminatedDownload = 3,
  PendingDownloadHighPriority = 4
}

const initialSyncValue = -1; // -1 because we check for <Dexie>.where().above()
const ENABLE_SYNC_FILTER_BY_ID = true;
const NO_SYNC_FOR_UNASSOCIATED_FILES = false; // This is an aggressive check, and expects every file reference to have an entity ID. Not recommended
export class SyncJobLiveOrderQueue {

  private acceptableEntityIds = new Set<string | number>();
  constructor(store: Store<unknown, AnyAction>) {
    Object.values(((store.getState() as any).entityMeta??{}) as BelongingEntityMeta).forEach(e=>{
      e?.entityId && this.acceptableEntityIds.add(e.entityId);
      e?.entityUuid && this.acceptableEntityIds.add(e.entityUuid);
    });
  }
  order = syncJobOrder;
  downloadSyncTypes = syncDownloadTypes;
  groups: { [key in StorageItemSyncStatus]?: IFileMeta[] } = {};
  _syncRun = initialSyncValue;

  // First in Last out within each priority group, but priority group still consumed in order
  private async updateQueue() {
    const thisReqTs = this._syncRun;
    this._syncRun = new Date().getTime(); // Tiny chance of overlap, but big deal if the status is synced?
    const newEntries = (await FileStorage.getLatestSyncJobs(thisReqTs));
    const redundancyCheck = thisReqTs !== initialSyncValue;
    for (const entry of newEntries) {
      const groupId = entry.syncStatus;
      if (!this.groups[groupId]) {
        this.groups[groupId] = [];
      }
      const group = this.groups[groupId];
      if (!group) continue; // This should never occur because of the condition above, but TS
      // Not checking if we're adding initial values, subsequent adds should have far fewer
      if (redundancyCheck && group.find(m => m.id === entry.id)) {
        console.warn('Attempted to add item already in queue!');
        continue;
      }
      const shouldHavePermission = (!NO_SYNC_FOR_UNASSOCIATED_FILES && !entry.propertyEntityId) || (entry.propertyEntityId && this.acceptableEntityIds.has(entry.propertyEntityId));

      if (ENABLE_SYNC_FILTER_BY_ID && !shouldHavePermission) {
        // Remember, this is add to queue, not remove, so we aren't splicing group. We should remove the request if it is for a download though, as it will no longer be relevant
        const willDelete = this.downloadSyncTypes.includes(entry.syncStatus);

        if (willDelete) {
          // Just in case some other "thread" also deletes it. Unlikely for reason stated above, but juuust in case
          void db.fileMeta.delete(entry.id).then(()=>{}).catch(()=>{});
        }
        continue;
      } else if (!ENABLE_SYNC_FILTER_BY_ID && !shouldHavePermission) {
        console.warn('Detected as not being a part of entity, would not be synced if filter was enabled. ID:', entry.id);
      }

      group.splice(0, 0, entry);
    }
  }

  public get syncRun() {
    return this._syncRun;
  }

  public async next() {
    await this.updateQueue();
    for (const oKey of this.order) {
      const group = this.groups[oKey];
      if (group && group[0]) {
        // In a property parallel system, object access to this like this would be dangerous
        // but this is just async, so it should be fine. The probability of the above if going
        // oh yes theres one, and then in the line below, oh there's nothing left now, is
        // vanishingly small, if it is even possible. In this worst case scenario, means we have one
        // less pseudo-thread getting items to retrieve I guess?
        return group.splice(0, 1)[0];

      }
    }
    return null;
  }
}
export enum EntityFileType {
  MarketingTemplate = 'MARKETING',
  EmailTemplateFile = 'EMAIL_FILE'
}

export interface IFileRelatedData {
  // note to self: why the nesting?
  // for downloads it may be too much.
  // for uploads it's used to place the files appropriately.
  // flattening always might help for client-side searching,
  // but I think we'd usually search by propertyId/entityId for purposes of deletion.
  propertyId?: string;
  propertyFile?: {
    propertyId: string;
    formId: string;
    formCode: string;
    signingSessionId?: string;
  }
  cachedPartyImage?: {
    propertyId: string;
    canonicalId: string;
  }
  entityLogo?: {
    entityId: number;
    logoUri: string;
  },
  entityFile?: {
    entityUuid: string,
    fileType: EntityFileType,
    parentId: string
  }
}

export enum FileType {
  PropertyFile = 0,
  EntityLogo = 1,
  PropertyFileDataSnapshot = 2,
  EntityFile = 3
}

export interface IFileMeta {
  id: string;
  propertyEntityId?: string | number; // We accept both types of identifiers. It's up to the consumer to deal with both. There are definitely both active, some things with only access to the meta and the numeric id, others might only have access to the UUID such as the settings page
  contentType: string;
  type?: FileType;
  size?: number;
  fileStatus: StorageItemFileStatus;
  syncStatus: StorageItemSyncStatus;
  addedTimeMs: number;
  failure?: {
    message: string;
    timestamp?: number;
    count?: number;
  }
  relatedData: IFileRelatedData;
  manifestData?: ManifestData;
}

export const syncDownloadTypes = [
  StorageItemSyncStatus.PendingDownloadHighPriority,
  StorageItemSyncStatus.PendingDownload
];
// Uploads are almost always user action, so we probably don't need a higher priority bucket for
// them
export const syncUploadTypes = [
  StorageItemSyncStatus.PendingUpload
];

export const syncJobOrder = [
  ...syncUploadTypes,
  ...syncDownloadTypes
];

export interface IFileData {
  id: string;
  data: Blob;
}

function traceBlobType(name: string, blob: Blob, contentType: string) {
  // Not guaranteed to be a buffer converted to a blob, but pretty likely.
  // At this time the pdf generator does indeed return as a buffer.
  const pdfIsBuffer = !blob.type && contentType === ContentType.Pdf;

  const traceCircumstances = [
    !pdfIsBuffer && blob.type !== contentType ? `content type mismatch '${blob.type}' !== '${contentType}'` : undefined
  ].filter(Predicate.isNotNull);

  const logCircumstances = [
    pdfIsBuffer ? `No type declared. Expected ${contentType}` : undefined
  ].filter(Predicate.isNotNull);

  if (traceCircumstances.length) {
    console.trace(name, traceCircumstances);
  }

  if (logCircumstances.length) {
    console.log(name, logCircumstances);
  }
}

function fixBlobType(blob: Blob, expectedContentType?: string) {
  if (!expectedContentType) return blob;
  if (blob.type === expectedContentType) return blob;

  return new Blob([blob], { type: expectedContentType });
}

export type ReadFile = IFileMeta & { data?: Blob };

export class FileStorage {
  private static _blobShimNeeded: boolean;

  private static async blobShimNeeded(): Promise<boolean> {
    if (this._blobShimNeeded == undefined) {
      this._blobShimNeeded = await checkIfIndexedDBShimNeeded();
    }

    return this._blobShimNeeded;
  }

  static async readFileOnly(id: Maybe<string>): Promise<Maybe<Blob>> {
    const blobOrStrBlob = await db.transaction('r', db.fileData, async () => {
      return (await db.fileData.get(id || ''))?.data;
    });

    const blob = blobOrStrBlob instanceof Blob
      ? blobOrStrBlob
      : FetchObjectContainingBlobs(blobOrStrBlob);

    return blob;
  }

  /**
   * Get file meta + content when available
   */
  static async read(
    id: Maybe<string>,
    { noPriorityUpgrade }: {noPriorityUpgrade?: boolean} ={}
  ): Promise<Maybe<ReadFile>> {
    if (!id) {
      return undefined;
    }

    return db.transaction('rw', db.fileData, db.fileMeta, async () => {
      const meta = await db.fileMeta.get(id);

      if (!meta) {
        return undefined;
      }

      if (meta.fileStatus !== StorageItemFileStatus.Available) {
        // If we're trying to read it, perhaps we should upgrade the priority of this item
        if (meta.syncStatus === StorageItemSyncStatus.PendingDownload && !noPriorityUpgrade) {
          await db.fileMeta.update(id, { syncStatus: StorageItemSyncStatus.PendingDownloadHighPriority, addedTimeMs: new Date().getTime() });
        }
        return meta;
      }

      const data = await db.fileData.get(id);
      const blobOrStrBlob = data?.data;
      let blob: Blob | undefined = blobOrStrBlob instanceof Blob
        ? blobOrStrBlob
        : FetchObjectContainingBlobs(blobOrStrBlob);

      if (!blob?.size) {
        console.warn('file meta exists, but file content does not. requeue.');

        const targetPriority = noPriorityUpgrade?StorageItemSyncStatus.PendingDownload:StorageItemSyncStatus.PendingDownloadHighPriority;
        await db.fileMeta.update(id, { syncStatus: targetPriority });
        meta.syncStatus = targetPriority;
        return meta;
      }

      blob = fixBlobType(blob, meta.contentType);
      traceBlobType('read', blob, meta.contentType);

      return {
        ...meta,
        data: blob
      };
    });
  }

  static async readMeta(id: Maybe<string>): Promise<Maybe<IFileMeta>> {
    if (!id) {
      return undefined;
    }

    return db.transaction('r', db.fileMeta, async () => {
      const meta = await db.fileMeta.get(id);
      if (!meta) {
        return undefined;
      }

      return meta;
    });
  }

  static async openPreview(id: string) {
    const blob = await FileStorage.read(id);

    if (!blob?.data) {
      console.log('no data');
      return;
    }

    window.open(URL.createObjectURL(blob.data));
  }

  /**
   * Supports two cases:
   * - file was downloaded: sync status should be None
   * - file was created locally: sync status should be PendingUpload
   * Always sets the file status to Available.
   * @param id unique file id (uuid v4)
   * @param type broad file category
   * @param contentType file content type
   * @param data file content
   * @param syncStatus is the file pending upload/download?
   * @param relatedData metadata for associating the file
   * @param manifestData metadata to accompany the file during upload (requires json serialising and zip archiving with
   *   the file data)
   */
  static async write(
    id: string,
    type: FileType,
    contentType: string,
    data: Blob,
    syncStatus: StorageItemSyncStatus.None | StorageItemSyncStatus.PendingUpload,
    relatedData: IFileRelatedData,
    sideEffectWriters: {store: Store<unknown, AnyAction> | undefined, ydoc: Doc | undefined}, // ydoc is now also used for obtaining the entity ID
    manifestData?: ManifestData,
    overwriteContentType?: boolean,
    accompanyingObjects?: AccompanyingObjects,
    { entityId: entityIdParam, ...debugOpts }: {
      entityId?: string | number

      /** Debug option */
      blobShimNeeded?: boolean,
      /** Debug option */
      logFn?: (...text: string[]) => void
    } = {}
  ) {
    const { store, ydoc } = sideEffectWriters;
    const entityId = entityIdParam ?? (ydoc?.getMap<TransactionMetaData>(PropertyRootKey.Meta).toJSON())?.entity?.id;
    if (syncStatus === StorageItemSyncStatus.PendingUpload && (relatedData.propertyId || relatedData.propertyFile?.propertyId) && !ydoc) {
      console.error('Bad call to write. A property related file must have a ydoc to update the sync status of');
    }
    const fileIdsToPublish = [id];
    if (debugOpts?.logFn) {
      debugOpts.logFn('check blobShimNeeded...');
    }
    const blobShimNeeded = debugOpts?.blobShimNeeded !== undefined
      ? debugOpts.blobShimNeeded
      : await this.blobShimNeeded();

    if (debugOpts?.logFn) {
      debugOpts.logFn(debugOpts.blobShimNeeded !== undefined
        ? '(check skipped)'
        : blobShimNeeded
          ? 'shim needed'
          : 'shim not needed'
      );
      debugOpts.logFn('prepare wrappedBlob');
    }
    const wrappedBlob = blobShimNeeded
      ? await SaveObjectContainingBlobs(fixBlobType(data, contentType))
      : fixBlobType(data, contentType);

    if (debugOpts?.logFn) {
      debugOpts.logFn('prepare accompanyingFiles');
    }
    const accompanyingFiles = accompanyingObjects
      ? (await Promise.all(Object.entries(accompanyingObjects).map(async ([key, val]) => {
        const idFromKey = (manifestData as any)?.data?.accompanying?.[key]?.fileId;
        if (!idFromKey) {
          return;
        }
        const jsonBlob = new Blob([JSON.stringify(val)], { type: ContentType.Json });
        const wrappedJsonBlob = blobShimNeeded ? await SaveObjectContainingBlobs(jsonBlob) : jsonBlob;
        fileIdsToPublish.push(idFromKey);
        return { id: idFromKey, blob: wrappedJsonBlob };
      }
      ))).filter(Predicate.isNotNull)
      : [];
    if (debugOpts?.logFn) {
      debugOpts.logFn(`${accompanyingFiles.length} accompanying files made`);
    }

    traceBlobType('write', data, contentType);
    if (debugOpts?.logFn) {
      debugOpts.logFn('begin db transaction');
    }
    await db.transaction('rw', db.fileData, db.fileMeta, async () => {
      if (debugOpts?.logFn) {
        debugOpts.logFn('check for existing file meta');
      }
      const meta = await db.fileMeta.get(id);
      if (meta && !meta.failure) {
        const updateObj: Partial<IFileMeta> = {
          ...(entityId ? { propertyEntityId: entityId } : {}),
          fileStatus: StorageItemFileStatus.Available,
          syncStatus
        };
        if (contentType && overwriteContentType) {
          updateObj.contentType = contentType;
        }
        if (debugOpts?.logFn) {
          debugOpts.logFn('update file meta');
        }
        await db.fileMeta.update(id, updateObj);
      } else {
        if (debugOpts?.logFn) {
          debugOpts.logFn('put file meta');
        }
        await db.fileMeta.put({
          id,
          ...(entityId ? { propertyEntityId: entityId } : {}),
          type,
          fileStatus: StorageItemFileStatus.Available,
          contentType,
          size: data.size,
          syncStatus,
          relatedData,
          manifestData,
          addedTimeMs: new Date().getTime()
        });
      }

      if (debugOpts?.logFn) {
        debugOpts.logFn('decide logo redux dispatch?');
      }
      const entityLogoRelated = (meta?.relatedData || relatedData)?.entityLogo;
      if (entityLogoRelated && store) {
        if (debugOpts?.logFn) {
          debugOpts.logFn('yes, logo redux dispatch');
        }
        store.dispatch(markLogoUpdated({ id: entityLogoRelated.entityId.toString(), updatedTime: Date.now() }));
      } else if (entityLogoRelated && !store) {
        if (debugOpts?.logFn) {
          debugOpts.logFn('should logo redux dispatch, but no store');
        }
        throw new Error('No store provided to set entity logo!');
      }

      if (store) {
        store.dispatch(addAvailableFile({ id }));
      }

      if (debugOpts?.logFn) {
        debugOpts.logFn('put file data');
      }
      await db.fileData.put({
        id,
        data: wrappedBlob
      });

      if (accompanyingFiles) {
        if (debugOpts?.logFn) {
          debugOpts.logFn(`wait for ${accompanyingFiles.length} accompanying files to write`);
        }
        await Promise.all(
          accompanyingFiles.map(async af => {
            const { id, blob } = af;

            await db.fileMeta.put({
              id,
              type: FileType.PropertyFile,
              fileStatus: StorageItemFileStatus.Available,
              contentType: ContentType.Json,
              size: data.size,
              syncStatus: StorageItemSyncStatus.None,
              relatedData: relatedData,
              addedTimeMs: new Date().getTime()
            });

            await db.fileData.put({
              id,
              data: blob
            });

            if (store) {
              store.dispatch(addAvailableFile({ id }));
            }
          })
        );
      }
    });

    if (syncStatus === StorageItemSyncStatus.PendingUpload && ydoc) {
      applyMigrationsV2_1({
        doc: ydoc,
        docKey: PropertyRootKey.FileTrack,
        typeName: 'Property',
        migrations: [{
          name: 'Publishing file to file tracker doc',
          fn: (draft: FileTrackDocRoot) => {
            if (!draft.files) {
              draft.files = {};
            }
            for (const fid of fileIdsToPublish) {
              if (!draft.files[fid]) {
                draft.files[fid] = {
                  state: FileTrackState.NotUploaded
                };
                continue;
              }
              console.warn('Not clobbering existing file ID track state');
            }
          }
        }]
      });
    }

    if (debugOpts?.logFn) {
      debugOpts.logFn('write completed');
    }
  }

  /**
   *
   * @param id ID seems to be a file identifier, used for deduplication
   * @param type
   * @param contentType
   * @param relatedData
   * @returns
   */
  static async queueDownload(
    id: string,
    type: FileType,
    contentType: string,
    relatedData: IFileRelatedData,
    { syncPriority, entityId }: {syncPriority?: StorageItemSyncStatus.PendingDownload | StorageItemSyncStatus.PendingDownloadHighPriority, entityId?: string | number} = {}
  ) {
    return db.transaction('rw', db.fileData, db.fileMeta, async () => {
      const meta = await db.fileMeta.get(id);
      if (meta?.relatedData?.entityLogo && meta?.relatedData?.entityLogo.logoUri === relatedData?.entityLogo?.logoUri) {
        return false;
      }
      const newQueueHigherPriority = syncPriority != null && meta && (meta?.syncStatus == null || (syncJobOrder.includes(meta.syncStatus) && syncJobOrder.findIndex(s=>s===meta.syncStatus) < syncJobOrder.findIndex(s=>s===syncPriority)));

      if (meta && !newQueueHigherPriority && !meta?.relatedData?.entityLogo) {
        return false;
      }

      if (newQueueHigherPriority) {

        db.fileMeta.update(id, { syncStatus: syncPriority, addedTimeMs: new Date().getTime() });
        return false; // Yes we've updated it, but it was already in the queue
      }

      const targetPriority = syncPriority??StorageItemSyncStatus.PendingDownloadHighPriority;

      await db.fileMeta.put({
        id,
        ...(entityId ? { propertyEntityId: entityId } : {}),
        type,
        fileStatus: StorageItemFileStatus.Unavailable,
        contentType,
        syncStatus: targetPriority,
        relatedData,
        addedTimeMs: new Date().getTime()
      });

      return true;
    });
  }

  static async updateStatus(id: string, syncStatus?: StorageItemSyncStatus, fileStatus?: StorageItemFileStatus, failureMessage?: string) {
    if (syncStatus === undefined && fileStatus === undefined) {
      return;
    }

    await db.transaction('rw', db.fileMeta, async () => {
      const meta = await db.fileMeta.get(id);
      if (!meta) {
        return;
      }

      if (meta.fileStatus === StorageItemFileStatus.Available) {
        fileStatus = StorageItemFileStatus.Available;
      }

      if (meta.fileStatus === fileStatus && meta.syncStatus === syncStatus) {
        return;
      }

      if (syncStatus !== undefined) {
        meta.syncStatus = syncStatus;
      }
      if (fileStatus !== undefined) {
        console.log('updateStatus', id, fileStatus);
        meta.fileStatus = fileStatus;
      }
      if (failureMessage) {
        meta.failure = {
          message: failureMessage
        };
      } else {
        delete meta.failure;
      }

      await db.fileMeta.put(meta);
    });
  }

  static async delete(id: string) {
    await db.transaction('rw', db.fileData, db.fileMeta, async () => {
      await db.fileMeta.delete(id);
      await db.fileData.delete(id);
    });
  }

  static async byFileStatus(fileStatus: StorageItemFileStatus): Promise<IFileMeta[]> {
    return await db.fileMeta.where('fileStatus').equals(fileStatus).toArray();
  }

  static async getLatestSyncJobs(currentRunTS: number) {
    return await db.fileMeta.where('addedTimeMs').above(currentRunTS).toArray();
  }

  static async bySyncStatus(syncStatus: StorageItemSyncStatus): Promise<IFileMeta[]> {
    return await db.fileMeta.where('syncStatus').equals(syncStatus).toArray();
  }

  static async requeueFailedDownloads() {
    await db.transaction('rw', db.fileMeta, async () => {
      const files = await db.fileMeta.where('fileStatus').equals(StorageItemFileStatus.Failed).toArray();

      console.log('requeue', files.length, 'failed files', files.map(f => f.id));
      for (const file of files) {
        file.fileStatus = StorageItemFileStatus.Unavailable;
        file.syncStatus = StorageItemSyncStatus.PendingDownload;
        if (file.failure) {
          delete file.failure;
        }
        await db.fileMeta.put(file);
      }
    });
  }

  static async requeueUpload(fileId: string) {
    await db.transaction('rw', db.fileMeta, async () => {
      const files = await db.fileMeta.where('id').equals(fileId).toArray();

      console.log('requeue upload', files.map(f => f.id).join(' '));
      for (const file of files.filter(f => f.fileStatus === StorageItemFileStatus.Available)) {
        file.syncStatus = StorageItemSyncStatus.PendingUpload;

        if (file.failure) {
          delete file.failure;
        }
        await db.fileMeta.put(file);
      }
    });
  }

  public static async requeueIndividualDownload(fileId: string) {
    await db.transaction('rw', db.fileMeta, async () => {
      const files = await db.fileMeta.where('id').equals(fileId).toArray();

      console.log('requeue file', files.map(f => f.id).join(' '));
      for (const file of files) {
        file.fileStatus = StorageItemFileStatus.Unavailable;
        file.syncStatus = StorageItemSyncStatus.PendingDownload;
        if (file.failure) {
          delete file.failure;
        }
        await db.fileMeta.put(file);
      }
    });
  }

  static async alterManifestData(id: string | undefined, alterFn: (data: ManifestData) => void) {
    if (!id) return;

    const fileId = id;
    await db.transaction('rw', db.fileMeta, async () => {
      const meta = await db.fileMeta.get(fileId);
      if (!meta?.manifestData) {
        throw new Error('manifest data not found');
      }

      const manifestData = meta.manifestData;
      alterFn(manifestData);
      await db.fileMeta.update(fileId, { manifestData });
    });
  }

  static async replaceManifestAndOrRelated(fileId: string, { manifest, related }: {manifest?: ManifestData, related?: IFileRelatedData}) {
    if (!fileId || (!manifest && !related)) {
      // nothing to do
      return;
    }
    await db.transaction('rw', db.fileMeta, async () => {
      const meta = await db.fileMeta.get(fileId);
      if (!meta) {
        throw new Error('metadata for this file not found');
      }

      await db.fileMeta.update(fileId, {
        ...(manifest ? { manifestData: manifest } : {}),
        ...(related ? { relatedData: related } : {})
      });
    });
  }
}
