
export const saaNonUniqueWatchPaths = {
  property: [
    ['saleAddrs'],
    ['saleTitles'],
    ['titleDivision']
  ],
  vendor: [['vendors'],['primaryVendor']],
  agency: [
    ['agent', '[*]', 'linkedEntityId'],
    ['agent', '[*]', 'salesp', '[*]', 'linkedSalespersonId'],
    ['authAssoc'],
    ['assocAgents'],
    ['agencyExclusive'],
    ['agency'],
    ['otherAgents'],
    ['prevAgentEnable'],
    ['prevAgentConfirm'],
    ['prevAgents'],
    ['prevAgentExpire'],
    ['prevAgentNotice'],
    ['agentAuthority']
  ],
  sale: [
    ['sale', 'advertRange'],
    ['sale', 'advertPrc'],
    ['sale', 'advertPrcUpper'],
    ['sale', 'vendorPrc'],
    ['sale', 'agentEstPrc'],
    ['compareAddrs'],
    ['disableCompareAlreadyProvided']
  ],
  saleMethod: [
    ['sale', 'saleMethod'],
    ['sale', 'saleMethodOther'],
    ['auction']
  ],
  particulars: [
    ['contractSettlement'],
    ['chattels'],
    ['inclExclOpts'],
    ['exclusions'],
    ['encroach'],
    ['encroachEnable'],
    ['notiWorks'],
    ['notiWorksEnable'],
    ['vendorWorks'],
    ['specialTerms'],
    ['cgtPerson'],
    ['gstWithholdEnable'],
    ['pool'],
    ['vendorGst'],
    ['saaGstUnknowns', 'vendorGst'],
    ['saaGstUnknowns', 'inputTaxed'],
    ['contractPrice', 'inputTaxed'],
    ['saaTenant', 'tenantEnable'],
    ['tenantsCollect']
  ],
  marketing: [
    ['marketingFees'],
    ['pressBudget'],
    ['marketingFeesOptions', 'vendorPaidAdvertising'],
    ['searchFees'],
    ['fixedSearchFees'],
    ['adminFee'],
    ['auctionFee'],
    ['auctionFeeSwitches'],
    ['benefits'],
    ['marketingTemplate']
    /* Variations for general terms not yet implemented
    ['form1AndSearches'],
    ['transactionFee']
    */
  ],
  proFees: [
    ['professFee'],
    ['uplift'],
    ['payTerm']
  ]
};
