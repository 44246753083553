import { ValidationDefnType } from '../validation-field';
import { fieldGroups as scFieldGroups } from './sale-contract-residential';

export const saleContractVariationOverlay: ValidationDefnType = {
  coolingOff: {
    purchaserRight: { _required: false }
  }
};

export const scvFieldGroups = {
  ...scFieldGroups
};