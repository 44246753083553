// Types
import {
  AuthorityParty,
  MaterialisedPropertyData,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { Maybe } from '@property-folders/common/types/Utility';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { propertyFolderMaskDataNotRelevant, transformPropertyFolderDataForDisplay } from '@property-folders/common/util/pdfgen/display-transformations';
import { FormUtil } from '@property-folders/common/util/form';
import {
  calculateFinalPrice2
} from '@property-folders/common/util/pdfgen/sections/contract-price';
import { SignatureSection } from '@property-folders/common/util/pdfgen/sections/signatureSection';
import { Predicate } from '@property-folders/common/predicate';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { prepareData, salesContractCoverPage } from '../documents/sales-contract-cover-page';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { residentialSalesContract } from '@property-folders/common/util/pdfgen/definitions';
import { CustomObjects, DefinitionMode, GetterBinder, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { agencyLogoImageToString } from './residential-sales-agreement-pdf';
import { BelongingEntityMeta } from '../../../../redux-reducers/entityMeta';
export class SaleContractPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<GetterBinder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<GetterBinder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean,
    private templateContractMode?: boolean,
    private offerContractMode?: boolean
  ) { }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  async getCoverPageDefinitionForPdfWorker(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<any> {
    const agencyLogoImage = await agencyLogoImageToString(objects?.agencyLogoImage);

    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    return {
      ...prepareData({
        agencyLogoImage: agencyLogoImage ?? '',
        brand: brand,
        completedAtMs: objects?.completedAtMs ?? Date.now(),
        dataBinder: this.dataBinder,
        formCode: this.formCode,
        formId: this.formId,
        metaBinder: this.metaBinder
      }),
      formType: 'ResidentialSalesContractPDF'
    };
  }

  async getCoverPage(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<TDocumentDefinitions> {
    const agencyLogoImage = await agencyLogoImageToString(objects?.agencyLogoImage);

    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    return salesContractCoverPage({
      agencyLogoImage: agencyLogoImage ?? '',
      brand: brand,
      completedAtMs: objects?.completedAtMs ?? Date.now(),
      dataBinder: this.dataBinder,
      formCode: this.formCode,
      formId: this.formId,
      metaBinder: this.metaBinder
    });
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    _changeSet?: DiffCollection,
    _changeOriginal?: MaterialisedPropertyData,
    _snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride?: TransactionMetaData
      finalBuildESigningMode?: boolean
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts??{};
    const templateContractMode = !!this.templateContractMode;

    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const familyState = FormUtil.getFormFamilyState(this.formCode, meta);
    const formInstance = FormUtil.getFormInstanceFromFamilyState(familyState, this.formId);
    const clausesId = familyState?.clauseChildId;
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formInstance && !templateContractMode) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
    partyMap.set(SigningPartySourceType.Vendor, property?.vendors??[]);
    partyMap.set(SigningPartySourceType.Purchaser, property?.purchasers??[]);
    const signers = mode === DefinitionMode.Signing && !templateContractMode
      ? await SignatureSection.buildSignersFromSigningSession(formInstance, propertyRaw)
      : await SignatureSection.buildSignersForPreview({ authorityParties: partyMap, propertyData: propertyRaw, formCode: this.formCode, memberEntities });

    const { inputTaxed } = property?.contractPrice ?? {};
    let { finalPriceDisplay } = calculateFinalPrice2(property?.vendorGst, property?.contractPrice);
    if (finalPriceDisplay && Predicate.boolFalse(inputTaxed)) finalPriceDisplay = finalPriceDisplay + ' (Including GST)';

    return {
      objects,
      brand,
      property,
      propertyRaw,
      signers,
      formType: 'ResidentialSalesContractPDF',
      previousFormInstance: undefined,
      clausesId,
      annexures,
      options: { templateContractMode, offerContractMode: this.offerContractMode, finalBuildESigningMode: opts?.finalBuildESigningMode },
      noBoldContentMode: noBoldContentMode??false,
      formFamilyState: familyState,
      currentFormInstance: formInstance
    };
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions> {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      changeOriginal,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );

    return residentialSalesContract(definition);
  }
}
