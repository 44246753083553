import { notBeforeDate } from '../custom-point-validators/notBeforeDate';
import { CustomPointValidationFunction } from '@property-folders/contract/yjs-schema/model/custom-point-validator';
import { noCommonValues } from './no-common-values';
import { matchesExpectations } from './matches-expectation';

export const customValidatorsDirectory: {[functionName:string]: CustomPointValidationFunction | undefined} = {
  notBeforeDate,
  noCommonValues,
  matchesExpectations
};
