import { reaformsCharcoal } from '@property-folders/common/visual';
import { fieldsSpacing } from '.';
import { defaultFormBrandConfig, minimumFontSize } from './constants';

import type { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';

/**
 * Default styles object.
 * @param brand Brand colors.
 * @param noBoldContentMode Do not allow any bold content by default.
 * @returns Default styles object.
 * @example
 * ```ts
 * // This will use the `sectionFreeText` style object.
 * { text: 'This is my PDFMake text object!', style: 'sectionFreeText' }
 *
 * // Overwrite specific style properties
 * { text: 'This is my PDFMake text object!', style: 'sectionFreeText', color: 'red' }
 *
 * // Use value to make new style relative to another style
 * const { fontSize } = pdfDefaultStyles().sectionFreeText;
 * { text: 'This is my PDFMake text object!', style: { color: 'red', fontSize } }
 * ```
 */
export const pdfDefaultStyles = (brand: EntityBrandFormConfig = defaultFormBrandConfig, noBoldContentMode: boolean = false) => ({
  hiddenText: {
    fontSize: 0,
    margin: [0, 0, 0, 0]
  },
  coverStatement: {
    alignment: 'center',
    fontSize: 22,
    margin: [0, 0, 0, 0]
  },
  coverTitle: {
    alignment: 'center',
    bold: true,
    fontSize: 22,
    margin: [0, 0, 0, 0]
  },
  documentTitle: {
    bold: true,
    fontSize: 20,
    margin: [0, 0, 0, 0]
  },
  documentTitleL2: {
    alignment: 'center',
    color: brand.headingColour,
    fontSize: 20,
    margin: [0, 0, 0, 0]
  },
  documentSubtitle: {
    alignment: 'center',
    color: brand.headingColour,
    fontSize: 14,
    margin: [36, 9, 36, 9]
  },
  coverHeading: {
    bold: true,
    color: brand.headingColour,
    fontSize: 12
  },
  sectionTitle: {
    fontSize: 20,
    margin: [0, 0, 0, 8]
  },
  sectionSubTitle: {
    bold: true,
    color: brand.headingColour,
    fontSize: 12
  },
  sectionDetailSubTitle: {
    bold: true,
    color: brand.headingColour,
    fontSize: minimumFontSize
  },
  sectionText: {
    fontSize: 10.5
  },
  importantSectionText: {
    fontSize: 12
  },
  noticeToPurchaser: {
    bold: true,
    fontSize: 12
  },
  detailText: {
    fontSize: minimumFontSize
  },
  signature: {
    color: '#2f3946',
    font: 'SquarePeg',
    fontSize: 24
  },
  boldEmphasis: {
    bold: true,
    color: 'black'
  },
  content: noBoldContentMode ? {} : {
    bold: true,
    color: 'black',
    fontSize: 10.5
  },
  fieldContent: noBoldContentMode ? {} : {
    bold: true,
    color: 'black',
    fontSize: 10.5
  },
  fieldContentMini: noBoldContentMode ? {} : {
    bold: true,
    color: 'black',
    fontSize: minimumFontSize
  },
  majorContent: noBoldContentMode ? {} : {
    alignment: 'center',
    bold: true,
    color: 'black',
    fontSize: 12
  },
  header: {
    bold: true,
    fontSize: 12
  },
  headerAgencyContact: {
    fontSize: 7
  },
  fieldFontGeneral: {
    fontSize: 10.5
  },
  footer: {
    fontSize: 8
  },
  tableFieldLabel: {
    bold: false,
    color: brand.headingColour,
    fontSize: 10.5,
    margin: [0, 0, 2, 0]
  },
  tableFieldLabelMini: {
    bold: false,
    color: brand.headingColour,
    fontSize: 10.5,
    margin: [0, 0, 2, 0]
  },
  tableFieldLabelLessened: {
    bold: false,
    color: reaformsCharcoal,
    fontSize: 10.5,
    margin: [0, 0, 2, 0]
  },
  tableFieldOption: noBoldContentMode ? {} : {
    bold: true,
    color: 'black',
    fontSize: 10.5
  },
  titleCheckBox: {
    bold: false,
    color: reaformsCharcoal,
    fontSize: 11
  },
  titleCheckBoxSelected: noBoldContentMode ? {
    bold: false,
    color: reaformsCharcoal,
    fontSize: 11
  } : {
    bold: true,
    color: reaformsCharcoal,
    fontSize: 11
  },
  tableFieldGeneralText: {
    fontSize: 10.5
  },
  sectionFreeText: {
    fontSize: 10.5
  },
  listMarker: {
    lineHeight: 1.2,
    margin: [0, fieldsSpacing * 2, 0, 0],
    markerColor: brand.headingColour
  },
  checkbox: {}
});

// The primary default style if no style is specified
export const defaultStyle = {
  font: 'Roboto',
  fontSize: minimumFontSize,
  color: reaformsCharcoal
};

export const FieldPlaceholderStyle = {
  Abn: 'abn',
  Amount: 'amount',
  Date: 'date',
  Default: 'default',
  Email: 'email',
  Location: 'location',
  LongLocation: 'longlocation',
  Mobile: 'mobile',
  Name: 'name',
  Other: 'other',
  OtherLong: 'otherLong',
  Percentage: 'percentage',
  Price: 'price',
  Rla: 'rla',
  Signature: 'signature',
  Time: 'time'
} as const;

export type FieldPlaceholderStyleType = typeof FieldPlaceholderStyle;

export type FieldPlaceholderStyleValue = FieldPlaceholderStyleType[keyof FieldPlaceholderStyleType];

export const FieldPlaceholder = {
  [FieldPlaceholderStyle.Abn]: {
    width: 12
  },
  [FieldPlaceholderStyle.Amount]: {
    width: 8
  },
  [FieldPlaceholderStyle.Date]: {
    width: 8
  },
  [FieldPlaceholderStyle.Default]: {
    linesIfEmpty: 1,
    prefix: '',
    suffix: '',
    // If this is changed to a string instead of a number, you will have to change drawUnderline functions to have a fallback number
    width: 10
  },
  [FieldPlaceholderStyle.Email]: {
    width: '*'
  },
  [FieldPlaceholderStyle.Location]: {
    width: '*'
  },
  [FieldPlaceholderStyle.LongLocation]: {
    width: 38
  },
  [FieldPlaceholderStyle.Mobile]: {
    width: 12
  },
  [FieldPlaceholderStyle.Name]: {
    width: '*'
  },
  [FieldPlaceholderStyle.Other]: {
    width: '*'
  },
  [FieldPlaceholderStyle.OtherLong]: {
    width: 30 // * seems to just fall to the default value
  },
  [FieldPlaceholderStyle.Percentage]: {
    suffix: '%',
    width: 8
  },
  [FieldPlaceholderStyle.Price]: {
    prefix: '$',
    width: 8
  },
  [FieldPlaceholderStyle.Rla]: {
    width: 12
  },
  [FieldPlaceholderStyle.Signature]: {
    width: 27
  },
  [FieldPlaceholderStyle.Time]: {
    width: 8
  }
} as const;

export type FieldPlaceholderType = typeof FieldPlaceholder;
