import { CustomObjects, DefinitionMode, IPdfDefinitionProvider } from '../../../../types/PDFDefinition';
import { Maybe } from '../../../../types/Utility';
import { Binder } from 'immer-yjs/src/immer-yjs';
import {
  AuthorityParty,
  MaterialisedPropertyData,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { PdfWorkerDocumentDefinition } from '../../../pdf/pdf-worker-types';
import {
  propertyFolderMaskDataNotRelevant,
  transformPropertyFolderDataForDisplay
} from '../../display-transformations';
import { FormUtil } from '../../../form';
import { SignatureSection } from '../../sections/signatureSection';
import { sectionTitles as st } from '../../doc-constants/sale-contract';
import { calculateFinalPrice2 } from '../../sections/contract-price';
import { Predicate } from '../../../../predicate';
import { residentialPurchaseOffer } from '../documents/residential-purchase-offer';
import { BelongingEntityMeta } from '../../../../redux-reducers/entityMeta';

export class ResidentialPurchaseOfferPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<Binder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean
  ) { }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  async getCoverPageDefinitionForPdfWorker(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<any> {
    return undefined;
    // const agencyLogoImage = await agencyLogoImageToString(objects?.agencyLogoImage);
    //
    // if (!(this.dataBinder && this.metaBinder)) {
    //   throw new Error('Cannot generate preview, data binders are not initialised');
    // }
    //
    // return {
    //   ...prepareData({
    //     agencyLogoImage: agencyLogoImage ?? '',
    //     brand: brand,
    //     completedAtMs: objects?.completedAtMs ?? Date.now(),
    //     dataBinder: this.dataBinder,
    //     formCode: this.formCode,
    //     formId: this.formId,
    //     metaBinder: this.metaBinder
    //   }),
    //   formType: 'ResidentialPurchaseOfferPDF'
    // };
  }

  async getCoverPage(brand: EntityBrandFormConfig, objects?: CustomObjects) {
    return undefined;
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ) {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      changeOriginal,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );

    return residentialPurchaseOffer(definition);
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    _changeSet?: DiffCollection,
    _changeOriginal?: MaterialisedPropertyData,
    _snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride: TransactionMetaData
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts??{};
    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const familyState = FormUtil.getFormFamilyState(this.formCode, meta);
    const formInstance = FormUtil.getFormInstanceFromFamilyState(familyState, this.formId);
    const clausesId = familyState?.clauseChildId;
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formInstance) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
    partyMap.set(SigningPartySourceType.Vendor, property?.vendors??[]);
    partyMap.set(SigningPartySourceType.Purchaser, property?.purchasers??[]);
    const signers = mode === DefinitionMode.Signing
      ? await SignatureSection.buildSignersFromSigningSession(formInstance, propertyRaw)
      : await SignatureSection.buildSignersForPreview({ authorityParties: partyMap, propertyData: propertyRaw, formCode: this.formCode, memberEntities });

    // The section titles are all upper case, but we want this text to be 'normal' as it is also
    // used in other places.
    let vendorPartyLabel = st.vendors.title.toLocaleLowerCase();
    vendorPartyLabel = vendorPartyLabel[0].toLocaleUpperCase() + vendorPartyLabel.slice(1);
    let purchaserPartyLabel = st.purchasers.title.toLocaleLowerCase();
    purchaserPartyLabel = purchaserPartyLabel[0].toLocaleUpperCase() + purchaserPartyLabel.slice(1);

    const { inputTaxed } = property?.contractPrice ?? {};
    let { finalPriceDisplay } = calculateFinalPrice2(property?.vendorGst, property?.contractPrice);
    if (finalPriceDisplay && Predicate.boolFalse(inputTaxed)) finalPriceDisplay = finalPriceDisplay + ' (Including GST)';

    return {
      objects,
      brand,
      property,
      propertyRaw,
      signers,
      formType: 'ResidentialPurchaseOfferPDF',
      previousFormInstance: undefined,
      clausesId,
      annexures,
      noBoldContentMode: noBoldContentMode??false,
      formFamilyState: familyState,
      currentFormInstance: formInstance
    };
  }
}
