import { documentSubTitle, fieldFocus, itemSection, spaceStackLinesSideEffect } from '..';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { Clause } from '@property-folders/contract/property';
import { Remarkable } from 'remarkable';
import { pdfMakeRenderer } from '../pdfMakeRenderer';
import { setNumberingSideEffect } from '../formatters/setNumberingSideEffect';
import { mmToPoints } from '../measurements';
import { clause } from '../formatters/clauses';
import type { ReaformsContent } from '../formatters/clauses';
import { processTemplateSimple, ReplacementTokens, TokenReplacementData } from '../../process-template';
import { canonicalisers } from '../../formatting';

const remarkable = new Remarkable();
remarkable.use(pdfMakeRenderer);

export const clauseReplacementTokens: ReplacementTokens = {
  '@@planDepositDueDate': {
    value: d =>  d?.property?.data?.titleDivision?.planDue12
      ? '12 months after contract date'
      : date(d?.property?.data?.titleDivision?.planDueDate, '______________________ **[insert date]**')
  }
};

export function clauseItemRender(c: Clause, idx: number, templateData: TokenReplacementData) {
  const text = processTemplateSimple(c.text, templateData, clauseReplacementTokens);
  const renderedItems = ((remarkable.render(text, { pos: idx }) as unknown) as any[]);

  return clause(c.title || `[Clause ${idx + 1} title]`,
    (renderedItems.map((node) => setNumberingSideEffect(node, 1, null, `${idx + 1}.`).node) as ReaformsContent)
  );
}

function getClauseItemRenderer(templateData: TokenReplacementData) {
  return (c: Clause, idx: number) => {
    return clauseItemRender(c, idx, templateData);
  };
}

export function isolatedClauseItemRenderer(c: Clause, idx: number, templateData: TokenReplacementData) {
  let stackInner = clauseItemRender(c, idx, templateData);
  // We get something different in the regular document and the variation, but we need stacks to be
  // only arrays, and we were getting stack: {stack: []}
  // Alternately, maybe this function is only called from Variations
  if (!Array.isArray(stackInner)) stackInner = [stackInner];
  return {
    thisLevelMarkerOverrides: { style: 'sectionDetailSubTitle' },
    style: 'listMarker',
    ol: [{ stack: stackInner, counter: idx + 1 }]
  };
}

export function date(value: string | undefined, defaultValue: string): string {
  if (!value) return defaultValue;
  const canon = canonicalisers.date(value);
  return canon && canon.display
    ? canon.display
    : defaultValue;
}

export function clausesSection(sectionNumber: number|undefined, clauses: Clause[], brand: EntityBrandFormConfig, templateData: any) {
  if (!clauses || clauses.length === 0) {
    return [];
  }

  const section = itemSection({
    itemNo: undefined,
    itemTitleParam: '',
    bookmark: [
      'subsection-clauses',
      'bookmark_clauses',
      ...fieldFocus('clauses')
    ],
    stackContent: [
      documentSubTitle('Additional Conditions'),
      {
        margin: [ 0, mmToPoints(5), 0, 0 ],
        thisLevelMarkerOverrides: { style: 'listMarker' },
        ol: spaceStackLinesSideEffect(clauses.map(getClauseItemRenderer(templateData)), undefined, 2)
      }
    ],
    superTitle: true,
    pageBreakBefore: true
  });

  return [section];
}
