// a lot of code stolen from https://gist.github.com/leedongwei/6015450165b70a32b72cdebc073714ab
import { CoverSheet } from '../utilpdf/pdf-worker-types';
// Add some fake DOM dependencies so that pdfmake thinks it's in the browser.
const window = self;
const document = {
  createElementNS: function () {
    return {};
  }
};

import '@eckermanns/pdfmake';
import { squarePegRegular } from '../pdfgen/font-square-peg-regular';
import fontRobotoBold from '../pdfgen/font-roboto-bold';
import fontRobotoRegular from '../pdfgen/font-roboto-regular';
import fontRobotoItalic from '../pdfgen/font-roboto-italic';
import fontRobotoBolditalic from '../pdfgen/font-roboto-bolditalic';
import fontDejavu from '../pdfgen/font-dejavu';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { pdfDefaultStyles } from '../pdfgen';
import { Predicate } from '../../predicate';
import { Annexure } from '@property-folders/contract';
import { getAnnexureCoverSheetDefinition } from '../pdfgen/sections/annexureCoverSheet';
import { stitch } from '../pdf/pdf-stitch';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { defaultStyle } from '../pdfgen/standards';

interface DocumentMetadata {
  author: string,
  title: string
}

export enum OutputType {
  ObjectUrl,
  Blob
}

const pdfMake = window['pdfMake'];
export class Pdf {
  workingDocumentDefinition: any;

  constructor() {
    this.workingDocumentDefinition = {
      content: []
    };
  }

  clear = () => {
    this.workingDocumentDefinition = {
      content: []
    };
    return this;
  };

  tryWriteVfs = (name: string, content: string) => {
    if (!pdfMake.virtualfs?.writeFileSync) {
      return;
    }

    pdfMake.virtualfs.writeFileSync(name, content, 'base64');
  };

  withRoboto = () => {
    // Load the base64 encoded fonts into the virtual file system
    pdfMake.vfs = {
      'SquarePeg-Regular.ttf': squarePegRegular,
      'Roboto-Bold.ttf': fontRobotoBold,
      'Roboto-Regular.ttf': fontRobotoRegular,
      'Roboto-Italic.ttf': fontRobotoItalic,
      'Roboto-BoldItalic.ttf': fontRobotoBolditalic,
      'dejavu.ttf': fontDejavu // This font file should now only contains checkboxes. It is certainly smaller now.

    };
    // "pdfmake": "github:samc36/pdfmake#fe8caf1cc86bc089accd37bd6cf7d9d0315ca8fa",
    this.tryWriteVfs('SquarePeg-Regular.ttf', squarePegRegular);
    this.tryWriteVfs('Roboto-Bold.ttf', fontRobotoBold);
    this.tryWriteVfs('Roboto-Regular.ttf', fontRobotoRegular);
    this.tryWriteVfs('Roboto-Italic.ttf', fontRobotoItalic);
    this.tryWriteVfs('dejavu.ttf', fontDejavu);
    this.tryWriteVfs('Roboto-BoldItalic.ttf', fontRobotoBolditalic);
    // Map the fonts into pdfmake.
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Bold.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-BoldItalic.ttf'
      },
      SquarePeg: {
        normal: 'SquarePeg-Regular.ttf'
      },
      DejaVu: {
        normal: 'dejavu.ttf',
        bold: 'dejavu.ttf'
      }
    };

    this.workingDocumentDefinition = Object.assign(this.workingDocumentDefinition, {
      defaultStyle
    });

    return this;
  };
  withMetadata = (metadata: DocumentMetadata) => {
    this.workingDocumentDefinition = Object.assign(this.workingDocumentDefinition, {
      info: {
        title: metadata.title,
        author: metadata.author,
        producer: 'reaforms Document Service',
        creator: 'reaforms Document Service'
      }
    });
    return this;
  };
  withDraft = () => {
    this.workingDocumentDefinition = Object.assign(this.workingDocumentDefinition, {
      watermark: { text: 'DRAFT', color: 'gray', opacity: 0.1, bold: true, italics: false }
    });
    return this;
  };
  withDefaultStyles = (brand: EntityBrandFormConfig, noBoldContentMode: boolean = false) => {
    this.workingDocumentDefinition = Object.assign(this.workingDocumentDefinition, {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      //pageMargins: [54, 72, 54, 54],
      styles: pdfDefaultStyles(brand, noBoldContentMode)
    });
    return this;
  };

  public prepare(brand: EntityBrandFormConfig, meta: {
    agentName?: string,
    documentLabel?: string,
    headline?: string
  }, noBoldContentMode: boolean): Pdf {
    return this
      .clear()
      .withRoboto()
      .withDefaultStyles(brand, noBoldContentMode)
      .withMetadata({
        title: [meta.documentLabel, meta.headline].filter(Predicate.isTruthy).join(' - '),
        author: meta.agentName ? `${meta.agentName} via reaforms` : 'reaforms'
      });
  }

  public generateBlob = (documentDefinition: TDocumentDefinitions, callback: (blob: Blob) => void) => {
    const final = Object.assign(this.workingDocumentDefinition, documentDefinition);
    const pdf = pdfMake.createPdf(final);
    pdf.getBlob().then((blob: Blob) => callback(blob));
    return this;
  };

  public generateBuffer = (documentDefinition: TDocumentDefinitions, callback: (buffer: Uint8Array) => void) => {
    const final = Object.assign(this.workingDocumentDefinition, documentDefinition);
    const pdf = pdfMake.createPdf(final);
    pdf.getBuffer().then((buffer: Uint8Array) => {
      callback(buffer);
    });
    return this;
  };

  public generateBufferAsync = (documentDefinition: TDocumentDefinitions) => {
    return new Promise<Uint8Array>(resolve => {
      this.generateBuffer(documentDefinition, resolve);
    });
  };

  public generateBlobAsync = (documentDefinition: TDocumentDefinitions) => {
    return new Promise<Blob>(resolve => {
      this.generateBlob(documentDefinition, resolve);
    });
  };

  public generateObjectUrl(documentDefinition: TDocumentDefinitions, callback: (objectUrl: string) => void) {
    return this.generateBlob(documentDefinition, blob => {
      callback(URL.createObjectURL(blob));
    });
  }

  public async generateAnnexure ({
    annexure,
    brand,
    coversheet,
    buffers
  }: {buffers: ArrayBuffer[], annexure: Annexure, brand: EntityBrandFormConfig, coversheet: CoverSheet}) {
    const coverPageDef = getAnnexureCoverSheetDefinition(annexure, brand);
    const coverPagePdf = await this.prepare(brand, coversheet).generateBufferAsync(coverPageDef);

    return this.stitch([coverPagePdf, ...buffers]);
  }

  public async stitch(pdfs: ArrayBuffer[]) {
    if (pdfs.length === 0) {
      throw new Error('stitch requires at least one pdf');
    }

    return pdfs.length > 1 ? stitch(pdfs) : pdfs[0];
  }
}

