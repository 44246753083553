import { FolderType, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { ShortId } from '../url/short-id';

export function getSignatureFrameText(meta?: Pick<TransactionMetaData, 'folderType'>): string {
  switch (meta?.folderType) {
    case FolderType.Document:
      return 'Signed on reaforms by:';
    case FolderType.MyFile:
      return 'Signed on SignAnything by:';
    case FolderType.Property:
    default:
      return 'Signed on Greatforms by:';
  }
}

export function getSignatureFrameIdText(fieldId: string, partyId?: string): string {
  if (partyId) {
    return `P${ShortId.fromUuid(partyId).toUpperCase()}`;
  }

  console.trace('could not map field to party');
  return `F${ShortId.fromUuid(fieldId).toUpperCase()}`;
}
