import { FileStorage, FileType, IFileMeta, IFileRelatedData, StorageItemFileStatus, StorageItemSyncStatus, syncDownloadTypes, SyncJobLiveOrderQueue, syncUploadTypes } from './fileStorage';
import { Properties } from '../client-api/properties';
import { ReaformsUserAssets } from '../client-api/reaformsUserAssets';
import { ContentType, IFormInstancePlusMarketingAndSnapshotData, ManifestData, ManifestType } from '@property-folders/contract';
import * as fflate from 'fflate';
import { UploadArchiveManifest } from '@property-folders/contract/rest/property';
import { StatusCodeError } from '../client-api/statusCodeError';
import { Predicate } from '../predicate';
import { AnyAction, Store } from 'redux';
import { tryParseInt } from '../util';
import { FileApi } from '../client-api/fileApi';
import { db } from './db';

const syncLockKey = 'FileSyncSyncing';
const syncLastRequeueKey = 'FileSyncLastRequeueMs';
const requeueIntervalMs = 1000 * 60 * 60;

export const propertyIdFromRelated = (related: IFileRelatedData) => related.propertyId || related.propertyFile?.propertyId || related.cachedPartyImage?.propertyId;

export class FileSync {
  public store: Store<unknown, AnyAction>;
  constructor(private agentId: number, store: Store<unknown, AnyAction>) {
    this.store = store;
  }

  private async uploadPropertyFileRef(
    propertyId: string,
    fileId: string,
    formCode: string,
    formId: string,
    signingSessionId: string | undefined,
    contentType: string,
    body: BodyInit,
    transportContentType?: string
  ) {
    const instruction = await Properties.putFileRef(propertyId, fileId, {
      query: {
        formCode,
        formId,
        signingSessionId,
        contentType,
        transportContentType
      }
    });

    if (!instruction?.url) {
      throw new Error('could not obtain pre-signed url');
    }

    const uploadResult = await fetch(instruction.url, {
      method: 'PUT',
      body,
      headers: new Headers(instruction.headers)
    });

    if (!uploadResult.ok) {
      console.error(`Failed to upload ${fileId}`, uploadResult.status, await uploadResult.text());
      throw new Error('Failed to upload file');
    }
  }

  private async uploadEntityFileRef(
    entityUuid: string,
    fileId: string,
    fileType: 'MARKETING',
    parentId: string,
    contentType: string,
    body: BodyInit,
    transportContentType?: string
  ) {
    const instruction = await FileApi.putFileRef(entityUuid, fileId, {
      fileType,
      parentId,
      contentType,
      transportContentType
    });

    if (!instruction?.url) {
      throw new Error('could not obtain pre-signed url');
    }

    const uploadResult = await fetch(instruction.url, {
      method: 'PUT',
      body,
      headers: new Headers(instruction.headers)
    });

    if (!uploadResult.ok) {
      console.error(`Failed to upload ${fileId}`, uploadResult.status, await uploadResult.text());
      throw new Error('Failed to upload file');
    }
  }

  private prepareManifestForSingleFile(meta: IFileMeta): UploadArchiveManifest {
    return {
      files: [{
        id: meta.id,
        contentType: meta.contentType,
        data: meta.manifestData
      }]
    };
  }

  private async getAccompanyingFilesToBundle(meta: IFileMeta) {
    if (![ManifestType.FormInstancePlusMarketingAndSnapshot].includes(meta?.manifestData?.manifestType || ManifestType.None)) {
      return [];
    }
    if (meta?.manifestData == null) {
      return [];
    }
    const accompanying = (meta.manifestData?.data as unknown as IFormInstancePlusMarketingAndSnapshotData)?.accompanying;
    if (!accompanying) {
      return [];
    }
    const rval = (await Promise.allSettled(Object.entries(accompanying).map(async ([accKey, { fileId }]) => {

      const blobLoad = await FileStorage.read(fileId);
      if (!blobLoad?.data) {
        return;
      }
      const dataBuffer = new Uint8Array(await blobLoad?.data?.arrayBuffer());
      return [`${fileId}.json`, dataBuffer];
    }))).map(res=>res.status === 'fulfilled' && res.value).filter(Predicate.isNotNull);
    return rval;
  }

  private async prepareFileForUpload(meta: IFileMeta, blob: Blob): Promise<{ body: BodyInit, transportContentType?: ContentType.Zip}> {
    if (!meta.manifestData) {
      console.log('no manifest data');
      return { body: blob };
    }

    const manifest = this.prepareManifestForSingleFile(meta);
    console.log('prepare zip for upload', manifest);
    const zipContents: fflate.Zippable = {};
    zipContents[meta.id] = new Uint8Array(await blob.arrayBuffer());

    if (meta) {
      const accompanyingArray = await this.getAccompanyingFilesToBundle(meta);
      await Promise.all(accompanyingArray.map((node) => {
        if (!Array.isArray(node)) {
          return;
        }
        const [filename, buffer] = node as [string, Uint8Array];
        manifest.files.push({
          contentType: ContentType.Json,
          id: filename,
          data: {}
        });
        zipContents[filename] = buffer;
      }));
    }

    zipContents['manifest.json'] = new TextEncoder().encode(JSON.stringify(manifest));

    const zipped = fflate.zipSync(zipContents);

    return { body: zipped, transportContentType: ContentType.Zip };
  }

  private async uploadFile(meta: IFileMeta, blob: Blob) {
    console.log('uploadFile', meta);

    const { body, transportContentType } = await this.prepareFileForUpload(meta, blob);

    if (meta.type === FileType.EntityFile) {
      const { entityUuid, fileType, parentId } = meta.relatedData.entityFile||{};
      if (!entityUuid) throw new Error('entityUuid not specified for Entity File Upload');
      if (!fileType) throw new Error('fileType not specified for Entity File Upload');
      if (!parentId) throw new Error('parentId not specified for Entity File Upload');

      return this.uploadEntityFileRef(
        entityUuid,
        meta.id,
        fileType,
        parentId,
        meta.contentType,
        body,
        transportContentType);

    } else {

      if (!meta.relatedData.propertyFile) {
        console.warn(`[sync] Could not find suitable upload target for file ${meta.id}`, meta);
        throw new Error('Failed to upload file - missing relatedData.propertyFile');
      }

      const { propertyId, formId, formCode, signingSessionId } = meta.relatedData.propertyFile||{};
      if (!propertyId) throw new Error('propertyId not specified for Property File Upload');
      if (!formCode) throw new Error('formCode not specified for Property File Upload');
      if (!formId) throw new Error('formId not specified for Property File Upload');

      return this.uploadPropertyFileRef(
        propertyId,
        meta.id,
        formCode,
        formId,
        signingSessionId,
        meta.contentType,
        body,
        transportContentType);
    }
  }

  private async downloadFile(meta: IFileMeta) {
    switch (meta.type) {
      case FileType.EntityLogo:
        {
          if (!meta.relatedData.entityLogo) {
            return;
          }
          const result = await ReaformsUserAssets.getEntityLogo(meta.relatedData.entityLogo.entityId);
          await FileStorage.write(meta.id, FileType.EntityLogo, result.contentType, result.data, StorageItemSyncStatus.None, meta.relatedData, { store: this.store, ydoc: undefined }, undefined, true, undefined, { entityId: meta.propertyEntityId });
        }
        break;
      case FileType.PropertyFile:
        {
          const propertyId = propertyIdFromRelated(meta.relatedData);
          if (!propertyId) {
            console.error('could not determine property id for pending file download. Clearing reference to trigger system to set the request again, hopefully with better data');
            try {await db.fileMeta.delete(meta.id);}
            catch (e) {console.warn('Couldn\'t delete meta ref. Maybe it is gone already?', e);}
            return;
          }
          try {
            const result = await Properties.getFile(propertyId, meta.id, meta.contentType);
            await FileStorage.write(meta.id, FileType.PropertyFile, result.contentType, result.data, StorageItemSyncStatus.None, meta.relatedData, { store: this.store, ydoc: undefined }, undefined, undefined, undefined, { entityId: meta.propertyEntityId });
          } catch (e) {
            if (e instanceof StatusCodeError && e.errorCode === 503) {
              console.warn(`${e.errorMessage || 'Unavailable file'}:`, meta.id, meta.contentType, 'property id:', propertyId);
            }
            else {
              throw e;
            }
          }

        }
        break;
      case FileType.EntityFile:
        {
          const result = await FileApi.getFile(meta.relatedData.entityFile?.entityUuid, meta.id);
          await FileStorage.write(meta.id, FileType.EntityFile, result.contentType, result.data, StorageItemSyncStatus.None, meta.relatedData, { store: this.store, ydoc: undefined }, undefined, true, undefined, { entityId: meta.propertyEntityId });
        }
        break;
      default:
        console.log('not implemented!');
        break;
    }
  }

  private async tryUploadFile(meta: IFileMeta) {

    const blob = await FileStorage.read(meta.id);

    if (!blob?.data) {
      await FileStorage.updateStatus(meta.id, StorageItemSyncStatus.None, StorageItemFileStatus.Failed, 'could not find blob to upload');
      console.warn('[sync] could not find blob for file', meta.id);
      return;
    }

    if (meta.contentType === ContentType.Pdf && meta.manifestData?.manifestType == null) {
      console.warn('[sync][upload] PDFs must be uploaded with a manifestData section, even if it is type None. Not failing even though this may cause silent failures down the line', meta);
    }

    if (meta.contentType === ContentType.Pdf && meta.failure && meta.syncStatus === StorageItemSyncStatus.PendingUpload) {
      console.warn('Upload file has a failure flagged!');
    }

    try {
      await this.uploadFile(meta, blob.data);
    } catch (err: unknown) {
      console.error('tryUploadFiles error', err);
      return;
    }

    await FileStorage.updateStatus(meta.id, StorageItemSyncStatus.None);

  }

  private async tryDownloadFile(meta: IFileMeta) {
    try {
      await this.downloadFile(meta);
    } catch (err: unknown) {
      console.error(err);
      if (err instanceof StatusCodeError) {
        const { status } = err;
        if ([400, 401, 403].some(statusCode => statusCode === status)) {
          console.log('No retry allowed, mark file as failed');
          await FileStorage.updateStatus(meta.id, StorageItemSyncStatus.None, StorageItemFileStatus.Failed, err.message);
        }
      }
    }
  }

  public async requeueUpload(fileId: string, replacementData: {manifest?: ManifestData, related?: IFileRelatedData}) {
    await FileStorage.replaceManifestAndOrRelated(fileId, replacementData);
    await FileStorage.requeueUpload(fileId);
    const meta = await FileStorage.readMeta(fileId);
    if (!meta) return;
    await this.tryUploadFile(meta);
  }

  private async requeueFailed() {
    // note: assumes that this instance has taken a sync lock.
    const lastRequeueRaw = localStorage.getItem(syncLastRequeueKey);
    const lastRequeueMs = tryParseInt(lastRequeueRaw ?? undefined, 0);
    const msSinceLastRequeue = Date.now() - lastRequeueMs;
    if (msSinceLastRequeue < requeueIntervalMs) {
      return;
    }

    await FileStorage.requeueFailedDownloads();
    localStorage.setItem(syncLastRequeueKey, Date.now().toString());
  }

  public async syncFiles() {
    if (localStorage.getItem(syncLockKey)) {
      return;
    }
    localStorage.setItem(syncLockKey, 'true');

    try {
      await this.requeueFailed();
    } catch (err: unknown) {
      console.error('requeue error', err);
    }
    try {

      const liveQueue = new SyncJobLiveOrderQueue(this.store); // .next will build the queue

      // PromisePool is for node, we'll try and simulate the behaviour a bit
      const parallelSyncs = 5;
      const results = await Promise.allSettled(new Array(parallelSyncs).fill(null).map(async ()=>{
        let meta = await liveQueue.next();

        while (meta) {
          if (syncDownloadTypes.includes(meta?.syncStatus??StorageItemSyncStatus.None)) {
            await this.tryDownloadFile(meta);
          }
          if (syncUploadTypes.includes(meta?.syncStatus??StorageItemSyncStatus.None)) {
            await this.tryUploadFile(meta);
          }
          meta = await liveQueue.next();
        }
      }));
      results.forEach(e=>{
        if (e.status === 'rejected') {
          console.warn('Error in file sync pseudoworker', e.reason);
        }
      });
    } catch (err: unknown) {
      console.error('Error during file sync',err);
    } finally {
      localStorage.removeItem(syncLockKey);
    }
  }

  public static clearLock() {
    localStorage.removeItem(syncLockKey);
  }

  public static triggerSync(instance?: FileSync) {
    if (instance) {
      instance.syncFiles().then(() => console.log('sync done')).catch(console.error);
    } else {
      console.warn('Could not trigger file sync operation as file sync instance was not found');
    }
  }
}
