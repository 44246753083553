import { spaceStackLinesSideEffect, documentSubTitle } from '..';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { clause, ol, header, quote, stack, bold, formatAct } from '../formatters/clauses';
import { LegalJurisdiction } from '../constants';
import { setNumberingSideEffect } from '../formatters/setNumberingSideEffect';

export function generalConditionsForResidentialSalesAgencyAgreement(
  _brand: EntityBrandFormConfig,
  config: {
     form1AndSearches: any;
     showTransactionFeeCreditNotice: boolean;
     transactionFeeAmount: string;
     hasSearchCredits: boolean;
     searchCreditAmount?: string;
     shortMode?: boolean
  }) {
  const { shortMode } = config;
  return [
    documentSubTitle('General Conditions', { pageBreak: true }),
    {
      style: 'listMarker',
      ol: spaceStackLinesSideEffect([
        clause('Definitions and interpretations',
          ol(
            stack(
              header('Definitions'),
              'In this Agreement, unless the context otherwise requires:',
              `${quote(bold('Act'))} means the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')};`,
              !shortMode && `${quote(bold('Administration Fee'))} includes the types of services and is for the amount specified in Item 7 of the Schedule;`,
              !shortMode && `${quote(bold('Agent'))} means the Agent specified in Item 3 of the Schedule;`,
              !shortMode && `${quote(bold('Agent’s Estimated Selling Price'))} means the amount specified in Item 4 of the Schedule;`,
              `${quote(bold('Agreement'))} means the Schedule, these General Conditions, any Additional Conditions and annexures, as varied or extended;`,
              `${quote(bold('Amounts Due by Vendor'))} means Professional Fees, Marketing and Other Costs, and/or interest payable on overdue payments;`,
              !shortMode && `${quote(bold('ATO'))} means the Australian Taxation Office;`,
              !shortMode && `${quote(bold('Auction'))} means a method of sale as specified in Item 5 of the Schedule;`,
              !shortMode && `${quote(bold('Auctioneer'))} means the licensed person appointed by the Agent to conduct an Auction for the sale of the Property;`,
              !shortMode && `${quote(bold('Auction Fee'))} means the amount specified in Item 7 of the Schedule;`,
              `${quote(bold('Benefit'))} means a rebate, discount, refund or any other benefit received by any person including the Agent from a third party in respect of the provision of services associated with or in connection with the Sale of the Property;`,
              `${quote(bold('Business Day'))} means a day other than a Saturday, Sunday or a day which is a public holiday in South Australia within the meaning set out in the ${formatAct(LegalJurisdiction.SouthAustralia, 'HolidaysAct1910')};`,
              !shortMode && `${quote(bold('CBS'))} means Consumer and Business Services;`,
              !shortMode && `${quote(bold('Commission'))} means the amount specified in Item 8 of the Schedule;`,
              !shortMode && `${quote(bold('Commission Uplift'))} means the amount specified in Item 8 of the Schedule;`,
              !shortMode && `${quote(bold('Community Lot'))} means a community lot or community strata lot in a community plan deposited or intended to be deposited at Land Services SA;`,
              `${quote(bold('Contract'))} means a contract between the Vendor and a Purchaser for the transfer of the Property;`,
              !shortMode && `${quote(bold('Exclusions'))} means the excluded items specified in Item 6 of the Schedule;`,
              !shortMode && `${quote(bold('Fixed Fee'))} means the amount specified in Item 8 of the Schedule;`,
              !shortMode && `${quote(bold('Form 1 and Searches'))} means the amount specified in Item 7 of the Schedule;`,
              !shortMode && `${quote(bold('General Agency'))} means more than one agent may be appointed for the purpose of marketing and selling the Property for the Term of the Agreement;`,
              !shortMode && `${quote(bold('Greatforms Transaction Fee'))} means the amount specified in Item 7 of the Schedule;`,
              `${quote(bold('GST'))} has the meaning set out in ${formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999')};`,
              !shortMode && `${quote(bold('Inclusions'))} means the included items in Item 6 of the Schedule;`,
              !shortMode && `${quote(bold('Marketing and Other Costs'))} means the expenses and costs specified in Item 7 of the  Schedule;`,
              !shortMode && `${quote(bold('Method of Sale'))} means the method set out in Item 5 of the Schedule;`,
              !shortMode && `${quote(bold('Periodic Press Promotion Budget'))} means the amount specified in Item 7 of the Schedule;`,
              `${quote(bold('Personal Information'))} has the meaning set out in the ${formatAct(LegalJurisdiction.Commonwealth, 'PrivacyAct1988')};`,
              `${quote(bold('Pool'))} means any swimming pool as defined in the ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')}, located on the Property;`,
              !shortMode && `${quote(bold('Professional Fee'))} means the fee specified in Item 8 of the Schedule;`,
              `${quote(bold('Property'))} means:`,
              ol(
                `the freehold land (including a ${shortMode?'strata unit or community lot':'Strata Unit or Community Lot'}) or leasehold land specified in Item 1; and`,
                'all buildings and other improvements on the land, including all fixtures and fittings and Inclusions, but not the Exclusions;'
              ),
              `${quote(bold('Purchaser'))} means the Purchaser set out in the Contract, and includes their assignee or nominee;`,
              `${quote(bold('Sale'))} means the sale of the Property to the Purchaser and as specified in Item 4 of the Schedule;`,
              !shortMode && `${quote(bold('Schedule'))} means the Schedule preceding these General Conditions;`,
              !shortMode && `${quote(bold('Sole Agency'))} means the Agent appointed is the only appointed Agent for the purpose of marketing and selling the Property for the Term of the Agreement;`,
              !shortMode && `${quote(bold('Strata Unit'))} means a unit on a strata plan registered at Land Services SA;`,
              `${quote(bold('Term'))} means the duration of the Agreement specified in Item 3 of the Schedule;${shortMode?' and':''}`,
              !shortMode && `${quote(bold('Vendor'))} means the Vendor specified in Item 2 of the Schedule;`,
              !shortMode && `${quote(bold('Vendor’s Marketing Costs'))} means the amount specified in Item 7 of the Schedule;`,
              `${quote(bold('Vendor’s Representative'))} means any party holding themselves out as having the requisite authority to act on behalf of the Vendor and for the avoidance of doubt, if the Vendor is a corporation, includes a director of the corporation, and if there is more than one Vendor, includes any one of them${shortMode?'.':';'}`,
              !shortMode && `${quote(bold('Vendor’s Selling Price'))} means the amount specified in Item 4 of the Schedule; and`,
              !shortMode && `${quote(bold('Vendor’s Works'))} means any works to be carried out by the Vendor prior to settlement under a Contract, as specified in Item 6.`
            ),
            stack(
              header('Interpretation'),
              'In this Agreement, unless something else is clearly intended:',
              ol(
                'a reference to:',
                ol(
                  'a party to this Agreement includes that party’s executors, administrators, substitutes, successors and assigns;',
                  !shortMode && 'any legislation or any provision of any legislation includes:',
                  !shortMode && ol(
                    'all regulations, orders or instruments issued under the legislation or provision; and',
                    'any modification, consolidation, amendment, re-enactment, replacement or codification of such legislation or provision;',
                  ),
                  !shortMode && `${quote('including')} and similar expressions are not and must not be treated as words of limitation;`,
                  !shortMode && `${quote('notice')} or ${quote('notify')} means written notice given in the manner provided in this document for the service of notices;`,
                  !shortMode && `${quote('Person')} includes corporations, firms, unincorporated bodies, authorities and instrumentalities;`,
                  `an ${quote('Item')} means an Item in the Schedule;`,
                  `${quote('midnight')} means 11:59:59pm of that day; and`,
                  'time means the legal time in South Australia;'
                ),
                'words or expressions importing the singular include the plural and vice versa;',
                'where:',
                ol(
                  'two or more Persons are named as a party in this Contract, it means those persons jointly and severally;',
                  'an act would be required to be done, or a time limit or period would expire, on a day which is not a Business Day, the act may be done, or the limit or period will expire, on the following Business Day;',
                  'there is anything that is due to be completed on a particular day, it will deemed to be by midnight of that day, unless otherwise specified in writing between the parties; and',
                  'where a term is denoted in bold quotation marks within a General Condition or Additional Condition, that is a defined term for the purpose of the condition in which it appears only; and',
                ),
                'a checkbox will be deemed to have been selected where the intention is clear, including where a tick or a cross are marked in the checkbox.'
              )
            )
          )
        ),
        clause('Appointment and instructions',
          ol(
            stack(
              header('Appointment'),
              'The Vendor appoints the Agent to sell the Property, and the Agent accepts the appointment, in accordance with this Agreement.',
            ),
            !shortMode && stack(
              header('Instructions'),
              `While the preference is for communications with the Vendor to be with the ${quote('Vendor 1 - Primary Contact')}, the Agent is authorised to receive instructions and communications from the Vendor’s Representative, and the Vendor indemnifies the Agent from acting upon those instructions.`
            )
          )
        ),
        clause('Agent’s authority',
          ol(
            stack(
              header('Market and sell the Property'),
              'The Agent is authorised to market and sell the Property on behalf of the Vendor on the terms set out in this Agreement.'
            ),
            stack(
              header('Accept offers'),
              'Unless expressly not authorised at Item 3 of the Schedule, the Agent is authorised to accept offers on behalf of the Vendor.'
            ),
            stack(
              header('Hold the deposit'),
              `The Vendor authorises and instructs the Agent to accept the deposit or other monies due to the Vendor under a Contract, and the Agent will hold any monies received on trust in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAgentsAct1994')}. The Agent will be entitled to receive a deposit guarantee in lieu of a cash deposit under a Contract.`
            ),
            stack(
              header('Pay Agent from monies held on trust'),
              'The Vendor irrevocably authorises the Agent to transfer to itself or a third party all Amounts Due by Vendor from any monies held on trust, upon both the monies held on trust otherwise becoming payable to the Vendor, and the Amounts Due by Vendor becoming payable to the Agent.'
            ),
            stack(
              header('Appoint associated agents'),
              'Unless expressly not authorised at Item 3 of the Schedule, the Agent is authorised to appoint other licensed agents to assist the Agent in the marketing and sale of the Property, and the Agent is entitled to share their Professional Fee with the other agent(s) at their discretion.'
            ),
            stack(
              header('Serve notices on tenants'),
              `The Agent is authorised to serve notices on any tenants at the Property on behalf of the Vendor, as required under the ${formatAct(LegalJurisdiction.SouthAustralia, 'ResidentialTenanciesAct1995')}, if applicable.`
            ),
            stack(
              header('Sign on behalf of Vendor'),
              'The Agent is authorised to sign any document associated with the proposed sale of the Property on behalf of the Vendor, including but not limited to the Contract and Form 1.'
            ),
          )
        ),
        clause('Auction',
          stack(
            'Where the Method of Sale specified in Item 5 of the Schedule is by Auction:',
            ol(
              'the Agent is authorised to engage an Auctioneer, and the Vendor will be liable to pay the Auction Fee specified in Item 7 of the Schedule, which will be payable in accordance with General Condition 6, whether or not the Property is sold at Auction;',
              !shortMode && 'the Agent will offer the Property for sale by public auction in accordance with the Public Auction of Real Property Terms and Conditions of the Real Estate Institute of South Australia and Society of Auctioneers and Appraisers SA Inc, at the time and in the manner detailed in this Agreement, and as further instructed in writing between the Vendor and the Agent;',
              'if the Property does not sell at Auction or on the day of the Auction under auction conditions, or if the Property is withdrawn from Auction by the Vendor, then the Method of Sale will automatically become Private Treaty, unless otherwise agreed in writing between the Vendor and the Agent; and',
              'the Auctioneer is authorised to sign a contract on behalf of the Vendor if the Property is sold at Auction.'
            )
          )
        ),
        clause('Form of agency',
          ol(
            stack(
              header('Sole (exclusive) Agency'),
              `Unless specified in writing to be a General (non-exclusive) Agency agreement, this Agreement will be deemed to be a Sole Agency agreement.${shortMode?'':'Under a Sole Agency agreement:'}`,
              !shortMode && ol(
                'the Agent has the exclusive right to market and sell the Property;',
                'the Agent is entitled to receive the Professional Fee in accordance with the terms of this Agreement, even if the Agent was not the one to sell the Property or introduce the Purchaser;',
                `the Vendor may not terminate this Agreement or withdraw the Sole Agency during the Term, unless General Condition ${shortMode?'11':'12'} applies; and`,
                'the Agent may terminate this Agreement at any time.'
              )
            ),
            !shortMode && stack(
              header('General (non-exclusive) Agency'),
              'If specified in writing to be a General Agency:',
              ol(
                'the Agent will be entitled to market the Property together with other appointed agents and introduce or procure a purchaser for the Property;',
                'the Vendor will do all things to assist the Agent to sell the Property to the Purchaser; and',
                'the Agent may terminate this Agreement at any time.'
              )
            )
          )
        ),
        clause('Marketing and Other Costs',
          ol(
            `The Marketing and Other Costs are in addition to any Professional Fee, and are made up of one or more of the Vendor’s Marketing Costs, ${shortMode?'':'Periodic Press Promotion Budget, '}Administration Fee, Auction Fee, Form 1 and Searches and/or Greatforms Transaction Fee, as applicable.`,
            `Subject to General Condition 6, The Marketing and Other Costs are payable in accordance with General Condition ${shortMode?'10.1':'11.1'}.`,
            'Vendor’s Marketing Costs in the Schedule specifies how the Agent intends to allocate those costs, however, the Agent may vary the allocation between different types of marketing options at their discretion, subject to the total approved amount for the Vendor’s Marketing Costs not being exceeded.'
          )
        ),
        !shortMode && clause('Form 1 and Searches',
          config.form1AndSearches
        ),
        clause('Benefits',
          ol(
            `The Agent may receive Benefits on advertising and associated expenses from third parties to whom the Agent has referred the Vendor, or with whom the Agent has contracted on the Vendor’s behalf for the provision of services associated with the sale of the Property. Such Benefits must be set out in this Agreement or a ${quote('Form R2 – Disclosure of Benefits')}.`,
            'The Agent advises that:',
            ol(
              'any Administration Fee and Auction Fee charged is a genuine estimate of the labour costs, direct out of pocket expenses and reasonable allocation of general overhead costs spent on marketing and compliance for each listing, and therefore any actual Benefit received by the Agent is close to $0; and',
              'in the normal course of business, suppliers to the Agent, or suppliers who the Agent might refer their clients to, might provide to the Agent entertainment, gifts, training, support and more to develop and support ongoing business relationships. The value of any such Benefit is very difficult to ascertain, and is immaterial in value when considered on a per listing basis, and is not attributable to any individual engagement or referral of services relating to a listing, and does not detract from the Agent’s obligations to act in the best interests of the Vendor.',
              config.showTransactionFeeCreditNotice
                ? `Where the Agent is paying the Greatforms Transaction Fee (and not the Vendor), then should Eckermann Property Forms be engaged to prepare the Form 1, the Agent could receive a Benefit in the form of a reduced Greatforms Transaction Fee under a packaged pricing option. The amount of this Benefit could range from between $0.00 to $${config.transactionFeeAmount} including GST.`
                : undefined,
              config.hasSearchCredits
                ? `The Agent will receive a searches credit from reaforms for property searches undertaken or to be undertaken in the amount of $${config.searchCreditAmount}, which may be used by the Agent for searches relating to this Property or any other property.`
                : undefined
            )
          )
        ),
        clause('Professional Fee',
          `The Professional Fee is made up of one or more of the following, and will become payable as set out at General Condition ${shortMode?'10.2':'11.2'}:`,
          ol(
            stack(
              header('Fixed Fee'),
              'If selected, the Vendor will pay to the Agent the Fixed Fee. Unless otherwise stated, the Fixed Fee is payable irrespective of whether a Sale is effected or not, and is payable in addition to any Commission and/or Commission Uplift.'
            ),
            stack(
              header('Commission'),
              'If selected, the Vendor will pay to the Agent the Commission, calculated by multiplying the Commission percentage by the total sale price of the Property (including GST if applicable) set out in the Contract. Unless otherwise stated, the Commission is payable in addition to any Fixed Fee and/or Commission Uplift.'
            ),
            stack(
              header('Commission Uplift'),
              'If selected, the Vendor will pay to the Agent the Commission Uplift, calculated by multiplying the Commission Uplift percentage by the amount by which the total sale price of the Property (including GST if applicable) set out in the Contract exceeds the Agreed Higher Sales Threshold. Unless otherwise stated, the Commission Uplift is payable in addition to any Fixed Fee and/or Commission.'
            )
          )
        ),
        clause('Sale',
          ol(
            'The Vendor and Agent agree that a Sale will have been effected on the earliest of the following occurring:',
            ol(
              'If a Sole Agency:',
              ol(
                'where a Contract is entered into during the Term, and it continues to settlement, Sale is effected at date of Contract;',
                'where a Contract is entered into during the Term, but is terminated by the Vendor for default of the Purchaser, Sale is effected at date of Contract;',
                'where a Contract is entered into during the Term, but is terminated by mutual agreement between the Vendor and Purchaser, Sale is effected at date of Contract;',
                'where an option agreement is entered into during the Term, which provides that either the Vendor or Purchaser can require the other party to enter into a Contract for the sale of the Property, Sale is effected at the date of the option agreement;',
                'where a bid is made at Auction or an offer is presented to the Vendor during the Term at or above the Vendor’s Selling Price (as adjusted) and otherwise on terms consistent with the terms of this Agreement, and the Vendor does not enter into a Contract, Sale is effected at the date of the Auction or date of offer;',
                'where after the Term expires the Vendor enters into a Contract with a Purchaser (or their related party or nominee) introduced by the Agent, and there is no Sole Agency with another agent in place, Sale is effected at date of Contract;',
                'where a transfer of beneficial ownership of the Property occurs from the Vendor to a transferee, and the transfer of beneficial ownership is effected by some means other than a transfer of legal ownership from the Vendor, Sale is effected at date of transfer of the beneficial interest;',
                'Notwithstanding the above, a Sale is not effected where a Contract is entered into with a Purchaser, but that Purchaser validly cools-off under the Act, or where the Purchaser is not required to settle on the Contract pursuant to a court order.'
              ),
              `If a General Agency, all of the circumstances described in General Condition ${shortMode?'9.1(a)':'10.1(a)'} apply, but where the Purchaser (or transferee) was introduced by the Agent.`
            ),
            !shortMode && stack(
              header('Transfer of beneficial ownership'),
              `Without limitation, the following satisfies a transfer of beneficial ownership contemplated by General Condition ${shortMode?'9.1(a)(vii)':'10.1(a)(vii)'}:`,
              ol(
                'where the Vendor is a corporation, a transfer of the shares of that corporation, giving control of the corporation to another party; and',
                'where the Vendor owns its interest in the Property as trustee, a transfer of the beneficial interest of the trust.'
              )
            ),
            stack(
              header('Total sale price of Property'),
              ol(
                stack(
                  'Transfer not for money',
                  'If some or all of the consideration paid by the Purchaser or transferee to the Vendor is non-monetary, and/or if the Sale is not at arm’s length for market value, then the total sale price of the Property will be calculated by reference to the market value of the Property at the date of Sale.'
                ),
                !shortMode && stack(
                  'Market value of Property',
                  `Where General Condition ${shortMode?'9.2(a)':'10.3(a)'} applies, and if the Vendor and Agent do not agree on the market value of the Property at the date of Sale, then either party may request the President of the Law Society of South Australia, or his or her nominee, to appoint a valuer who is a member of the Australian Property Institute to determine the market value of the Property (including GST if applicable) at the date of Sale. The valuer’s decision will be final and binding on both parties, and the parties will equally share the cost of the valuation.`
                )
              )
            ),
          )
        ),
        clause('Payment',
          ol(
            header('Payment of Marketing and Other Costs'),
            ol(
              'The Marketing and Other Costs will become payable irrespective of whether a Sale is effected or not, and will become payable on the earlier of:',
              ol(
                '7 days after invoice from the Agent;',
                'the payment terms as set out on an invoice from a third party outsourced provider (being at least 7 days after invoice); or',
                'settlement of the Contract.'
              ),
              'The Agent may delay listing the Property on any marketing portals until some or all Marketing and Other Costs have been paid.'
            ),
            stack(
              header('Payment of Professional Fee'),
              stack(
                'The Professional Fee will become payable by the Vendor to the Agent as follows: ',
                ol(
                  `where General Condition ${shortMode?'9.1(a)(i)':'10.1(a)(i)'} applies, or the equivalent circumstances under a General Agency, the Professional Fee is payable at settlement;`,
                  'where the Sale is effected in any other circumstances, the Professional Fees is payable within 7 days of date of invoice from the Agent to the Vendor; and',
                  `where General Condition ${shortMode?'8.1':'9.1'} applies, irrespective of whether a Sale is effected or not, the Agent may invoice the Vendor for the Fixed Fee at the expiry of the Term, and the invoice is payable within 7 days of date of invoice.`
                )
              ),
            ),
            stack(header('Default in payment by Vendor'),
              shortMode
                ? 'The Vendor will pay interest on any overdue monies payable to the Agent, calculated daily at 10% per annum.'
                : ol(
                  'The Vendor will pay interest on any overdue monies payable to the Agent, calculated daily at 10% per annum.',
                  `The deposit received by the Agent under the Contract and otherwise payable to the Vendor is a collateral security charge to the Agent under the ${formatAct(LegalJurisdiction.Commonwealth, 'PersonalPropertySecuritiesAct2009')} for the Amounts Due by Vendor to the Agent. The Agent can register this charge.`,
                  'Where any Marketing and Other Costs or any Professional Fees are unpaid at their due date; and',
                  ol(
                    'the Agent can issue a written notice to the Vendor, requiring payment by a date, not less than 5 Business Days after the date of the notice;',
                    `if not paid on the terms of the notice set out in General Condition ${shortMode?'10.3(c)(i)':'11.3(c)(i)'}, the outstanding monies will become a charge over the Property and any other real property owned by the Vendor, until the Agent is paid in full;`,
                    'the Agent will not be entitled to register a caveat over the Property or any other real property owned by the Vendor to secure payment of the outstanding monies, but may apply to register a mortgage over any land owned by the Vendor to secure the charge and the Vendor must consent and not object to the mortgage being registered, even if there is a dispute between the parties about the outstanding monies; and',
                    `all costs incurred by the Agent in collecting the outstanding monies will be recoverable from the Vendor on a solicitor client basis, and the Agent is entitled to charge the Vendor $500 for each notice issued under General Condition ${shortMode?'10.3(c)(i)':'11.3(c)(i)'}.`
                  )
                )),
            stack(
              header('Credit card surcharge'),
              'The Agent may charge a surcharge to the Vendor on any payments made by the Vendor which attract bank charges and/or merchant fees.'
            ),
            stack(
              header('GST'),
              'To the extent that any supply under this Agreement is subject to GST, any amount payable by the Vendor to the Agent or a third party in respect of those supplies will be increased by the rate at which GST is imposed at that time. The Vendor will pay the increased amount to the Agent or third party at the same time payment is due under this Agreement, and the Agent or third party will render a tax invoice setting out the GST payable.'
            )
          ),
        ),
        clause('Rights of Vendor to terminate this Agreement',
          'The Vendor may terminate this Agreement where:',
          ol(
            'the Agent:',
            ol(
              'is in fundamental breach of this Agreement; and',
              'has not remedied their breach within 7 days of written notice from the Vendor; or'
            ),
            'the Vendor has contracted to purchase another property marketed by the Agent, and that contract is subject to the sale of this Property, and the other contract is terminated for any reason.'
          )
        ),
        clause('Agent’s warranty',
          'The Agent warrants that the Agent will comply with all the Agent’s obligations under the Act and will act in the Vendor’s best interests.'
        ),
        clause('Vendor’s warranties and indemnities',
          ol(
            stack(
              header('Best endeavours'),
              'The Vendor undertakes to enter into a contract for the sale of the Property if the Agent introduces a prospective purchaser, who offers to buy the Property at or above the Vendor’s Selling Price, on terms that are consistent or above those set out in this Agreement. The Vendor further undertakes to use their best endeavours to perform their obligations under the Contract and complete settlement.'
            ),
            stack(
              header('Sole Agency'),
              'Where this Agreement is for a Sole Agency, the Vendor warrants that no other agent will be appointed by the Vendor during the period of any Sole Agency, unless otherwise specified in this Agreement or agreed by the Agent in writing.'
            ),
            !shortMode && stack(
              header('Previous agents'),
              'The Vendor warrants that there have been no previous agents appointed to sell the Property, or if there have been, that any previous agent’s appointment has expired or been effectively terminated, and the previous agent will have no claim against the Agent for carrying out their appointment in accordance with this Agreement.'
            ),
            stack(
              header('Vendor warranties'),
              'The Vendor warrants that:',
              ol(
                'the Vendor has good authority and full capacity to sell the Property and enter into this Agreement with the Agent;',
                'all information provided in this Agreement and otherwise provided to the Agent is accurate and correct;',
                'the Vendor will promptly advise the Agent of any changes to the information given which comes to the Vendor’s knowledge or attention;',
                'there is no other information material to the Sale which the Vendor is aware of and should be brought to the attention of the Agent;',
                'all equipment and operating fixtures are in good working condition unless otherwise advised in writing to the Agent;',
                'if there is a Pool on the Property, then it will comply with the Pool safety legislation at settlement;',
                'the Vendor has or will provide suitable insurance cover for the Property and the Vendor’s personal property (including public liability and theft), which includes insurance cover for inspections and auctions held on the Property; and',
                'the Vendor will provide any additional information requested after this Agreement ends in a timely manner.'
              )
            ),
            stack(
              header('Vendor indemnity to Agent'),
              'The Vendor:',
              ol(
                'indemnifies the Agent against all costs, expenses, damages and loss (including any loss of fees) suffered by the Agent, and holds harmless the Agent against claims, demands, actions and losses arising from:',
                ol(
                  'the Agent acting for the Vendor; and',
                  'the Vendor’s obligations with respect to compliance of all Pool safety legislation and regulations, save and except to the extent that the costs, expenses, damages and losses are as a result of the negligence of the Agent; and'
                ),
                'holds harmless the Agent for any loss or damage whatsoever to the Property or to any goods or chattels of the Vendor or any third party in or on the Property, arising from the act or omission of any third party during any inspection or auction of the Property, unless any loss or damage is as a result of the negligence of the Agent.'
              )
            ),
          )
        ),
        clause('Form of Contract',
          `The Agent will offer the Property for sale using the Greatforms' standard ${quote('Contract of Sale - Residential')} template, as varied from time to time.`
        ),
        !shortMode && clause('Arbitration',
          ol(
            'Any dispute involving the termination of this Agreement, or involving the sum of $100,000 or less, (in the event the Vendor and Agent do not agree on an independent arbitrator) will be determined by an independent arbitrator nominated by the President of the Law Society of South Australia, or his or her nominee, at the request of either party.',
            `The determination of the arbitrator will be final and binding between the parties and the provisions of the ${formatAct(LegalJurisdiction.SouthAustralia, 'CommercialArbitrationAct2011')} will apply.`,
            'The costs of arbitration will be paid by such party or parties as directed by the arbitrator.',
            'The arbitrator will advise of the procedures to be followed and will request the payment of reasonable fees prior to acting, and in default of payment, may refuse to act.',
            `Any appeals against an award by the arbitrator must comply with section 34A of the ${formatAct(LegalJurisdiction.SouthAustralia, 'CommercialArbitrationAct2011')}.`
          )
        ),
        clause('Electronic signing and communications',
          'The parties acknowledge and agree to:',
          ol(
            'the signing by a party of this Agreement and any other documents by digital or electronic signatures; and',
            `all communications relating to the sale of the Property (including the service of any notices under the Act) by way of electronic means via the party’s email address or mobile phone number specified in this Agreement or as otherwise provided to the other party from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')}.`
          )
        ),
        clause('Counterparts',
          'This Agreement may be executed in any number of counterparts, and by a party on separate counterparts. All counterparts taken together constitute one and the same instrument.'
        ),
        clause('Privacy',
          ol(
            stack(
              header('Use of personal information'),
              `The Agent may use personal information collected from the Vendor in accordance with the ${formatAct(LegalJurisdiction.Commonwealth, 'PrivacyAct1988')} and it is agreed the Agent may disclose this information to:`,
              ol(
                'act as the Vendor’s Agent and perform the Agent’s obligations under this Agreement;',
                'promote the services of the Agent and seek new clients;',
                'disclose information to other parties including media organisations, real estate internet sites and potential purchasers to promote and report upon this proposed transaction;',
                `disclose information to other parties involved in this proposed transaction, including conveyancers, legal advisors, financial institutions, insurers, valuers and the agents of banks and financiers who may provide finance or insurance, their agents, or any other persons, tradespersons, ${quote('Vendor Paid Advertising')} finance providers, strata corporations, connections services, government and statutory bodies and for all purposes for any valuation databases; and`,
                'comply with any law including real estate and tax law.'
              ),
            ),
            stack(
              header('Vendor’s access to personal information'),
              'The Vendor may contact the Agent at the address or by the phone numbers specified in this Agreement in order to access the information held by the Agent about them and to request that their information be updated where it is inaccurate, incomplete or out of date.'
            ),
            stack(
              header('Greatforms'),
              'Greatforms may use personal information to advise the parties about potential useful services, but it will be at the Vendor’s discretion whether to engage with these. Information gathered may be used for statistical purposes, however, any such information will be anonymised. If a party requires access to this information, they may contact privacy@greatforms.com.au. '
            ),
          )
        )
      ])
    }
  ].map(node=>setNumberingSideEffect(node).node);
}
