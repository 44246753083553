import { FormCodeUnion, MaterialisedProperty } from '@property-folders/contract';
import { DbEntityType } from '@property-folders/services/lib/dal/mysql';
import { conditionTokens } from '../workflow-rules/WorkflowTriggerTemplates';

export type ReplacementTokenType = `@@${string}`;
export type ConditionTokenType = keyof typeof conditionTokens;
export type WorkflowRecipient = { name?: string, fullName?: string, email: string, phone?: string };
export type TokenReplacementData = { property: MaterialisedProperty, sublineage?: MaterialisedProperty, entity: DbEntityType, recipient: WorkflowRecipient, formCode?: FormCodeUnion, formId?: string };
export type TokenType = 'contract' | 'document';
export type ReplacementToken = { type?: TokenType, label?: string, length?: number, value: (data: TokenReplacementData)=>string };
export type ReplacementTokens = Record<ReplacementTokenType, ReplacementToken>;
export type ReplacementValues = Record<ReplacementTokenType, string>;

export type ConditionToken = { type?: TokenType, label: string, isTrue: (data: TokenReplacementData)=>boolean };
export type ConditionTokens = Record<string, ConditionToken>;

export function processTemplate(doc: Document, templateData?: TokenReplacementData, replacementTokens?: ReplacementTokens | ReplacementValues, conditionTokens?: ConditionTokens) {
  if (!replacementTokens || !templateData || !doc) return '';

  doc.querySelectorAll('div[data-lexical-condition]').forEach(node => {
    const condition = node.getAttribute('data-lexical-condition');
    const inverse = node.getAttribute('data-lexical-condition-inverse') === 'true';
    const conditionValue = !!conditionTokens?.[condition as ConditionTokenType]?.isTrue(templateData);

    //if condition not true, remove the node
    if (inverse === conditionValue) {
      node.remove();
    } else {
      //strip all the attributes we dont want in the output HTML
      node.removeAttribute('data-lexical-condition-inverse');
      node.removeAttribute('data-lexical-condition');
      node.removeAttribute('open');
      node?.children?.[0]?.removeAttribute('data-lexical-condition-content');
    }
  });

  return processTemplateSimple(doc.documentElement.querySelector('body')?.innerHTML, templateData, replacementTokens);
}

//For when theres no DOMParser available - and we dont need to use conditional blocks
export function processTemplateSimple(text?: string, templateData?: TokenReplacementData, replacementTokens?: ReplacementTokens | ReplacementValues) {
  if (!replacementTokens || !templateData || !text) return text;

  return text.replace(/(@@\w+)/gi, (substr) => {
    const matchedToken = substr as ReplacementTokenType;
    return isValidReplacementToken(substr, replacementTokens)
      ? (typeof replacementTokens[matchedToken] !== 'string' ? replacementTokens[matchedToken]?.value?.(templateData) : replacementTokens[matchedToken]) || ''
      : substr;
  });
}

export function isValidReplacementToken(text: string, tokens?: ReplacementTokens | ReplacementValues): text is ReplacementTokenType {
  return Boolean(text.match(/^@@\w+$/) && tokens?.[text as ReplacementTokenType]);
}
