import { EmailLinkType, WorkflowAction, WorkflowActionType, WorkflowConditionType, MappingFunction, Operator, WorkflowConditionDefinition, WorkflowConditionTriggerType, WorkflowConditionDefinitionLeafType } from '@property-folders/contract/yjs-schema/entity-settings';
import { ExtraFormCode, FormCode, planOptionsFull, saleMethodOptions } from '@property-folders/contract';
import { FormTypes, PropertyFormYjsDal } from '../yjs-schema/property/form';
import { mapValues, pickBy } from 'lodash';
import { canonicalisers, formatTimestamp } from '../util/formatting';
import { calculateDepositDueDate } from '../util/form';
import { getDocumentName } from '../yjs-schema/property';
import { ConditionTokens, ReplacementTokens } from '../util/process-template';

const AllowedOperators = {
  SingleField: [Operator.In, Operator.NotIn],
  List: [Operator.Includes, Operator.NotIncludes]
};

export enum StandardCondition {
  saleMethod= 'saleMethod',
  planType= 'planType',
  documentName = 'documentName',
  salesperson = 'salesperson',
}

const { documentName, ...nonDocConditions } = StandardCondition;
const docs = mapValues(pickBy(FormTypes, f => !f.renderOpts?.processNotForm && !f.renderOpts?.notYetImplemented), f => f.shortLabel || f.label);

export const StandardConditions: Record<StandardCondition, WorkflowConditionDefinitionLeafType> = {
  [StandardCondition.saleMethod]: { name: StandardCondition.saleMethod, type: WorkflowConditionType.Field, field: 'sale.saleMethod', label: 'Sale Method', options: saleMethodOptions, operators: AllowedOperators.SingleField, operator: Operator.In },
  [StandardCondition.planType]: { name: StandardCondition.planType, type: WorkflowConditionType.MappedField, mappingFn: MappingFunction.planTypes, label: 'Plan Type', options: planOptionsFull, operators: AllowedOperators.List, operator: Operator.Includes },
  [StandardCondition.documentName]: { name: StandardCondition.documentName, type: WorkflowConditionType.Document, label: 'Document Type', options: docs, operators: AllowedOperators.SingleField, operator: Operator.In },
  [StandardCondition.salesperson]: { name: StandardCondition.salesperson, type: WorkflowConditionType.MappedField, mappingFn: MappingFunction.salespersons, label: 'Salespeople', options: 'salespeople', operators: AllowedOperators.List, operator: Operator.Includes }
};

const tokensGeneral: ReplacementTokens = {
  '@@PropertyAddress': { label: 'Property Address', length: 45, value: d => d.property?.data?.saleAddrs?.map(a => `${a.streetAddr}, ${a.subStateAndPost}`)?.join(', ') || '' },
  '@@RecipientFirstName': { label: 'Recipient First Name', length: 8, value: d => d.recipient.name || d.recipient.fullName || '' },
  '@@RecipientFullLegalName': { label: 'Recipient Full Name', length: 15, value: d => d.recipient.fullName || '' },
  '@@SalespersonName': { label: 'Salesperson Name', length: 15, value: d => d.property.data.agent?.[0]?.salesp?.[0]?.name || '' },
  '@@SalespersonEmail': { label: 'Salesperson Email', length: 30, value: d => d.property.data.agent?.[0]?.salesp?.[0]?.email || '' },
  '@@SalespersonPhone': { label: 'Salesperson Phone', length: 12, value: d => d.property.data.agent?.[0]?.salesp?.[0]?.phone || '' },
  '@@AgencyTradingName': { label: 'Agency Trading Name', length: 15, value: d => d.property.data.agent?.[0]?.company || '' },
  '@@AgencyTrustAccountName': { label: 'Agency Trust Account Name', length: 20, value: d => d.entity?.TrustAccountName || '' },
  '@@AgencyTrustAccountBSB': { label: 'Agency Trust Account BSB', length: 7, value: d => d.entity?.TrustAccountBsb || '' },
  '@@AgencyTrustAccountNumber': { label: 'Agency Trust Account Number', length: 10, value: d => d.entity?.TrustAccountNumber || '' }
};

const tokensContract: ReplacementTokens = {
  '@@CoolingOffBeginsDate': { type: 'contract', label: 'Cooling Off Begins Date', length: 25, value: d => formatTimestamp(d.sublineage?.data?.coolingOff?.startDate) },
  '@@CoolingOffEndsDate': { type: 'contract', label: 'Cooling Off Ends Date', length: 25, value: d => formatTimestamp(d.sublineage?.data?.coolingOff?.endDate) },
  '@@DepositAmount': { type: 'contract', label: 'Deposit Amount', length: 8, value: d => canonicalisers.aud(d.sublineage?.data?.contractPrice?.deposit).display },
  '@@DepositDueDate': { type: 'contract', label: 'Deposit Due Date', length: 11, value: d => d.sublineage ? formatTimestamp(calculateDepositDueDate(d.sublineage?.data), undefined, false) : '' },
  '@@SubjectToFinanceDueDate': { type: 'contract', label: 'Subject To Finance Due Date', length: 11, value: d => d.sublineage ? formatTimestamp(d.sublineage?.data?.contractSpecial?.financeDeadline, undefined, false) : '' },
  '@@SubjectToFinanceAmount': { type: 'contract', label: 'Subject To Finance Amount', length: 8, value: d => d.sublineage ? canonicalisers.aud(d.sublineage?.data?.contractSpecial?.financeAmount).display : '' },
  '@@SubjectToSaleContractDueDate': { type: 'contract', label: 'Subject To Sale Contract Due Date', length: 11, value: d => d.sublineage ? formatTimestamp(d.sublineage?.data?.contractSpecial?.purchaserSaleContractDeadline, undefined, false) : '' },
  '@@SubjectToSaleUnconditionalDueDate': { type: 'contract', label: 'Subject To Sale Unconditional Due Date', length: 11, value: d => d.sublineage ? formatTimestamp(d.sublineage?.data?.contractSpecial?.saleSettleUnconditionalDeadline, undefined, false) : '' },
  '@@SubjectToSaleSettlementDueDate': { type: 'contract', label: 'Subject To Sale Settlement Due Date', length: 11, value: d => d.sublineage ? formatTimestamp(d.sublineage?.data?.contractSpecial?.purchaserSaleSettlementDeadline, undefined, false) : '' },
  '@@SettlementDate': { type: 'contract', label: 'Settlement Date', length: 11, value: d => d.sublineage ? formatTimestamp(d.sublineage?.data?.contractSettlement?.date, undefined, false) : '' }
};

const tokensDocument: ReplacementTokens = {
  '@@DocumentName': { type: 'document', label: 'Document Name', length: 20, value: d => d.formCode && d.formId ? getDocumentName(d.formCode, PropertyFormYjsDal.getFormInstanceFromState(d.formCode, d.formId, d.sublineage?.meta || d.property.meta)) : '' }
};

export const tokensCombined = {
  ...tokensGeneral,
  ...tokensContract,
  ...tokensDocument
};

export const conditionTokens: ConditionTokens = {
  'ifSubjectToFinance': { type: 'contract', label: 'Subject to Finance', isTrue: d => !!d.sublineage?.data?.contractSpecial?.financeRequired },
  'ifSubjectToSale': { type: 'contract', label: 'Subject to Sale', isTrue: d => !!d.sublineage?.data?.contractSpecial?.purchaserSaleRequired }
};

export type WorkflowTriggerTemplateType = {
  type: 'contract' | 'document',
  label: string,
  description: string,
  defaultCondition: WorkflowConditionDefinition,
  actions: WorkflowAction[],
  replacementTokens: ReplacementTokens,
  conditionTokens?: ConditionTokens,
  allowedConditions?: StandardCondition[]
};

export const FormTypeMap = {
  [FormCode.RSC_ContractOfSale]: 'contract',
  [ExtraFormCode.SCV_ContractOfSaleVariation]: 'contract',
  [ExtraFormCode.SCT_ContractOfSaleTermination]: 'contract'
};

export const WorkflowTriggerTemplates: Record<WorkflowConditionTriggerType, WorkflowTriggerTemplateType> = {
  [WorkflowConditionTriggerType.CoolingOffBegun]: {
    type: 'contract',
    label: 'Cooling-off begins',
    description: 'Raised at the later of full contract execution, and service of the Form 1.',
    allowedConditions: Object.values(nonDocConditions),
    defaultCondition: {
      type: WorkflowConditionType.And,
      conditions: []
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Contract', formFamily: FormCode.RSC_ContractOfSale },
        button2: { type: EmailLinkType.Document, text: 'Download Form 1', formFamily: FormCode.Form1 }
      },
      to: [{ type: 'role', id: 'purchaser' }]
    }],
    replacementTokens: { ...tokensGeneral, ...tokensContract },
    conditionTokens: conditionTokens
  },
  [WorkflowConditionTriggerType.CoolingOffWaived]: {
    type: 'contract',
    label: 'Cooling-off waived',
    description: 'Raised when the Purchasers have waived their right to cooling off and a Contract is distributed to the Purchasers.',
    allowedConditions: Object.values(nonDocConditions),
    defaultCondition: {
      type: WorkflowConditionType.And,
      conditions: []
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Contract' }
      },
      to: [{ type: 'role', id: 'purchaser' }]
    }],
    replacementTokens: { ...tokensGeneral, ...tokensContract },
    conditionTokens: conditionTokens
  },
  [WorkflowConditionTriggerType.CoolingOffNotApplicable]: {
    type: 'contract',
    label: 'Cooling-off not applicable',
    description: 'Raised when a Contract under Auction conditions has been distributed to the Purchasers.',
    allowedConditions: Object.values(nonDocConditions),
    defaultCondition: {
      type: WorkflowConditionType.And,
      conditions: []
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Contract' }
      },
      to: [{ type: 'role', id: 'purchaser' }]
    }],
    replacementTokens: { ...tokensGeneral, ...tokensContract },
    conditionTokens: conditionTokens
  },
  [WorkflowConditionTriggerType.DocumentDistributed]: {
    type: 'document',
    label: 'Document is distributed',
    description: 'Raised once all parties have signed and received a copy of the fully signed document.',
    defaultCondition: {
      type: WorkflowConditionType.And,
      conditions: [
        { ...StandardConditions[StandardCondition.documentName], value: [FormCode.RSAA_SalesAgencyAgreement] }
      ]
    },
    actions: [{
      type: WorkflowActionType.Email,
      cta: {
        enabled: true,
        button1: { type: EmailLinkType.Document, text: 'Download Document' }
      },
      to: [{ type: 'role', id: 'vendor' }]
    }],
    replacementTokens: tokensCombined,
    conditionTokens: conditionTokens
  }
};
