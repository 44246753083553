import { jointTypes, multiRepAuthority } from '../../property';
import { SigningAuthorityType } from '../../property';
import { ValidationDefnType } from '../validation-field';

const authorityPartyChildRules: ValidationDefnType = {
  authority: {
    _required: true
  },
  partyType: {
    _required: true
  },
  fullLegalName: {
    _required: true
  },
  legalRepresentatives: {
    _children: {
      name: { _requiredIf: {
        expectations: [
          { oneOfExpectedValue: multiRepAuthority, field: ['..', '..', '..', 'authority'] }
        ],
        modeOrTrueAndFalse: false
      } }
    }
  },
  personName1: {
    _requiredIf: { expectations: [
      { oneOfExpectedValue: [ // Basically anything other than self
        SigningAuthorityType.authRep,
        SigningAuthorityType.attorney,
        SigningAuthorityType.sole, // Sole means sole director/secretary and is thus a company type
        SigningAuthorityType.directorSecretary,
        SigningAuthorityType.directors2,
        SigningAuthorityType.guardian
      ], field: ['..', 'authority'] },
      { notOneOfExpectedValue: jointTypes, field: ['..', 'partyType'] }
    ], modeOrTrueAndFalse: false }
  },
  personName2: {
    _requiredIf: { expectations: [
      { oneOfExpectedValue: [
        SigningAuthorityType.directorSecretary,
        SigningAuthorityType.directors2
      ], field: ['..', 'authority'] },
      { notOneOfExpectedValue: jointTypes, field: ['..', 'partyType'] }
    ], modeOrTrueAndFalse: false }
  }
};

export const vendorChildRules: ValidationDefnType = {
  ...structuredClone(authorityPartyChildRules),
  authority: {
    _required: false,
    _requiredIf: { expectations: [
      { evaluationFunction: 'isNotJointAuthorityPartyParent', field: ['..'] }
    ], modeOrTrueAndFalse: true }
  },
  fullLegalName: {
    _required: false,
    _requiredIf: { expectations: [
      { evaluationFunction: 'isNotJointAuthorityPartyParent', field: ['..'] }
    ], modeOrTrueAndFalse: true }
  },
  addressSingleLine: {
    _requiredIf: { modeOrTrueAndFalse: false, expectations: [
      { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryVendor'] },
      { notExpectedValue: true, field: ['..', 'addrSameAsSale'] }
    ] }
  },
  namedExecutors: {
    _children: {
      ...structuredClone(authorityPartyChildRules),
      fullLegalName: {
        _required: true
      }
    }
  }
};

export const purchaserChildRules: ValidationDefnType = {
  ...structuredClone(authorityPartyChildRules),
  addressSingleLine: {
    _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] }
  }
};

export const formStatesClauseMatchRules: ValidationDefnType = {
  clausesByFamily: {
    _children: {
      clauses: {
        _children: {
          id: { _requiredIf: { clauseIdMatches: true, field: ['..','..', '..','id'] } },
          title: { _requiredIf: { clauseIdMatches: true, field: ['..','..', '..','id'] } },
          text: { _requiredIf: { clauseIdMatches: true, field: ['..','..', '..','id'] } }
        }
      }
    }
  }
};
