import { AdminFeeType, Annexure, AuctionFeeSwitches, DisclosureOfBenefitsType, Form1AndSearches, FormCode, ItemiserItem, MarketingFeesOptions, MarketingTemplateItemOld, MarketingTemplateOld, MaterialisedPropertyData, PressBudgetType, VendorPaidAdvertisingType, TransactionFeeType } from '@property-folders/contract';
import { EntityBrandFormConfig, EntitySettingsEntity, MarketingTemplate, MarketingTemplateItem } from '@property-folders/contract/yjs-schema/entity-settings';
import { adminFeeDefaultMessage, auctionFeeDefaultMessage, expenditurePeriods, pressBeginOpts, pressEndOpts } from '../constants';
import { Predicate } from '../../../predicate';
import { arbitraryFieldsRowTable, confineWidthWithColumn, fieldBoolCheckboxes, fieldFocus, fieldLabel, fieldsSpacing, freeTextArea, generateCheckboxInlineTextLine, generateCheckboxRows, generateCheckboxRowsV2, generateCheckboxTextColumns, itemiser, itemiserGeneric, itemSection, itemSubsection, knownPair, singleFieldTable, spaceStackLinesSideEffect } from '..';
import { mapOptsForCheckbox } from '../display-transformations';
import { subsectionTitles } from '../doc-constants/sales-agency-agreement';
import { formatBI } from '../formatBI';
import { FieldPlaceholderStyle } from '../standards';
import { marketingTemplateSection, rayWhiteMarketingTemplateSection } from './marketingTemplateSection';
import { FormTypes } from '../../../yjs-schema/property/form';
import { canonicalisers } from '../../formatting';
import { ReaformsContent } from '../formatters/clauses';
import { getTotalForVPA } from '../../vpa-total-cost';

// Not sure why needs to return array rather than use a variable, but this works
const notApplicableText = () => [{ text: 'Not applicable', margin: [0, fieldsSpacing, 0, 0] }];

export function marketingCostSection(data: {
  marketingFees?: ItemiserItem[],
  marketingFeesOptions?: MarketingFeesOptions,
  formCode?: FormCode,
  annexures?: Annexure[]
}, isVariation?: boolean) {
  const {
    marketingFeesOptions,
    marketingFees,
    formCode = FormCode.RSAA_SalesAgencyAgreement,
    annexures = []
  } = data;
  const formFamily = FormTypes[formCode].formFamily;

  const sectionItems = [];
  const annexureRef = marketingFeesOptions?.externalFeeScheduleAnnexureRef?.['family_'+formFamily];
  const { noFees } = marketingFeesOptions ?? {};
  const allConsideredValuesForValidation = [
    ...(((marketingFees??[]) as {itemCost: number|string|undefined}[])
      .map(r=>canonicalisers.audWithNegative(r.itemCost).canonical))
  ];
  const validationIssueIndex = allConsideredValuesForValidation.findIndex(v=>typeof v !== 'number' || isNaN(v));
  const considerTableComplete = validationIssueIndex === -1;
  if (noFees) {
    sectionItems.push(generateCheckboxRows(['No marketing costs to be charged by the agent'], ['No marketing costs to be charged by the agent']));
  } else if (annexureRef) {
    const foundAnnexure = annexures?.find(annex=>annex.id === annexureRef);
    if (foundAnnexure) {
      sectionItems.push({ text: formatBI(`Refer *-*Annexure ${foundAnnexure.label}*-* - ${foundAnnexure.name}`), style: 'sectionText' });
    }
  }

  if (sectionItems.length === 0) {
    if (!considerTableComplete) {
      sectionItems.push(generateCheckboxRows([
        'No marketing costs to be charged by the agent',
        'Refer Annexure for Marketing costs'
      ], [], 1));
    }
    sectionItems.push(itemiser(marketingFees, 'APPROVED AMOUNT (inclusive of GST)', considerTableComplete ? 0 : undefined));
  }

  return itemSubsection({
    subsectionTitle: subsectionTitles.marketingCosts,
    subsectionContent: [{ stack: spaceStackLinesSideEffect(sectionItems, 1 ), margin: [0,fieldsSpacing,0,0] }],
    bookmark: ['subsection-marketing-fees', ...fieldFocus('marketingFees')],
    isVariation
  });
}

export function benefitsSection(data: {benefits?: DisclosureOfBenefitsType}, opts: {isVariation?: boolean}) {
  const { benefits } = data ?? {};
  const { isVariation } = opts ?? {};
  const sectionItems = [];
  sectionItems.push({ text: 'In addition to the Benefits set out in General Condition 8, the Agent discloses to the Vendor the following Benefits:', margin: [0, fieldsSpacing, 0, 0] });
  sectionItems.push(itemiserGeneric(benefits?.benefits, [
    { name: 'itemDesc', width: '*', header: 'Nature of the Benefit' },
    { name: 'itemSource', width: '21%', header: 'Source of the Benefit' },
    { name: 'itemCost', width: '21%', header: 'Amount (or estimated amount or value) of the Benefit', alignment: 'right', display: 'aud' },
    { name: 'itemRecipient', width: '21%', header: 'Name of recipient of the Benefit and capacity* of recipient' }
  ]));
  sectionItems.push({ text: '* Refers to the capacity in which the person receives the Benefit eg as an agent, a financier, mortgage broker, lawyer', italics: true, margin: [0, 10, 0, 0] });
  return itemSubsection({
    subsectionTitle: subsectionTitles.disclosureOfBenefits,
    subsectionContent: Predicate.boolFalse(benefits?.enable)
      ? notApplicableText()
      : spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-disclosure-of-benefits', ...fieldFocus('benefits')],
    isVariation,
    unbreakable: false
  });
}

export function shouldDisplayVpaSection (data: {vpa: VendorPaidAdvertisingType | undefined, entitySettings: EntitySettingsEntity}) {
  const { vpa, entitySettings } = data;
  const { vpaPayEnable } = vpa??{};
  return (vpaPayEnable !== false && entitySettings?.campaignAgentVpa) || vpaPayEnable;
}

export function vpaCampaignAgentSection(data: {vpa: VendorPaidAdvertisingType | undefined, tradeName: string, entitySettings: EntitySettingsEntity, property: MaterialisedPropertyData | undefined}, opts?: {isVariation?: boolean}) {
  const { vpa, tradeName, property } = data;
  if (!shouldDisplayVpaSection(data)) return [];

  const calculatedPayNow = getTotalForVPA({ property, included: property?.marketingFeesOptions?.vendorPaidAdvertising?.includedAmounts });

  const payNowCanon = canonicalisers.audWithNegative(vpa?.nowAmount ? vpa.nowAmount : calculatedPayNow.total);
  const payLaterCanon = canonicalisers.audWithNegative(vpa?.laterAmount ? vpa?.laterAmount : payNowCanon.valid ? payNowCanon.canonical * 1.069 : NaN);
  const sectionItems: ReaformsContent[] = [];
  const genPayNowLaterContent = (label: string, amount: string | number | undefined) => {
    const amountCanon = canonicalisers.aud(amount);

    const amountStr = vpa?.vpaPayEnable && amountCanon.valid ? `${amountCanon.display.replace(/[ ]*\$[ ]*/, '')}` : ' ';
    return { table: {
      widths: ['*'],
      body: [
        [{ text: [{ text: '$', style: 'boldEmphasis' }, '  ', amountStr], fontSize: 12 }],
        [{ text: label, style: 'boldEmphasis' }]
      ]
    }, layout: {
      paddingRight: ()=>0,
      paddingLeft: ()=>0,
      paddingTop: (i)=>i===1?3:0,
      paddingBottom: (i)=>i===0?6:0,
      hLineWidth: (i)=>i===1?0.5:0,
      vLineWidth: ()=>0
    } };
  };
  sectionItems.push({
    ...generateCheckboxTextColumns(`I acknowledge that ${tradeName} engage VPA Pay by Campaign Agent to manage all vendor paid advertising. VPA Pay by Campaign Agent Terms and Conditions apply.`, vpa?.vpaPayEnable),
    margin: [0,0,60,0]
  });
  vpa?.vpaPayEnable !== false &&  sectionItems.push({
    margin: [24, 0, 0, 0],
    stack: [{
      margin: [0, 12, 0 , 12],
      columns: [
        {
          ...genPayNowLaterContent('Pay Now', payNowCanon.display),
          width: '*'
        },
        {
          text: ' OR ',
          style: 'boldEmphasis',
          width: 'auto',
          margin: [24, 15, 24, 0]
        },

        {
          ...genPayNowLaterContent('Pay Later', payLaterCanon.display),
          width: '*'
        }
      ]
    },
    {
      ul: [
        'The Pay Now date is the estimated date of photography.',
        'The Pay Later date is 6 months from the date of photography.',
        'Pay Later campaigns incur a 6.9% surcharge which is included in the Pay Later total.',
        'If you select Pay Now and do not pay on the due date, you will be required to sign a Pay Later agreement.',
        'VPA Pay by Campaign Agent will send you their Terms & Conditions and seperate agreement for signing prior to launching your property to the market.'
      ],
      italics: true
    }] });

  return itemSubsection({
    subsectionTitle: 'Instructions to Campaign Agent for marketing payment',
    //subsectionTitleFontSize: 11,
    isVariation: opts?.isVariation,
    unbreakable: true,
    subsectionContent: [{
      margin: [0, -12,0,0],
      stack: spaceStackLinesSideEffect(sectionItems)
    }],
    titleColumnsAfter: [
      {
        width: '*',
        text: ' '
      },
      {
        width: 'auto',
        stack: [{
          image: 'vpaPayLogo',
          width: 80,
          margin: [0,3,0,0]
        }]
      },
      {
        width: 'auto',
        stack: [{
          image: 'vpaQr',
          width: 50,
          margin: [6,-3,0,0]
        }]
      }
    ]
  });
}

export function pressSection(pressBudget?: PressBudgetType, { isVariation, shortMode }: {isVariation?: boolean, shortMode?: boolean} = {}) {
  if (shortMode) return [];
  const { strike, enable, start, startOther, expenditure, expPeriod, endAgency, otherEnd, additional } = pressBudget || {};
  const sectionItems = [];
  if (Predicate.truthyOrUndefined(enable) || strike) {
    sectionItems.push(singleFieldTable({
      fieldName: 'In addition to the Vendor’s Marketing Costs set out above?',
      fieldValue: fieldBoolCheckboxes(additional),
      fieldColons: false,
      isVariation
    }));
    sectionItems.push(
      fieldLabel({ fieldName: 'To begin from', isVariation }),
      generateCheckboxRows(mapOptsForCheckbox(pressBeginOpts, startOther), start ? [start] : [], 1)
    );

    sectionItems.push({
      columns: [
        {
          ...arbitraryFieldsRowTable({
            fields: [['Approved expenditure', expenditure]],
            fieldColons: [true, false],
            labelStyleOverride: [undefined, 'fieldFontGeneral'],
            contentStyleOverride: 'fieldFontGeneral',
            isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Price
          }), width: 'auto'
        },
        { text: ' per', width: 18, fontSize: 12 },
        { ...generateCheckboxInlineTextLine(expenditurePeriods, expPeriod, undefined, 10, { baselineOffset: -1.2 }, { baselineOffset: -3.2 }) }
      ],
      margin: [0, 4, 0, 0]
    });

    sectionItems.push({
      columns: [
        {
          ...knownPair({
            fieldName: 'To end on',
            fieldValue: fieldBoolCheckboxes(endAgency, pressEndOpts.true, `${pressEndOpts.false}:`),
            fieldColons: true,
            isVariation
          })?.[0], width: 'auto'
        },
        {  margin: [0, 5.5, 0, 0], text: Predicate.boolFalse(endAgency) ? otherEnd : '​         ', decoration: 'underline', decorationCombine: true }
      ]
    });
  }

  return itemSubsection({
    sectionRemove: Predicate.boolFalse(enable) && Predicate.boolFalse(strike),
    subsectionTitle: subsectionTitles.periodicPress,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? notApplicableText() : spaceStackLinesSideEffect(sectionItems, 1),
    bookmark: ['subsection-periodic-press', ...fieldFocus('pressBudget')],
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function adminFeeSection(adminFee?: AdminFeeType, isVariation?: boolean) {
  const { enable, strike, setPrice, templatePrice } = adminFee || {};
  const sectionItems = [];
  if (templatePrice === adminFeeDefaultMessage || !templatePrice) {
    sectionItems.push({ text: adminFeeDefaultMessage, margin: [0,fieldsSpacing,0,0] });
  } else {
    templatePrice && sectionItems.push({ stack: [], margin: [0,fieldsSpacing/2, 0,0] }, freeTextArea({
      title: undefined,
      content: templatePrice || '',
      linesIfEmpty: 3
    }));
  }

  sectionItems.push(confineWidthWithColumn(
    arbitraryFieldsRowTable({
      fields: [[[], setPrice], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
      fieldColons: [false, false],
      fieldPlaceholder: FieldPlaceholderStyle.Price
    }), 195));

  return itemSubsection({
    sectionRemove: Predicate.boolFalse(enable) && Predicate.boolFalse(strike),
    subsectionTitle: subsectionTitles.adminFee,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? notApplicableText() : spaceStackLinesSideEffect(sectionItems),
    bookmark: 'subsection-admin-fee',
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function auctionFeeSection(
  data: {
    saleMethod: string | undefined,
    auctionFee: string | undefined,
    auctionFeeSwitches: AuctionFeeSwitches | undefined
  },
  opts?: {isVariation?: boolean}) {
  const { isVariation } = opts ?? {};
  const { auctionFee, saleMethod, auctionFeeSwitches } = data ?? {};
  const { applicable, strike, feeDesc } = auctionFeeSwitches ?? {};

  const sectionItems = [];
  const definitelyNotAuction = (saleMethod && saleMethod !== 'auction') || (applicable === false && !strike);
  if (definitelyNotAuction) return [];
  const emptyFields = !applicable;
  if (!definitelyNotAuction) {
    sectionItems.push({
      text: (applicable && feeDesc) || auctionFeeDefaultMessage,
      margin: [0, fieldsSpacing, 0, 0]
    });
    sectionItems.push(confineWidthWithColumn(
      arbitraryFieldsRowTable({
        fields: [[[], emptyFields ? '' : auctionFee], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
        fieldColons: [false, false],
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Price
      }), 195));
  }

  return itemSubsection({
    subsectionTitle: subsectionTitles.auctionFee,
    subsectionContent: definitelyNotAuction ? notApplicableText() : spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-auction-fee', ...fieldFocus('auctionFee', 'auctionFeeSwitches')],
    otherStackAttributes: Predicate.boolFalse(applicable) && Predicate.truthyOrEmpty(strike) ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(applicable) && Predicate.truthyOrEmpty(strike) ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function transactionFee({ feeAmount, isVariation, transactionFeeData, form1AndSearches }: { feeAmount?: string; isVariation?: boolean, transactionFeeData?: TransactionFeeType, form1AndSearches?: Form1AndSearches }) {
  const { hideWaived, waiveFee } = transactionFeeData ?? {};
  const { whoSearches } = form1AndSearches??{};
  const useEpfToOrderSearches = whoSearches === 'eckermanns';
  const allowHide = waiveFee || useEpfToOrderSearches;

  if (allowHide && hideWaived) {
    return [];
  }

  const subsectionContent = spaceStackLinesSideEffect([
    {
      text: 'The Greatforms Transaction Fee as specified below is payable to enable the Agent to utilise Greatforms’ premium legal documentation, compliance and signing platform on behalf of the Vendor.' ,
      margin: [0, fieldsSpacing, 0, 0]
    },
    confineWidthWithColumn(
      feeAmount != undefined
        ? arbitraryFieldsRowTable({
          fields: [[[], feeAmount], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
          fieldColons: [false, false],
          isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        })
        : arbitraryFieldsRowTable({
          fields: [[[], 'Nil'], [[], []]],
          fieldColons: [false, false],
          isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        }),
      200
    )
  ],);

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.reaformsTransactionFee,
      subsectionContent,
      unbreakable: undefined,
      bookmark: ['subsection-transaction-fee', 'field_focus_transactionFee'],
      isVariation: false
    });
}

// This subsection should never be varied
export function form1HandlingSubsection(data: {searches: MaterialisedPropertyData['form1AndSearches']}) {
  const { whoPays, whoSearches } = data?.searches ?? { whoPays: 'vendor', whoSearches: undefined };
  const subsectionContent = [
    {
      text: 'Under the Act, the Vendor is required to serve a Form 1 on the Purchaser, and the Agent (or a person authorised to act on behalf of the Agent) must ensure the prescribed enquiries (searches) are made in relation to the Property and certify the Form 1.',
      margin: [0, fieldsSpacing, 0, 0]
    }
  ];

  if (whoPays === 'agent') {
    const checks = generateCheckboxRowsV2([
      { isSelected: whoSearches === 'eckermanns', label: 'Eckermann Property Forms to order searches and contact Vendor regarding Form 1 preparation, at Agent’s cost; OR' },
      { isSelected: whoSearches === 'agent', label: 'Agent to order searches and prepare Form 1, at Agent’s cost.' }
    ], { isNumbered: true, columns: 1 });
    subsectionContent.push(checks);
  } else {
    const checks = generateCheckboxRowsV2([
      { isSelected: whoSearches === 'eckermanns', label: 'Eckermann Property Forms to order searches and contact Vendor regarding Form 1 preparation, at Vendor’s cost;' },
      { isSelected: whoSearches === 'vendor', label: 'Vendor to arrange their own searches and Form 1 preparation, at Vendor’s cost; OR' },
      { isSelected: whoSearches === 'agent', label: 'Agent to arrange searches and Form 1 preparation, at Vendor’s cost. ' }
    ], { isNumbered: true, columns: 1 });
    subsectionContent.push(checks);
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.form1AndSearches,
      subsectionContent: spaceStackLinesSideEffect(subsectionContent),
      unbreakable: undefined,
      bookmark: ['subsection-form1AndSearches-fee', 'field_focus_form1AndSearches.whoSearches'],
      isVariation: false
    });
}

export function feeSection(
  itemNo: number,
  marketingTemplate?: MarketingTemplate | MarketingTemplateOld,
  marketingFees?: MarketingTemplateItem[] | MarketingTemplateItemOld[] | ItemiserItem[],
  pressBudget?: PressBudgetType,
  adminFee?: AdminFeeType,
  saleMethod?: string,
  auctionFee?: string,
  benefits?: DisclosureOfBenefitsType,
  brand?: EntityBrandFormConfig,
  reaformsTransactionFeeAmount?: string,
  otherData?: {
    form1AndSearches?: MaterialisedPropertyData['form1AndSearches']
    marketingFeesOptions?: MarketingFeesOptions,
    annexures: Annexure[],
    formCode: FormCode,
    auctionFeeSwitches: AuctionFeeSwitches | undefined,
    tradeName: string;
    entitySettings: EntitySettingsEntity;
    transactionFeeData: TransactionFeeType | undefined
    property: MaterialisedPropertyData | undefined
  },
  opts?: {isVariation?: boolean, shortMode?: boolean}
) {
  const { marketingFeesOptions, formCode, annexures, auctionFeeSwitches, transactionFeeData, tradeName = 'the Agency', entitySettings, property } = otherData ?? {};
  const { isVariation, shortMode } = opts ?? {};
  const sectionItems = [];
  //Use new marketing template if it exists, otherwise old marketing template if it exists, otherwise standard costs section
  const marketingSection = typeof marketingTemplate?.id === 'string'
    ? marketingTemplateSection(marketingTemplate as MarketingTemplate, isVariation)
    : 'enable' in (marketingTemplate||{}) && (marketingTemplate as MarketingTemplateOld).enable
      ? rayWhiteMarketingTemplateSection(marketingTemplate as MarketingTemplateOld, marketingFees, brand, isVariation)
      : marketingCostSection({ marketingFees: marketingFees, marketingFeesOptions, formCode, annexures }, isVariation);

  sectionItems.push({ text: 'In accordance with General Conditions 6, 7 and 11, the following Marketing and Other Costs are authorised and payable by the Vendor, regardless of whether the Property is sold by the Agent or not.', margin: [0, 0, 0, fieldsSpacing] });
  sectionItems.push(marketingSection);

  if (!shortMode) sectionItems.push(pressSection(pressBudget, { isVariation }));
  sectionItems.push(adminFeeSection(adminFee, isVariation));
  sectionItems.push(auctionFeeSection({ saleMethod, auctionFee, auctionFeeSwitches }, { isVariation }));
  sectionItems.push(transactionFee({ feeAmount: reaformsTransactionFeeAmount, transactionFeeData }));
  sectionItems.push(form1HandlingSubsection({ searches: otherData?.form1AndSearches }));
  sectionItems.push(vpaCampaignAgentSection({ vpa: marketingFeesOptions?.vendorPaidAdvertising, tradeName, entitySettings, property }));
  sectionItems.push(benefitsSection({ benefits }, { isVariation }));

  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'Marketing and Other Costs'.toUpperCase(),
    bookmark: 'bookmark_fees',
    stackContent: sectionItems,
    isVariation,
    pageBreakBefore: !!marketingTemplate?.id || false,
    topMarginOverride: 0
  });
}
