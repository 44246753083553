import { useInfiniteQuery } from '@tanstack/react-query';
import { cloneSimple } from '../util/cloneSimple';
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import { DocumentType } from '@property-folders/contract/rest/document';

export type TemplateListItem = {
  id: number,
  name: string,
  status: 'Draft',
  ownerId: number,
  ownerName: string,
  entityId: number,
  createdStamp: Date,
  updateStamp: Date,
  formName: string,
  isArchived: boolean,
  formCode: string,
} & (
  { subscriptionDocumentId?: number, subscriptionFormId?: number, type: DocumentType.Subscription }
  | { propertyId?: string, propertyFormId?: string, type: DocumentType.Property }
  );

export class TemplatesApi {
  static async getTemplateList({
    pageNumber,
    searchTerm,
    showArchived
  }: {
    pageNumber: number,
    searchTerm?: string,
    showArchived?: boolean
  }) {
    const params = new URLSearchParams({ pageNumber, showArchived });
    if (searchTerm) {
      params.set('searchTerm', searchTerm);
    }

    return await WrappedFetch.jsonWithDate<{ results: TemplateListItem[], pageNumber: number }>(
      LinkBuilder.restApi(`/templates?${params.toString()}`)
    );
  }

  static useTemplateList(
    defaultParams: {
      pageNumber: number,
      searchTerm: string,
      showArchived: boolean,
    } = { pageNumber: 1, searchTerm: '', showArchived: false },
  ) {
    return useInfiniteQuery(
      [
        'templates',
        'list',
        defaultParams.searchTerm ?? '',
        defaultParams.showArchived ?? ''
      ],
      async context => {
        return await TemplatesApi.getTemplateList(context.pageParam ?? {
          pageNumber: 1,
          searchTerm: '',
          ...defaultParams
        });
      },
      {
        getNextPageParam: (lastPage, _) => {
          if (!lastPage?.results || lastPage.results.length === 0) {
            return undefined;
          }

          const p = cloneSimple(defaultParams);
          p.pageNumber = (lastPage.pageNumber ?? 1) + 1;
          return p;
        }
      }
    );
  }
}
