import {
  EmailEvent,
  ExtraFormCode,
  FileRef,
  FormCode,
  FormCodeUnion,
  FormFamilyState,
  FormInstance,
  FormInstanceOrder,
  FormInstanceSigning,
  FormSigningState,
  LastServedContactSnapshot,
  PropertyRootKey,
  MaterialisedPropertyData,
  META_APPEND,
  ServeStateRecipient,
  SignedStates,
  SigningParty,
  SigningPartyDeclineType,
  SigningPartySource,
  SigningPartySourceType,
  SigningPartyType,
  SigningSession,
  SigningStates,
  TransactionMetaData,
  SigningSessionField,
  SigningSessionFieldType, UploadPdfAction, CoverSheetMode,
  SAAVerbosity,
  SigningOrderSettingsItem,
  PartyDistributionState
} from '@property-folders/contract/yjs-schema/property';
import { bind } from 'immer-yjs';
import * as Y from 'yjs';
import { Predicate } from '../../predicate';
import { applyMigrationsV2, applyMigrationsV2_1 } from '..';
import { TandcAcceptPartyYjsDal } from './tandc-accept-party';
import { SubscriptionFormCode, SubscriptionFormTypes } from '../../subscription-forms';
import { FormDescriptorRecord, FormState } from './types';
import { uuidv4 } from 'lib0/random';
import { CustomFieldType, FormOrderState, FormOrderType, Maybe } from '@property-folders/contract';
import { YFormUtil } from '../../util/yform';
import { cloneDeep, omit } from 'lodash';
import { saaNonUniqueWatchPaths } from '@property-folders/contract/yjs-schema/variations/sales-agency-agreement';
import { FillFieldDefinition } from '../../signing/pdf-form-filler';
import { emailEventIsLater } from '../../audit/emailEventSort';
import { MakeOptional } from '../../types/Utility';
import { materialiseProperty, materialisePropertyData } from './index';
import { compareFormInstances } from '../../util/compareFormInstances';
import { formatTimestamp } from '../../util/formatting';
import { getValueByPath } from '../../util/pathHandling';
import { v4 } from 'uuid';
import { produceContactDetailsSnapshotCompareString } from '../../util/purchaserHash';
import { AjaxPhp } from '../../util/ajaxPhp';
import { FormUtil, getSigningOrderVersion, SigningOrderVersion } from '../../util/form';
import { byMapperFn } from '../../util/sortComparison';
import { toTitleCase } from '../../util/toTitleCase';
import { getFormUploadRefs } from '../../util/upload-forms';
import { getCoverSheetMode } from './cover-sheet-customisation';
import { ContentType } from '@property-folders/contract';
import { getProxyEmail } from '../../util/dataExtract';

export type FormCardRenderOpts = {
  suggestionActions: ('request' | 'create' | 'view' | 'upload')[];
  iconBefore?: string
  iconAfter?: string
  notYetImplemented?: boolean
  processNotForm?: boolean
};

type FormStateRequirement = { [status: string]: boolean };
export type FormStateRequirementDescriptor = {
  [formCode: string]: FormStateRequirement
};
export type FormStateRequirementMessage = {
  [formCode: string]: string
};

export type PartyCategory =
  | 'agent'
  | 'vendor'
  | 'purchaser'
  | 'other'
  | 'tenant'
  | 'manager'
  | 'landlord';

/**
 * @deprecated - use mapSigningPartySourceTypeToCategoryRespectingOverride
 * @param sourceType
 */
export function mapSigningPartySourceTypeToCategory(sourceType: SigningPartySourceType): PartyCategory | undefined {
  switch (sourceType) {
    case SigningPartySourceType.Salesperson:
      return 'agent';
    case SigningPartySourceType.Vendor:
    case SigningPartySourceType.VendorFirstParty:
    case SigningPartySourceType.VendorSecondParty:
      return 'vendor';
    case SigningPartySourceType.Purchaser:
    case SigningPartySourceType.PurchaserFirstParty:
    case SigningPartySourceType.PurchaserSecondParty:
      return 'purchaser';
    case SigningPartySourceType.Other:
    case SigningPartySourceType.OtherFirstParty:
    case SigningPartySourceType.OtherSecondParty:
      return 'other';
    case SigningPartySourceType.Error:
    default:
      return undefined;
  }
}

export function sourceUniqueKey(source: SigningPartySource) {
  if (source.representationHierarchy) {
    const rmap = [...source.representationHierarchy];
    if (rmap.length === 1) {
      rmap.push({ accessKey: 'primarySubcontact', position: 0 }); // make it compatible with classic source types
    }
    return rmap.map(r=>{
      return `${r.itemId??r.position}`; // We do want to coerce this potential number to a string, so `` it is
    }).join('_');
  }
  const position = [
    SigningPartySourceType.OtherSecondParty,
    SigningPartySourceType.VendorSecondParty,
    SigningPartySourceType.PurchaserSecondParty
  ].includes(source.type) ? 1 : 0;
  return `${source.id}_${position}`;
}

export function partyCategoryToLabelStrings(party: PartyCategory, hasAuthRep: boolean): { singular: string, plural: string } {
  switch (party) {
    case 'agent':
      return hasAuthRep
        ? { singular: 'Agent\'s Authorised Representative', plural: 'Agent\'s Authorised Representatives' }
        : { singular: 'Agent', plural: 'Agents' };
    case 'vendor':
      return { singular: 'Vendor', plural: 'Vendors' };
    case 'purchaser':
      return { singular: 'Purchaser', plural: 'Purchasers' };
    case 'other':
      return { singular: 'Other', plural: 'Others' };
    default:
      return party
        ? { singular: toTitleCase(party), plural: toTitleCase(`${party}s`) }
        : { singular: 'Unknown', plural: 'Unknowns' };
  }
}

export function getSourceTypeRespectingOverride(source: SigningPartySource | undefined) {
  return source?.overrideType ?? source?.type;
}
export function mapSigningPartySourceTypeToCategoryRespectingOverride(source: SigningPartySource | undefined) {
  return source?.originalType
    ? (source.signatureDisplayName ?? source.originalType).replace(/(\w)(\d)/, '$1')
    : (mapSigningPartySourceTypeToCategory(source?.overrideType ?? source?.type) || 'other');
}

export function isRecipientServed(recipient: ServeStateRecipient) {
  return !!(recipient.manuallyServed || recipient.viewedOnline || recipient.servedByEmail);
}

const saaBase: Omit<FormDescriptorRecord, 'label' | 'description'> = {
  formFamily: FormCode.RSAA_SalesAgencyAgreement,
  renderOpts: {
    suggestionActions: ['create', 'view']
  },
  usesPartyEmail: true,
  usesPartyPhone: true,
  parties: [{ type: 'vendor' }, { type: 'agent' }],
  documentExpiryPaths: {
    duration: 'agency.duration',
    startDate: 'agency.startOther',
    startEnable: 'agency.start'
  },
  documentExpiryPathsDefaults: {
    duration: 90
  },
  variationClassificationNoun: 'Agreement',
  clonePreviousInfo: {
    cover: true,
    cc: true
  },
  isCustomisable: 'optional'
};

const noDocumentExpiryPaths: Pick<FormDescriptorRecord, 'documentExpiryPaths' | 'documentExpiryPathsDefaults'> = {
  documentExpiryPaths: {},
  documentExpiryPathsDefaults: {}
};

const FormTypeRelativeOrder: FormCodeUnion[] = [
  // SAA
  FormCode.RSAA_SalesAgencyAgreement,
  //Contract
  FormCode.RSC_ContractOfSale,
  FormCode.ContractManagement,
  // Form 1
  FormCode.Form1,
  SubscriptionFormCode.SAF001V2_Form1,
  // All Documents
  FormCode.AllDocuments,
  // VQ
  SubscriptionFormCode.SAR008_VendorQuestionnaire,
  //Offer Managemnt
  FormCode.PurchaserManagement,
  FormCode.OfferManagement,
  // License to Occupy
  FormCode.LicenceToOccupy,
  SubscriptionFormCode.SACS015_LicenceToOccupy,
  // Story Sheet
  FormCode.StorySheet,
  // Open Inspection
  FormCode.OpenInspection,
  // Auction
  FormCode.Auction
];
export const FormTypeOrderMap = new Map(
  FormTypeRelativeOrder.map((value, index) => [value, index])
);

const ProcessTypes: Record<string, FormDescriptorRecord> = {

  [FormCode.OpenInspection]: {
    ...noDocumentExpiryPaths,
    label: 'Open Inspection',
    description: '',
    formFamily: FormCode.OpenInspection,
    primary: true,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view'],
      iconBefore: 'search',
      notYetImplemented: true,
      processNotForm: true
    }
  },
  [FormCode.Auction]: {
    ...noDocumentExpiryPaths,
    label: 'Auction',
    description: '',
    formFamily: FormCode.Auction,
    primary: true,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view'],
      iconBefore: 'gavel',
      notYetImplemented: true,
      processNotForm: true
    }
  },
  [FormCode.PurchaserManagement]: {
    ...noDocumentExpiryPaths,
    label: 'Purchaser Management',
    description: '',
    formFamily: FormCode.PurchaserManagement,
    primary: true,
    suggestion: { },
    debug: true,
    renderOpts: {
      suggestionActions: ['view'],
      iconBefore: 'groups',
      processNotForm: true
    },
    navigateTo: 'prospective-purchasers'
  },
  [FormCode.OfferManagement]: {
    ...noDocumentExpiryPaths,
    label: 'Offer Management',
    description: '',
    formFamily: FormCode.OfferManagement,
    primary: true,
    suggestion: { },
    debug: true,
    renderOpts: {
      suggestionActions: ['view'],
      iconBefore: 'handshake',
      processNotForm: true
    },
    navigateTo: 'offer-management'
  },
  [FormCode.ContractManagement]: {
    ...noDocumentExpiryPaths,
    label: 'Contract Management',
    description: '',
    formFamily: FormCode.ContractManagement,
    primary: true,
    suggestion: {},
    renderOpts: {
      suggestionActions: [],
      notYetImplemented: false,
      processNotForm: true,
      iconBefore: 'contract'
    },
    navigateTo: 'contracts'
  },
  [FormCode.AllDocuments]: {
    ...noDocumentExpiryPaths,
    label: 'All Documents',
    description: '',
    formFamily: FormCode.AllDocuments,
    primary: true,
    suggestion: {},
    navigateTo: 'documents',
    renderOpts: {
      suggestionActions: [],
      notYetImplemented: false,
      processNotForm: true,
      iconBefore: 'list_alt'
    }
  }
};

export const FormTypes: Record<string, FormDescriptorRecord> = {
  ...SubscriptionFormTypes,
  [FormCode.RSAA_SalesAgencyAgreement]: {
    ...saaBase,
    label: 'Sales Agency Agreement',
    description: 'Core contract to engage Agency on behalf of the Vendor to sell a property.',
    formFamily: FormCode.RSAA_SalesAgencyAgreement,
    primary: true,
    suggestion: {},
    recommendVariation: true,
    commonWatchPaths: saaNonUniqueWatchPaths,
    verbosityModes: [{ label: 'Standard SAA', mode: SAAVerbosity.standard }, { label: 'Short SAA', mode: SAAVerbosity.short }]
  },
  [ExtraFormCode.AAV_SalesAgencyAgreementVariation]: {
    ...saaBase,
    label: 'Variation of Sales Agency Agreement',
    wizardTitle: 'Sales Agency Agreement Variation',
    description: 'A Variation to an Agency Agreement',
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    isVariation: true
  },
  [ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent]: {
    ...saaBase,
    label: 'Subsequent Sales Agency Agreement',
    printTitle: 'Subsequent Sales Agency Agreement',
    description: 'A Subsequent Sales Agency Agreement is used to extend the term of an existing Sales Agency Agreement that is expiring.',
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    formFamily: FormCode.RSAA_SalesAgencyAgreement,
    renderOpts: {
      suggestionActions: ['create', 'view']
    }
  },
  [FormCode.VendorQuestionnaire]: {
    ...noDocumentExpiryPaths,
    label: 'Vendor Questionnaire',
    description: '',
    formFamily: FormCode.VendorQuestionnaire,
    primary: true,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view'],
      notYetImplemented: true
    },
    parties: [{ type: 'vendor' }]
  },
  [FormCode.Form1]: {
    ...noDocumentExpiryPaths,
    label: 'Form 1 - Vendor’s Statement',
    description: 'Legal disclosure of property details',
    formFamily: FormCode.Form1,
    primary: true,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['request', 'create', 'view'],
      notYetImplemented: true
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [{ type: 'vendor' }, { type: 'agent' }],
    serveToPurchaser: true,
    uploadVersion: ExtraFormCode.Form1Upload,
    useLatestOnPropertyCard: true,
    archiveSiblingTypesOnCreate: [FormCode.Form1, SubscriptionFormCode.SAF001V2_Form1, ExtraFormCode.Form1Upload]
  },
  [ExtraFormCode.Form1Upload]: {
    ...noDocumentExpiryPaths,
    label: 'Form 1 - Vendor’s Statement',
    shortLabel: 'Form 1 - Uploaded',
    description: 'Legal disclosure of property details',
    formFamily: FormCode.Form1,
    primary: false,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: [ 'view', 'upload' ]
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [],
    isCustomisable: 'required',
    serveToPurchaser: true,
    allowEmptySigning: true,
    customiseFieldTypes: {
      signing: 'restricted',
      serve: 'full',
      other: 'hidden',
      contact: 'hidden',
      property: 'hidden'
    },
    archiveSiblingTypesOnCreate: [FormCode.Form1, SubscriptionFormCode.SAF001V2_Form1, ExtraFormCode.Form1Upload]
  },
  [FormCode.LicenceToOccupy]: {
    ...noDocumentExpiryPaths,
    label: 'Licence to Occupy',
    description: '',
    formFamily: FormCode.LicenceToOccupy,
    primary: true,
    suggestion: { [FormCode.RSC_ContractOfSale]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view'],
      notYetImplemented: true
    },
    parties: [{ type: 'vendor' }]
  },
  [FormCode.RSC_ContractOfSale]: {
    ...noDocumentExpiryPaths,
    label: 'Contract of Sale',
    description: 'Contract to sell property',
    formFamily: FormCode.RSC_ContractOfSale,
    primary: true,
    suggestion: {},
    signingRequirements: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    signingRequirementsMessage: { [FormCode.RSAA_SalesAgencyAgreement]: 'This document cannot be sent for signing until there is a fully executed Agency Agreement between the Agency and the Vendor.' },
    renderOpts: {
      suggestionActions: ['request', 'create', 'view']
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [{ type: 'purchaser' }, { type: 'vendor' }],
    variationClassificationNoun: 'Contract',
    externalTerminationCode: ExtraFormCode.SCTE_ContractOfSaleTerminationExternal,
    serveToPurchaser: true,
    clonePreviousInfo: {
      cover: true,
      cc: true
    },
    isCustomisable: 'optional'
  },
  [FormCode.OfferToPurchase]: {
    ...noDocumentExpiryPaths,
    label: 'Offer to Purchase',
    wizardTitle: 'Letter of Offer',
    description: 'Offer to purchase property',
    formFamily: FormCode.OfferToPurchase,
    primary: true,
    suggestion: {},
    signingRequirements: {},
    signingRequirementsMessage: {},
    renderOpts: {
      suggestionActions: ['request', 'create', 'view']
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [{ type: 'purchaser' }],
    variationClassificationNoun: 'Offer'
  },
  [ExtraFormCode.SCV_ContractOfSaleVariation]: {
    ...noDocumentExpiryPaths,
    label: 'Variation to Contract of Sale',
    description: 'A Variation to a Contract of Sale',
    formFamily: FormCode.RSC_ContractOfSale,
    suggestion: { [FormCode.RSC_ContractOfSale]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [{ type: 'purchaser' }, { type: 'vendor' }],
    variationClassificationNoun: 'Contract',
    isVariation: true,
    serveToPurchaser: true,
    clonePreviousInfo: {
      cover: true,
      cc: true
    }
  },
  [FormCode.StorySheet]: {
    ...noDocumentExpiryPaths,
    label: 'Story Sheet',
    description: '',
    formFamily: FormCode.StorySheet,
    primary: true,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view'],
      notYetImplemented: true
    }
  },
  [ExtraFormCode.SCT_ContractOfSaleTermination]: {
    ...noDocumentExpiryPaths,
    label: 'Mutual Termination of Contract of Sale',
    description: 'A Termination of a Contract of Sale',
    formFamily: FormCode.RSC_ContractOfSale,
    suggestion: { [FormCode.RSC_ContractOfSale]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    usesPartyEmail: true,
    usesPartyPhone: true,
    parties: [{ type: 'purchaser' }, { type: 'vendor' }],
    variationClassificationNoun: 'Document',
    isTermination: true,
    clonePreviousInfo: {
      cover: true,
      cc: true
    }
  },
  [ExtraFormCode.SCTE_ContractOfSaleTerminationExternal]: {
    ...noDocumentExpiryPaths,
    label: 'External Termination of Contract of Sale',
    description: 'A placeholder document to represent an externally executed Termination',
    formFamily: FormCode.RSC_ContractOfSale,
    suggestion: { [FormCode.RSC_ContractOfSale]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    parties: [],
    variationClassificationNoun: 'Document',

    isTermination: true,
    wizardOpts: {
      noSigning: true,
      pdfOnly: true
    }
  },
  [FormCode.UploadedDocument]: {
    ...noDocumentExpiryPaths,
    label: 'Uploaded Document',
    description: '',
    formFamily: FormCode.UploadedDocument,
    isCustomisable: 'required'
  },
  ...ProcessTypes
};

export function isOutForSigning(state?: FormSigningState) {
  return state === FormSigningState.OutForSigning || state === FormSigningState.OutForSigningPendingUpload || state === FormSigningState.OutForSigningPendingServerProcessing;
}

/**Use only the ydoc to determine completed time.
   *
   * Links below concern similar methodology
   * Also see property-folders\services\lib\document-preview.ts @getCompletedAtMs
   * Also see property-folders\common\util\pdfgen\index.ts @timestampOfAgreement
   * Also see property-folders\services\lib\signing.ts @getPartyTimestampsFromAuditIfNotWetSigned
   * Also see property-folders\services\api\handler\properties\forms\email\postEmailForm.ts @getFieldDefinitions const partyTimestamps
   */
export function determineCompletedTime(form:FormInstance, noServerCompletionCheck?: boolean) {
  if (!noServerCompletionCheck && !(Array.isArray(form.signing?.parties) && form.signing.session)) throw new Error('Signing hasn\'t even started');
  const parties = getInvolvedSigningParties(form.signing);
  if (!noServerCompletionCheck
    && parties.some(p => !(p.signedTimestamp && p.serverAcceptPending === false))
  ) {
    throw new Error('Not all parties have signed or are not yet server processed');
  }
  // At this point, we should be able to trust that all signing timestamps are valid. Specifically
  // that all eSigning timestamps are at the time the server received them, and that paper signed
  // timestamps are later than this. There is the case that the ydoc times for e-signing are
  // modified, but in that case, we should have audit events for when the signature was accepted.
  // Paper signing is already trust of the user, so there's nothing else for that.
  return parties.length ? Math.max(...parties.map(p=>p.signedTimestamp??0)) : undefined;
}

export class PropertyFormYjsDal {
  dataRootKey: string;
  metaRootKey: string;
  constructor(
    private doc: Y.Doc,
    dataRootKey: string | undefined,
    metaRootKey: string | undefined
  ) {
    this.dataRootKey = dataRootKey ?? PropertyRootKey.Data.toString();
    this.metaRootKey = metaRootKey ?? PropertyRootKey.Meta.toString();
  }

  /**Create a map of {[source id+type]: {recipient info}}
   *
   * @param recipients
   * @param form1FormCode
   * @returns
   */
  public mapForm1RecipientsToSourceId(recipients?: ServeStateRecipient[] | undefined, form1FormCode: FormCodeUnion = SubscriptionFormCode.SAF001V2_Form1) {
    const family = FormTypes[form1FormCode].formFamily;
    // form 1 recipients are tracked in the root of the meta doc.
    const rootMeta = this.doc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    recipients = recipients??rootMeta?.formStates?.[family]?.recipients??[];
    const recipientsIds = (recipients)?.map(r=>r.id);
    const workingRecipients = new Set(recipientsIds);
    const matchedRecipients = new Map<string, {signingParty: SigningParty, source: SigningPartySource, recipient: ServeStateRecipient, sublineage: string, sourceKey: string}>();

    for (const key of rootMeta?.sublineageRoots??[]) {
      const subMeta = this.doc.getMap(key+META_APPEND).toJSON() as TransactionMetaData;
      const signersHere = Object.values(subMeta.formStates??{})
        .flatMap(fs=>fs.instances)
        .filter(i=>Array.isArray(i?.signing?.parties))
        .flatMap(i=>i?.signing?.parties)
        .filter(Predicate.isTruthy);

      for (const signer of signersHere) {
        if (workingRecipients.has(signer.id)) {
          workingRecipients.delete(signer.id);
          const recipient = recipients.find(r=>r.id === signer.id);
          if (!recipient) continue;
          const sourceKey = sourceUniqueKey(signer.source);
          matchedRecipients.set(sourceKey, { sublineage: key, signingParty: signer, source: signer.source, recipient, sourceKey });
        }
      }
    }
    if (workingRecipients.size > 0) {
      console.warn('Could not match all recipients', workingRecipients, matchedRecipients);
    }
    return matchedRecipients;
  }

  public get metaBinder() {
    return bind<TransactionMetaData>(this.doc.getMap(this.metaRootKey));
  }

  public get dataBinder() {
    return bind<MaterialisedPropertyData>(this.doc.getMap(this.dataRootKey));
  }

  public get ydocIds() {
    return { dataRootKey: this.dataRootKey, metaRootKey: this.metaRootKey };
  }

  public getSublineageData(sublineageId: string): Maybe<MaterialisedPropertyData> {
    return materialisePropertyData(this.doc, sublineageId);
  }

  public getMaterialisedProperty(): Maybe<MaterialisedProperty> {
    return materialiseProperty(this.doc);
  }

  public static searchFormInstanceByIdFromState(formId: string, state: TransactionMetaData) {
    if (!state.formStates) return undefined;

    for (const familyKey of Object.keys(state.formStates)) {
      const family = state.formStates[familyKey];
      for (const instance of family.instances || []) {
        if (instance.id === formId) return instance;
      }
    }

    return undefined;
  }

  public searchFormInstanceById(formId: string) {
    return PropertyFormYjsDal.searchFormInstanceByIdFromState(formId, this.metaBinder.get());
  }

  static searchFormInstanceByCodeAndJob(formCode: string, jobId: number, state: TransactionMetaData) {
    if (!state.formStates) return undefined;
    const familyCode = FormTypes[formCode].formFamily;
    const instances = state.formStates[familyCode]?.instances || [];

    for (const instance of instances) {
      if (instance.formCode === formCode && instance.order?.job?.id && instance.order.job.id === jobId) {
        return instance;
      }
    }

    return undefined;
  }

  static searchFormInstanceByCodeAndSourceFormId(formCode: string, sourceFormId: string, state: TransactionMetaData) {
    if (!state.formStates) return undefined;
    const familyCode = FormTypes[formCode].formFamily;
    const instances = state.formStates[familyCode]?.instances || [];

    for (const instance of instances) {
      if (instance.formCode === formCode && instance.order?.source?.sourceFormId === sourceFormId) {
        return instance;
      }
    }

    return undefined;
  }

  public static searchFormInstanceBySigningSessionIdFromState(signingSessionId: string, state: TransactionMetaData) {
    if (!state.formStates) return undefined;

    for (const familyKey of Object.keys(state.formStates)) {
      const family = state.formStates[familyKey];
      for (const instance of family.instances || []) {
        if (instance.signing?.session?.id === signingSessionId) {
          return instance;
        }
      }
    }

    return undefined;
  }

  public static searchFormInstanceBySigningSessionIdFromYdoc(signingSessionId: string, ydoc: Y.Doc) {
    const rootMeta = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    const rootRes = this.searchFormInstanceBySigningSessionIdFromState(signingSessionId, rootMeta);
    if (rootRes) return { instance: rootRes, dataRootKey: PropertyRootKey.Data, metaRootKey: PropertyRootKey.Meta };

    for (const sublineageId of rootMeta.sublineageRoots??[]) {
      const metaKey = sublineageId+META_APPEND;
      const subMeta = ydoc.getMap(metaKey).toJSON() as TransactionMetaData;
      const res = this.searchFormInstanceBySigningSessionIdFromState(signingSessionId, subMeta);
      if (res) return { instance: res, dataRootKey: sublineageId, metaRootKey: metaKey };
    }

    return undefined;
  }

  /**
   * search root for form instance, and then the sub lineages if it fails to find it in the root
   * @param formCode
   * @param formId
   * @param ydoc
   */
  public static searchSublineagesForFormInstanceInYdoc(formCode: string, formId: string, ydoc: Y.Doc) {
    const rootMeta = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
    const rootRes = this.getFormInstanceFromState(formCode, formId, rootMeta);
    if (rootRes) return { instance: rootRes, dataRootKey: PropertyRootKey.Data, metaRootKey: PropertyRootKey.Meta };

    for (const sublineageId of rootMeta.sublineageRoots??[]) {
      const metaKey = sublineageId+META_APPEND;
      const subMeta = ydoc.getMap(metaKey).toJSON() as TransactionMetaData;
      const res = this.getFormInstanceFromState(formCode, formId, subMeta);
      if (res) return { instance: res, dataRootKey: sublineageId, metaRootKey: metaKey };
    }

    return undefined;
  }

  public static getFormInstanceFromState(formCode: string, formId: string, state: TransactionMetaData) {
    const family = PropertyFormYjsDal.getFormFamily(formCode, state);
    return family?.instances?.filter(i => i.id === formId)?.[0];
  }

  public static getFormInstanceAndFamilyFromState(formCode: string, formId: string, state: TransactionMetaData) {
    const family = PropertyFormYjsDal.getFormFamily(formCode, state);
    return { instance: family?.instances?.filter(i => i.id === formId)?.[0], family };
  }

  public static getLatestFormFamilyInstance(familyCode: string, state?: TransactionMetaData) {
    if (!state?.formStates) return undefined;

    const family = state.formStates[familyCode];
    if (!family?.instances?.length) return undefined;

    let latest = family.instances[0]?.archived ? undefined : family.instances[0];
    for (const instance of family.instances) {
      if (!instance.archived && (instance.created || 0) > (latest?.created || 0)) {
        latest = instance;
      }
    }
    return latest;
  }

  public static getLatestSignedInstanceForSourceParty(formCode: string, sourcePartyId: string, state: TransactionMetaData) {
    const famState = FormUtil.getFormFamilyState(formCode, state);
    const signedInstancesForParty = famState?.instances?.filter(inst => {
      if (inst.signing?.state !== FormSigningState.Signed) return false;
      inst.signing.session?.completedTime;
      return inst.signing.parties?.some(p=>p.source.id === sourcePartyId) ?? false;
    });
    if (!signedInstancesForParty) return null;
    return (signedInstancesForParty.sort((instanceA, instanceB) => {
      // descending sort
      return (instanceB.signing?.session?.completedTime||0) - (instanceA.signing?.session?.completedTime||0);
    }))[0];
  }

  public static getFormSigningSessionFromState(formCode: string, formId: string, state: TransactionMetaData) {
    const instance = this.getFormInstanceFromState(formCode, formId, state);
    return instance?.signing?.session;
  }

  public static signingSessionAllSigned(session: SigningSession | undefined) {
    // file is the best indicator - timestamp is set by the server after the fact so shouldn't be checked
    return !!session && session.fields.every(field => fieldIsSigned(field));
  }

  public static getDistinctFormFamilies(state: TransactionMetaData) {
    const familyCodes = new Set(Object.keys(state.formStates || {}).filter(Predicate.isTruthy));

    return [...familyCodes]
      .map(fam => {
        const defn = FormTypes[fam];
        return defn;
      })
      .filter(Predicate.isTruthy);
  }

  public getSigningSessionAllSigned(formCode: string, formId: string) {
    const session = PropertyFormYjsDal.getFormSigningSessionFromState(formCode, formId, this.metaBinder.get());
    return PropertyFormYjsDal.signingSessionAllSigned(session);
  }

  public getFormSigningSession(formCode: string, formId: string) {
    return PropertyFormYjsDal.getFormSigningSessionFromState(formCode, formId, this.metaBinder.get());
  }

  public getFormInstance(formCode: string, formId: string): FormInstance | undefined {
    return PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, this.metaBinder.get());
  }

  public getLatestFormFamilyInstance(familyCode: string) {
    return PropertyFormYjsDal.getLatestFormFamilyInstance(familyCode, this.metaBinder.get());
  }

  public getDistinctFormFamilies() {
    return PropertyFormYjsDal.getDistinctFormFamilies(this.metaBinder.get());
  }

  public getAgentPartyIds(formCode: string, formId: string, signingSessionId: string) {
    const instance = this.getFormInstance(formCode, formId);
    const agentPartyIds = new Map<number, string>();
    if (instance?.signing?.session?.id !== signingSessionId) return agentPartyIds;

    for (const party of instance?.signing?.parties || []) {
      if (!party.snapshot?.linkedSalespersonId) continue;
      const salespersonId = typeof party.snapshot.linkedSalespersonId === 'number'
        ? party.snapshot.linkedSalespersonId
        : parseInt(party.snapshot.linkedSalespersonId, 10);
      if (isNaN(salespersonId)) continue;

      agentPartyIds.set(salespersonId, party.id);
    }

    return agentPartyIds;
  }

  public getDataForPdfStitching(formCode: string, formId: string, signingSessionId: string, partyTimestamps: Map<string, number>, completed: boolean) {
    const instance = this.getFormInstance(formCode, formId);

    return PropertyFormYjsDal.getDataForPdfStitchingFromState(signingSessionId, partyTimestamps, instance, completed);
  }

  public static getDataForPdfStitchingFromState(
    signingSessionId: string,
    partyTimestamps: Map<string, number>,
    instance: FormInstance | undefined,
    completed: boolean
  ): undefined | { basePdf: FileRef, fields: FillFieldDefinition[] } {
    if (instance?.signing?.session?.id !== signingSessionId) return undefined;
    if (!instance.signing.session.fields.length) {
      if (FormTypes[instance.formCode]?.allowEmptySigning) {
        return {
          basePdf: instance.signing.session.file,
          fields: []
        };
      } else {
        return undefined;
      }
    }
    const parties = instance.signing.parties || [];

    return {
      basePdf: instance.signing.session.file,
      fields: instance.signing.session.fields
        .map<FillFieldDefinition | undefined>(field => {
          // only use default values when not filling a completed doc.
          const custom = completed
            ? undefined
            : instance?.signing?.customFields?.find(cf => cf.id === field.customFieldId);
          const defaultText = (() => {
            switch (custom?.type) {
              case CustomFieldType.remoteText:
                return custom.text ? custom.text : undefined;
              case CustomFieldType.remoteRadio:
              case CustomFieldType.remoteCheck:
                return custom.on ? 'on' : undefined;
              default:
                return undefined;
            }
          })();
          if (!fieldIsSigned(field) && !defaultText) return undefined;
          const fieldTimestamp = field.isWetSigned ? field.timestamp : partyTimestamps.get(field.partyId) || field.timestamp;
          const defaultTimestamp = defaultText ?  Date.now() : undefined;
          const timestamp = fieldTimestamp || defaultTimestamp;
          if (!timestamp) return undefined;

          const snapshot = parties.find(p => p.id === field.partyId)?.snapshot;

          return {
            id: field.id,
            type: field.type,
            subtype: field.subtype,
            partyId: field.partyId,
            file: field.file,
            isWetSigned: field.isWetSigned,
            timestamp: timestamp,
            inlineTimestampPosition: field.inlineTimestampPosition,
            name: snapshot?.name || '',
            signingPhrase: snapshot?.filledSigningPhrase || '',
            text: field.text != null ? field.text : defaultText
          };
        })
        .filter(Predicate.isNotNull)
    };
  }

  public getOwnerIds() {
    const meta = this.metaBinder.get();
    return {
      entityId: meta.entity?.id,
      // todo: there might be a change which alters where this comes from
      agentId: meta.creator?.id
    };
  }

  /**
   * Also transitions form signing state to fully-executed and sets timestamps everywhere
   */
  public setCompletedSigningSessionPdfFile(
    formCode: string,
    formId: string,
    signingSessionId: string,
    files: FileRef[],
    partyTimestamps: Map<string, number>,
    wetSigningParties: SigningParty[],
    eSignedPdfId?: string,
    autoServeForm1?: boolean,
    mergedFile?: string
  ) {
    const { instance: readForm } = YFormUtil.getFormLocationFromId(formId, this.doc)??{};
    return applyMigrationsV2_1<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'Update list of previously signed IDs',
        docKey: PropertyRootKey.Meta,
        fn: state => {
          if (!Array.isArray(state.previouslySignedParties)) {
            state.previouslySignedParties = [];
          }
          const previousIds = state.previouslySignedParties;
          for (const party of getInvolvedSigningParties(readForm?.signing)) {
            //dont update timestamps for wet signing
            if (party?.type !== SigningPartyType.SignWet) {
              if (eSignedPdfId) party.signedPdf = { id: eSignedPdfId, contentType: 'application/pdf' };
              party.signedTimestamp = partyTimestamps.get(party.id);
            }
            console.log('set serverAcceptPending false');
            party.serverAcceptPending = false;
            const partyDataModelId = party?.source?.id;
            if (partyDataModelId && !previousIds.includes(partyDataModelId)) {
              console.log('Marked previously signed', party?.snapshot?.name, partyDataModelId);
              previousIds.push(partyDataModelId);
            }
          }
        }
      },
      {
        name: 'set completed signing session pdf file',
        fn: state => {
          const session = PropertyFormYjsDal.getFormSigningSessionFromState(formCode, formId, state);
          const { instance: form, family } = PropertyFormYjsDal.getFormInstanceAndFamilyFromState(formCode, formId, state);

          if (!form?.signing) return false;
          if (session?.id !== signingSessionId) return false;
          if (getCompletedFiles(session).length) return false;
          const now = Date.now();
          form.signing.state = FormSigningState.Signed;
          session.completedTime = determineCompletedTime(form);
          session.completedFile = files.map(f => ({
            id: f.id,
            contentType: f.contentType
          }));

          //update the file id's of wetsigned parties with watermarked PDFs
          if (wetSigningParties?.length) {
            wetSigningParties.forEach(wp => {
              const party = form.signing?.parties?.find(p => p.id === wp.id);
              if (!party?.signedPdf || !wp.signedPdf) return;
              party.signedPdf.id = wp.signedPdf.id;
            });
          }

          if (FormTypes[formCode].isTermination) {
            const famCode = FormTypes[formCode].formFamily;
            const formState = state.formStates?.[famCode];
            if (formState) {
              formState.terminatedTime = now;
              formState.terminationConfirmed = true;
            }
          }

          for (const field of form.signing.session?.fields || []) {
            field.timestamp = partyTimestamps.get(field.partyId);
          }

          for (const recipient of family?.recipients || []) {
            // force a re-serve if recipient has been marked for autoserving, or entity setting is true
            if ((recipient.autoServe === undefined && autoServeForm1) || recipient.autoServe) recipient.signingSessionId = session.id;
          }

          if (mergedFile) {
            session.certifiedDocument = { id: mergedFile, contentType: ContentType.Pdf };
          }
        }
      }]
    });
  }

  public updateCompletedSigningSessionPdfFile(formCode: string, formId: string, signingSessionId: string, files: FileRef[], wetSigningParties: SigningParty[]) {
    return applyMigrationsV2_1<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'update completed signing session pdf file',
        fn: state => {
          const session = PropertyFormYjsDal.getFormSigningSessionFromState(formCode, formId, state);
          const form = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);

          if (!form?.signing) return false;
          if (session?.id !== signingSessionId) return false;

          //update the file id's of wetsigned parties with watermarked PDFs
          if (wetSigningParties?.length) {
            wetSigningParties.forEach(wp => {
              const party = form.signing?.parties?.find(p => p.id === wp.id);
              if (!party?.signedPdf || !wp.signedPdf) return;
              party.signedPdf.id = wp.signedPdf.id;
            });
          }

          console.log('old signing files', JSON.stringify(session.completedFile));
          session.completedFile = files.map(f => ({
            id: f.id,
            contentType: f.contentType
          }));
          console.log('new signing files', JSON.stringify(session.completedFile));
        }
      }]
    });
  }

  public addIntermediateSigningSessionPdfFile(formCode: string, formId: string, signingSessionId: string, file: FileRef) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'add intermediate signing session pdf file',
        fn: state => {
          const session = PropertyFormYjsDal.getFormSigningSessionFromState(formCode, formId, state);
          if (session?.id !== signingSessionId) {
            return false;
          }

          if (!session.intermediateFiles) {
            session.intermediateFiles = [];
          }

          session.intermediateFiles.push({
            id: file.id,
            contentType: file.contentType
          });
        }
      }]
    });
  }

  public setPartyPageAccessed(formCode: string, formId: string, signingSessionId: string, partyId: string) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set signing session party last accessed timestamp',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }

          party.lastAccessedTimestamp = Date.now();
        }
      }]
    });
  }

  public setPartyTAndCAgreed(formCode: string, formId: string, signingSessionId: string, partyId: string, salespersonId?: string | number) {
    let readParty: SigningParty | undefined;
    const now = Date.now();
    return applyMigrationsV2_1<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set signing session party T&Cs agreed timestamp',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }

          party.lastAccessedTimestamp = now; // Updating this timestamp again because they should not have been able to really read the document prior to agreeing

          if (!party.tandcAgreedTimestamp) {
            // If they've already agreed, we won't set the timestamp
            party.tandcAgreedTimestamp = now;
          }

          readParty = cloneDeep(party);
        }
      }, {
        name: 'Set T&C accepted event',
        docKey: PropertyRootKey.Meta,
        fn: draft => {
          if (!readParty) {
            return false;
          }
          TandcAcceptPartyYjsDal.partyAddAcceptEvent(draft, now, readParty, salespersonId);
        }
      }]
    });
  }

  /**
   * Set the party decline reason on a signing session.
   * If the supplied reason is not truthy, it will be deleted
   */
  public setPartyDeclined(formCode: string, formId: string, signingSessionId: string, partyId: string, declineInfo: { type: SigningPartyDeclineType, reason: string }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set party decline reason',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }

          if (party.declineReason === declineInfo?.reason) {
            // it's already set to the same thing!
            return false;
          }

          party.declineType = declineInfo.type;
          party.declineReason = declineInfo.reason;
          party.declineTimestamp = Date.now();
        }
      }]
    });
  }

  /**
   * Clear out party locked/decline details in preparation for link resending
   */
  public preparePartyForLinkResend(formCode: string, formId: string, signingSessionId: string, partyId: string) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'clear party decline and lockout',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }

          delete party.declineType;
          delete party.declineReason;
          delete party.declineTimestamp;
          delete party.locked;
        }
      },{
        name: 'update party link last sent timestamp',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }

          party.linkLastSent = Date.now();
        }
      }]
    });
  }

  /**Specifically does not set the proxy's details
   *
   */
  public setPartyEmailAndPhone(formCode: string, formId: string, signingSessionId: string, partyId: string, email?: string, phone?: string) {
    if (!phone && !email) return;
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set party email',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);

          if (!party?.snapshot) {
            return false;
          }

          let changes = false;
          if (!party.snapshot.email && email) {
            // only overwrite if not set
            party.snapshot.email = email;
            changes = true;
          }
          if (!party.snapshot.phone && phone) {
            // only overwrite if not set
            party.snapshot.phone = phone;
            changes = true;
          }
          if (changes) return;
          return false;
        }
      }]
    });
  }

  /**
   * Sets the distribution state on a party or a cc recipient (identified by party id)
   */
  public setPartyDistributedState(opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    partyId: string,
    metaRootSublineage?: string // We typically shouldn't need to set this, as this should be set up in the form dal set up
  } & ({
    type: 'email',
    emailId: string,
    event: EmailEvent,
    timestamp: number
  } | {
    type: 'email-sent'
  } | {
    type: 'sms'
  } | {
    type: 'delayed'
  })) {
    const {
      formCode,
      formId,
      signingSessionId,
      partyId: partyIdRaw,
      metaRootSublineage
    } = opts;
    const [partyId, memberId] = partyIdRaw.split('.');
    console.log('setPartyDistributedState', { partyId, memberId });
    const usingKey = metaRootSublineage??this.metaRootKey;
    const applyDistributionStateTo = (party: { distributionState?: PartyDistributionState }) => {
      switch (opts.type) {
        // There's a hierarchy here. Delay can only be set on nothing, sms, can replace delayed
        // but not email, and email can replace anything
        // Manual was added. Any comms type can replace it, but not delayed

        case 'email-sent': {
          if (memberId) {
            if (party.distributionState?.type !== 'team') {
              party.distributionState = {
                type: 'team',
                members: {}
              };
            }
            party.distributionState.members[memberId] = {
              type: 'email'
            };
            break;
          }

          if (!party.distributionState) {
            party.distributionState = { type: 'email' };
            break;
          }
          party.distributionState.type = 'email';
          break;
        }
        case 'email': {
          const { event, emailId, timestamp } = opts;
          if (memberId) {
            if (party.distributionState?.type !== 'team') {
              party.distributionState = {
                type: 'team',
                members: {}
              };
            }
            if (party.distributionState.members[memberId]?.type !== 'email') {
              party.distributionState.members[memberId] = {
                type: 'email'
              };
            }

            if (emailEventIsLater(party.distributionState.members[memberId].lastDistributionEmailEvent, event)) {
              party.distributionState.members[memberId].lastDistributionEmailEventTimestamp = timestamp;
              party.distributionState.members[memberId].lastDistributionEmailEvent = event;
              party.distributionState.members[memberId].lastDistributionEmailId = emailId;
            }
            break;
          }

          if (!party.distributionState) {
            party.distributionState = { type: 'email' };
          }
          party.distributionState.type = 'email';
          // Checking what we just set because typescript
          if (party.distributionState.type === 'email' && event && emailEventIsLater(party.distributionState?.lastDistributionEmailEvent, event)) {
            party.distributionState.lastDistributionEmailEventTimestamp = timestamp;
            party.distributionState.lastDistributionEmailEvent = event;
            party.distributionState.lastDistributionEmailId = emailId;
          } else {
            return false;
          }
          break;
        }
        case 'sms': {
          if (memberId) {
            console.log('setting for team member');
            if (party.distributionState?.type !== 'team') {
              party.distributionState = {
                type: 'team',
                members: {}
              };
            }
            party.distributionState.members[memberId] = {
              type: 'sms'
            };
            break;
          }
          if (party.distributionState?.type === 'email') return false;
          if (!party.distributionState) {
            party.distributionState = { type: 'sms' };
          } else {
            party.distributionState.type = 'sms';
          }
          break;
        }
        case 'delayed': {
          if (party.distributionState) return false;
          party.distributionState = { type: 'delayed' };
          break;
        }
      }
    };

    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: usingKey,
      typeName: 'Property',
      migrations: [{
        name: 'set signing session party completed distribution',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = formInstance?.signing?.parties?.find(p => p.id === partyId);
          if (party) {
            if (applyDistributionStateTo(party) === false) return false;
          }

          const cc = formInstance?.cc?.find(cc => cc.id === partyId);
          if (cc) {
            if (applyDistributionStateTo(cc) === false) return false;
          }
        }
      }]
    });
  }

  public setLastEmailEvent(opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    partyId: string,
    event: EmailEvent,
    timestamp: number,
    emailId: string,
    metaRootSublineage?: string
    eventEmail?: string
  }) {
    const {
      formCode,
      formId,
      signingSessionId,
      partyId,
      event,
      timestamp,
      emailId,
      metaRootSublineage,
      eventEmail
    } = opts;

    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: metaRootSublineage??this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set signing session party last email event',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const party = (formInstance.signing.parties || []).find(p => p.id === partyId);
          if (!party) {
            return false;
          }
          if (eventEmail != null && getProxyEmail(party) !== eventEmail) {
            console.error('Event recieved for an email address which now seems to be defunct. Not setting event');
            return false;
          }
          if (emailEventIsLater(party.lastEmailEvent, event)) {
            party.lastEmailEventTimestamp = timestamp;
            party.lastEmailEvent = event;
            party.lastEmailId = emailId;
          }
        }
      }]
    });
  }

  public setRecipientLastEmailEvent(opts: {
    formCode: string,
    formId: string,
    signingSessionId: string,
    recipientId: string,
    event: EmailEvent,
    timestamp: number,
    emailId: string
  }) {
    const {
      formCode,
      formId,
      signingSessionId,
      recipientId,
      event,
      timestamp,
      emailId
    } = opts;

    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set signing session party last email event',
        fn: state => {
          const { instance: formInstance, family } = PropertyFormYjsDal.getFormInstanceAndFamilyFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          const recipient = (family?.recipients || []).find(r => r.id === recipientId);
          if (!recipient) {
            return false;
          }

          recipient.lastEmailEvent = {
            timestamp,
            type: event,
            emailId
          };
        }
      }]
    });
  }

  public hasCompletedSigningSessionPdfFile(formCode: string, formId: string) {
    const session = this.getFormSigningSession(formCode, formId);
    return !!getCompletedFiles(session).length;
  }

  public getSigningParties(formCode: string, formId: string, signingSessionId: string) {
    const instance = this.getFormInstance(formCode, formId);
    if (instance?.signing?.session?.id !== signingSessionId) {
      return [];
    }

    return (instance.signing.parties || [])
      .filter(Predicate.isNotNull);
  }

  public getIsEpfManagedForm1(formCode: string, formId: string) {
    const instance = this.getFormInstance(formCode, formId);
    return instance?.order?.filler?.system === 'EPF';
  }

  public getEpfOrderInfo(formCode: string, formId: string) {
    const instance = this.getFormInstance(formCode, formId);
    return instance?.order;
  }

  public getSigningRecipientEmails(formCode: string, formId: string, signingSessionId: string) {
    return this.getSigningParties(formCode, formId, signingSessionId)
      .map(party => Predicate.proxyNotSelf(party.proxyAuthority) ? party.proxyEmail : party.snapshot?.email )
      .filter(Predicate.isNotNull);
  }

  public searchFormInstanceBySigningSessionId(signingSessionId: string) {
    return PropertyFormYjsDal.searchFormInstanceBySigningSessionIdFromState(signingSessionId, this.metaBinder.get());
  }

  public transitionOutForSigningState(
    formCode: string,
    formId: string,
    signingSessionId: string,
    newState:
      | FormSigningState.OutForSigningPendingServerProcessing
      | FormSigningState.OutForSigningPendingUpload
      | FormSigningState.OutForSigning,
    isGenerating?: boolean
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'transition out for signing state',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (formInstance?.signing?.session?.id !== signingSessionId) {
            return false;
          }

          if (!isOutForSigning(formInstance.signing.state)) {
            return false;
          }

          formInstance.signing.state = newState;
          if (typeof isGenerating !== 'undefined') {
            formInstance.signing.isGenerating = isGenerating;
          }
        }
      }]
    });
  }

  public setIsGenerating(formCode: string, formId: string, isGenerating: boolean) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: `set PDF isGenerating: ${isGenerating}`,
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!formInstance?.signing) return false;

          formInstance.signing.isGenerating = isGenerating;
        }
      }]
    });
  }

  public resetDownloadServingForRecipient({ formCode, formId, recipientId }:{
    formCode: string,
    formId: string,
    recipientId: string
  }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'add serve recipient',
        fn: state => {
          const { recipients, unservedRecipients } = PropertyFormYjsDal.getFormFamilyServeCombinedRecipients(formCode, state);
          if (!(Array.isArray(recipients) && Array.isArray(unservedRecipients))) { // Both should be created by the above call if the parent element exists
            return false;
          }
          //if recipient already exists, force a re-serving
          const existingIndex = (recipients?.findIndex(r => r.id === recipientId)) ?? -1;
          if (existingIndex < 0) {
            return false;
          }
          const existing = recipients[existingIndex];
          if (!(existing && existing.serveMode === 'download' && !isRecipientServed(existing))) {
            return false;
          }
          const newEntry = cloneDeep(existing);
          recipients.splice(existingIndex,1);
          delete newEntry.serveMode;
          delete newEntry.downloaded;
          delete newEntry.servedBy;
          delete newEntry.timestamp;
          delete newEntry.forceReServe;
          unservedRecipients.push(newEntry);
        }
      }]
    });
  }

  public removeDownloadCancelledCustomRecipient({ formCode, formId, recipientId }:{
    formCode: string,
    formId: string,
    recipientId: string
  }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'add serve recipient',
        fn: state => {
          const { unservedRecipients } = PropertyFormYjsDal.getFormFamilyServeCombinedRecipients(formCode, state);
          if (!Array.isArray(unservedRecipients)) {
            return false;
          }
          const unservedIndex = (unservedRecipients && unservedRecipients.findIndex(ur => ur.id === recipientId)) ?? -1;
          if (unservedIndex >= 0) {
            const unserved = unservedRecipients[unservedIndex];
            if (unserved.serveMode) return false; // Shouldn't be in this list, but anyway
            unservedRecipients?.splice(unservedIndex,1);
            return;
          }
          return false;
        }
      }]
    });
  }

  public addServeRecipient(
    formCode: string,
    formId: string,
    detail: MakeOptional<ServeStateRecipient, 'id'>
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'add serve recipient',
        fn: state => {
          const { recipients, unservedRecipients } = PropertyFormYjsDal.getFormFamilyServeCombinedRecipients(formCode, state);
          if (!recipients) {
            return false;
          }

          const now = Date.now();

          //if recipient already exists, force a re-serving
          const existing = recipients?.find(r => r.id === detail.id);

          const unservedIndex = (unservedRecipients && unservedRecipients.findIndex(ur => ur.id === detail.id)) ?? -1;
          if (unservedIndex >= 0) {
            unservedRecipients?.splice(unservedIndex,1);
          }

          if (existing) {
            if (existing.serveMode === 'email')  existing.forceReServe = true;
            existing.timestamp = now;
            existing.address = detail.address || existing.address;
            existing.email = detail.email || existing.email;
            existing.contractDate = detail.contractDate || existing.contractDate;
          } else {
            const newRecipient: ServeStateRecipient = {
              ...detail,
              timestamp: now,
              id: detail.id || uuidv4(),
              contractDate: detail.contractDate
            };
            recipients.push(newRecipient);
          }
        }
      }]
    });
  }

  public forceServeRecipient(
    formCode: string,
    formId: string,
    recipientId: string
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'force serve recipient',
        fn: state => {
          const recipient = PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, state);
          if (!recipient) {
            return false;
          }
          recipient.forceReServe = true;
        }
      }]
    });
  }

  public updateServeRecipientEmail(
    formCode: string,
    formId: string,
    recipientId: string,
    email?: string,
    name?: string,
    address?: string
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'update recipient email',
        fn: state => {
          const recipient = PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, state);
          if (!recipient) {
            return false;
          }
          recipient.email = email ?? recipient.email;
          recipient.name = name ?? recipient.name;
          recipient.address = address ?? recipient.address;
        }
      }]
    });
  }

  public markServedByEmail(
    formCode: string,
    formId: string,
    recipientId: string,
    contentId: string,
    { purchasersHash, purchasersSnapshot }: {
      purchasersHash?: string,
      purchasersSnapshot?: LastServedContactSnapshot[] | undefined
    }
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'mark recipient served',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);

          if (!formInstance?.signing?.session?.id) {
            console.warn('Signing session missing');
            return false;
          }
          const signingSessionId = formInstance.signing.session.id;
          const recipient = PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, state);
          console.log('mark recipient served - recipient', JSON.stringify(recipient));
          if (!recipient) {
            console.warn('No recipient ID');
            return false;
          }

          if (recipient.lastServedSigningSessionId === signingSessionId && (recipient.lastServedContactSnapshot
            ? produceContactDetailsSnapshotCompareString(recipient.lastServedContactSnapshot) === produceContactDetailsSnapshotCompareString(purchasersSnapshot||[])
            : purchasersHash === undefined || recipient.lastServedPurchasersHash === purchasersHash
          ) && !recipient.forceReServe) {
            console.warn('Recipient is not due to be served');
            return false;
          }

          if (recipient.lastServedSigningSessionId) {
            recipient.reServed = true;
          }

          recipient.publishedDocumentId = contentId;
          recipient.lastServedSigningSessionId = signingSessionId;
          recipient.signingSessionId = signingSessionId;
          if (purchasersHash) recipient.lastServedPurchasersHash = purchasersHash;
          if (purchasersSnapshot) recipient.lastServedContactSnapshot = purchasersSnapshot;

          recipient.servedByEmail = {
            timestamp: Date.now()
          };
          recipient.manuallyServed = undefined;
          recipient.forceReServe = false;
        }
      }]
    });
  }

  public createRecipientsAndMarkManuallyServed(
    formCode: string,
    formId: string,
    purchasers: {id: string, email?: string, name: string, address?: string}[]
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'add and mark recipients served',
        fn: state => {
          const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(formCode, state);
          if (!recipients) return false;

          for (const purchaser of purchasers) {
            const newRecipient = {
              type: 'purchaser' as const,
              id: purchaser.id || uuidv4(),
              name: purchaser.name,
              address: purchaser.address||'',
              timestamp: Date.now(),
              manuallyServed: {
                timestamp: Date.now()
              },
              lastServedSigningSessionId: undefined,
              signingSessionId: '',
              servedByEmail: undefined
            };

            recipients.push(newRecipient);
          }
        }
      }]
    });
  }

  //reserve document to all purchasers(with an email) in contracts that are fully signed
  public reServeAllSignedPurchasers(
    formCode: string,
    formId: string,
    entityAutoServeForm1: boolean
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 're-serve document to all purchasers(with an email) in contracts that are fully signed, plus manual ones',
        fn: state => {
          const { instance: formInstance, family } = PropertyFormYjsDal.getFormInstanceAndFamilyFromState(formCode, formId, state);

          if (!formInstance || !family) return false;

          if (!family.recipients) {
            family.recipients = [];
          }

          const existingRecipients = family.recipients;
          const property = materialiseProperty(this.doc);
          const newRecipients: ServeStateRecipient[] = [];

          //Get all purchasers from all signed contracts
          for (const key of Object.keys(property?.alternativeRoots||{})) {
            const latestContractState = property?.alternativeRoots?.[key]?.meta?.formStates?.[FormCode.RSC_ContractOfSale]?.instances
              ?.filter(i=>SignedStates.has(i.signing?.state??FormSigningState.None))
              ?.sort(compareFormInstances)[0];

            if (latestContractState?.signing?.instanceAutoForm1 == null ? !entityAutoServeForm1 : !latestContractState.signing.instanceAutoForm1) continue;

            const contractPurchasers = latestContractState?.signing?.parties?.filter(p => mapSigningPartySourceTypeToCategoryRespectingOverride(p.source) === 'purchaser' && p.snapshot?.email);
            if (!contractPurchasers?.length) continue;

            const earliestBaseContract = property?.alternativeRoots?.[key]?.meta?.formStates?.[FormCode.RSC_ContractOfSale]?.instances
              ?.filter(i=>i.formCode === FormCode.RSC_ContractOfSale)
              ?.sort(compareFormInstances)?.reverse()[0]; // Switch to ascending order to get the first contract

            newRecipients.push(...contractPurchasers.map(p => ({
              type: 'purchaser' as const,
              id: p.id,
              name: p.snapshot?.name||'',
              email: p.snapshot?.email, // Note: Unlike other things in which the proxy has an alternative address, For Form 1s, we want it to go to the original party
              address: p.snapshot?.addressSingleLine||'',
              contractDate: formatTimestamp(earliestBaseContract?.signing?.session?.completedTime, undefined, false),
              timestamp: Date.now(),
              serveMode: 'email' as const,
              signingSessionId: formInstance.signing?.session?.id || ''
            })));
          }

          //also get the manual (non-contract linked) recipients
          const manualRecipients = existingRecipients.filter(er => !newRecipients.find(nr => nr.id === er.id));
          newRecipients.push(...manualRecipients.map(mr => ({
            ...mr,
            signingSessionId: formInstance.signing?.session?.id || ''
          })));

          //if recipient already exists, update it, otherwise create new
          for (const recipient of newRecipients) {
            const existingRecipient = existingRecipients.find(e => e.id === recipient.id);
            if (existingRecipient) {
              //dont serve existing recipients that were 'download' served
              if (existingRecipient.serveMode !== 'email') continue;
              //this should force a re-serving
              existingRecipient.signingSessionId = recipient.signingSessionId;
              console.log('Serving existing recipient', JSON.stringify(existingRecipient));
            } else {
              family.recipients.push(recipient);
              console.log('Serving new recipient', JSON.stringify(recipient));
            }
          }
        }
      }]
    });
  }

  public markServed(
    formCode: string,
    formId: string,
    recipientId: string,
    signedCopy?: FileRef,
    servedDate?: string
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'mark recipient served',
        fn: state => {
          const formInstance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!formInstance?.signing?.session?.id) {
            return false;
          }
          const signingSessionId = formInstance.signing.session.id;
          const recipient = PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, state);
          if (!recipient) {
            return false;
          }
          if (recipient.lastServedSigningSessionId === signingSessionId) {
            return false;
          }

          recipient.lastServedSigningSessionId = formInstance.signing.session.id;
          recipient.manuallyServed = {
            timestamp: Date.now(),
            signedCopy,
            servedDate
          };
          recipient.servedByEmail = undefined;
        }
      }]
    });
  }

  public alterServedCopy(
    formCode: string,
    formId: string,
    recipientId: string,
    signedCopy: FileRef
  ) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'alter served copy',
        fn: state => {
          const recipient = PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, state);
          if (!recipient) {
            return false;
          }
          if (!recipient.manuallyServed) {
            return false;
          }

          recipient.manuallyServed.signedCopy = signedCopy;
        }
      }]
    });
  }

  public static getFormFamilyServeRecipients(formCode: string, state?: TransactionMetaData) {
    if (!state) return;
    const fam = PropertyFormYjsDal.getFormFamily(formCode, state);
    if (fam && !fam?.recipients) {
      fam.recipients = [];
    }
    return fam?.recipients;
  }
  public static getFormFamilyServeCombinedRecipients(formCode: string, state: TransactionMetaData) {
    const fam = PropertyFormYjsDal.getFormFamily(formCode, state);
    if (fam && !fam?.recipients) {
      fam.recipients = [];
    } if (fam && !fam?.unservedRecipients) {
      fam.unservedRecipients = [];
    }
    return { recipients: fam?.recipients, unservedRecipients: fam?.unservedRecipients };
  }

  public getFormFamilyServeRecipients(formCode: string) {
    return PropertyFormYjsDal.getFormFamilyServeRecipients(formCode, this.metaBinder.get());
  }
  public getFormFamilyServeCombinedRecipients(formCode: string) {
    return PropertyFormYjsDal.getFormFamilyServeCombinedRecipients(formCode, this.metaBinder.get());
  }

  public static getFormFamily(formCode: string, state?: TransactionMetaData) {
    if (!state) return undefined;

    const formDefn = FormTypes[formCode];
    const fam = formDefn?.formFamily;
    if (!fam) return undefined;

    return state.formStates?.[fam];
  }

  //formId used to be needed when recipients were in the form instance
  public static getServeRecipientFromState(formCode: string, formId: string, recipientId: string, state: TransactionMetaData) {
    return this.getFormFamilyServeRecipients(formCode, state)?.find(r => r.id === recipientId);
  }

  public getServeRecipient(formCode: string, formId: string, recipientId: string) {
    return PropertyFormYjsDal.getServeRecipientFromState(formCode, formId, recipientId, this.metaBinder.get());
  }

  public setFormOrdered({ formCode, formId, jobId, fillerManagedSigning, orderInfo, notificationEmail }: {
    formCode: string,
    formId: string,
    jobId: number,
    fillerManagedSigning: boolean,
    orderInfo: FormInstanceOrder['info'],
    notificationEmail: string
  }) {
    const formType = FormTypes[formCode];
    const familyCode = formType.formFamily;
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set form ordered',
        fn: state => {
          const instances = ensureFormStatesInstances(state, familyCode);
          const instance = instances.find(i => i.id === formId);
          if (!instance) return false;
          if (!instance.order) return false;
          if (instance.order.state !== FormOrderState.ClientOrdering) return false;

          archiveFormInstancesOfTypes(state, instances, formType.archiveSiblingTypesOnCreate, instance.id);

          instance.order.state = FormOrderState.ThirdPartyPreparing;
          instance.order.job = {
            id: jobId,
            requestedAtMs: Date.now(),
            fillerManagedSigning
          };
          instance.order.info = orderInfo;
          instance.order.notificationEmail = notificationEmail;
        }
      }]
    });
  }

  public setSearchesOrdered({ formCode, formId }: {
    formCode: string,
    formId: string
  }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set searches ordered',
        fn: state => {
          const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!instance) return false;
          if (!instance.order) return false;
          instance.order.info.waitForSearches = false;
        }
      }]
    });
  }

  public setCcTeamStats({ formCode, formId, typesMap }: { formCode: string, formId: string, typesMap: Map<string, ('email'|'phone')[]> }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set cc team stats',
        fn: state => {
          const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!instance?.signing?.session) return;
          for (const [ccId, types] of typesMap.entries()) {
            const cc = instance.cc?.find(x => x.id === ccId);
            if (cc?.type !== 'team') continue;
            cc.stats = {
              count: types.length,
              types: [...new Set(types)]
            };
          }
        }
      }]
    });
  }

  public setDelayOnCurrentSession({ formCode, formId }: { formCode: string, formId: string }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set delay requested on signing session after signing',
        fn: state => {
          const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!instance?.signing?.session) return false;
          instance.signing.session.delayAcknowledged = true;
        }
      }]
    });
  }

  public setDelayFulfilledOnCurrentSession({ formCode, formId }: { formCode: string, formId: string }) {
    return applyMigrationsV2<TransactionMetaData>({
      doc: this.doc,
      docKey: this.metaRootKey,
      typeName: 'Property',
      migrations: [{
        name: 'set documents distributed after delay',
        fn: state => {
          const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, state);
          if (!instance?.signing?.session) return false;
          instance.signing.session.delayFulfilled = true;
        }
      }]
    });
  }
}

export function getCompletedFiles(signingSession?: SigningSession): FileRef[] {
  if (!signingSession?.completedFile) return [];
  if (Array.isArray(signingSession.completedFile)) return signingSession.completedFile;
  return [signingSession.completedFile];
}

export function getCertifiedCompletedDocument(signingSession?: SigningSession): FileRef|undefined {
  return signingSession?.certifiedDocument;
}

export function getInvolvedSigningParties(signing?: FormInstanceSigning) {
  return signing?.parties?.filter(p => !p.ignoreForSigning) || [];
}

export function getFirstOrderedParty(parties: SigningParty[], signing: Pick<FormInstanceSigning, 'useSigningOrder' | 'signingOrderSettings' | 'signingOrderVersion'> | undefined) {
  if (!signing?.useSigningOrder) return undefined;
  if (!parties.length) return undefined;

  switch (getSigningOrderVersion(signing)) {
    case SigningOrderVersion.Flat:
      return [...parties].sort(byMapperFn(p => p.signingOrderSettings?.order ?? 99999))[0];
    case SigningOrderVersion.Grouped:
    default:{
      const settingsItems = [...(signing.signingOrderSettings || [])]
        .sort(byMapperFn(item => item.order));
      for (const item of settingsItems) {
        const partiesOfType = parties.filter(party => item.type === mapSigningPartySourceTypeToCategoryRespectingOverride(party.source));
        if (item.partyOrder) {
          partiesOfType.sort(byMapperFn(party => party.signingOrderSettings?.order ?? 99999));
        }
        const first = partiesOfType.at(0);
        if (first) {
          return first;
        }
      }

      return parties[0];
    }
  }
}

export function getOrderedParties(parties: SigningParty[], signing: Pick<FormInstanceSigning, 'useSigningOrder' | 'signingOrderSettings' | 'signingOrderVersion'> | undefined, formCode: FormCodeUnion): SigningParty[] {
  if (!signing?.useSigningOrder) return parties;
  if (!parties.length) return parties;

  switch (getSigningOrderVersion(signing)) {
    case SigningOrderVersion.Flat:
      return [...parties].sort(byMapperFn(party => party.signingOrderSettings?.order ?? 99999));
    case SigningOrderVersion.Grouped:
    default: {
      const sorted: SigningParty[] = [];
      const added = new Set<string>();
      // issue: if the users have never been re-ordered, there are no order settings
      // a default order should be assigned based on FormType setting
      const formTypeParties = FormTypes[formCode]?.parties || [];
      const settingsItems = !signing.signingOrderSettings?.length && formTypeParties.length
        ? formTypeParties.map<SigningOrderSettingsItem>((p, index) => ({
          id: uuidv4(),
          type: p.type,
          auto: false,
          order: index,
          partyOrder: p.type === 'other'
        }))
        : [...(signing.signingOrderSettings || [])]
          .sort(byMapperFn(item => item.order));
      for (const item of settingsItems) {
        const partiesOfType = parties.filter(party => item.type === mapSigningPartySourceTypeToCategoryRespectingOverride(party.source));
        if (item.partyOrder) {
          partiesOfType.sort(byMapperFn(party => party.signingOrderSettings?.order ?? 99999));
        }

        for (const party of partiesOfType) {
          if (added.has(party.id)) continue;
          added.add(party.id);
          sorted.push(party);
        }
      }

      return sorted;
    }
  }
}

export enum PreviewType {
  none = 0,
  intermediate = 1,
  completed = 2
}

export interface PreviewFile {
  id?: string;
  type: PreviewType;
  cover?: boolean;
  actions?: UploadPdfAction[];
  name?: string;
}

export interface PreviewFiles {
  files: PreviewFile[];
  fill: boolean,
  buster: string,
  buildNew?: boolean,
  buildCoverPage?: boolean,
  applyActions?: boolean,
  customCover?: { id: string, code: FormCodeUnion},
  fullyMergedPdf?: PreviewFile;
}

// future: move to common hooks folder, maybe rename to usePdfPreview.ts
function getIntermediateFile(signing: FormInstanceSigning, preferLatestIntermediate?: boolean) {
  return preferLatestIntermediate
    ? (signing.session?.intermediateFiles || []).at(-1) || signing.session?.file
    : signing.session?.file;
}

/**
 *
 * @param form
 * @param preferLatestIntermediate Only used if currently in a signing state, not signed
 * @returns
 */
export function determinePreviewFiles(form: FormInstance | undefined, preferLatestIntermediate?: boolean): PreviewFiles {
  const signing = form?.signing;
  const coverSheetMode = getCoverSheetMode(form);

  //this is a released form, only show the PDF snapshot
  if (form?.order?.pdfPreview && form?.order?.type === FormOrderType.Filler && form?.order?.state === FormOrderState.ReturnedToClient) {
    return {
      fill: false,
      buster: '',
      files: [{ id: form?.order?.pdfPreview?.id, type: PreviewType.completed }],
      buildCoverPage: false
    };
  }

  switch (signing?.state) {
    case FormSigningState.Signed: {
      const completedFiles = getCompletedFiles(signing.session);
      return completedFiles.length
        ? {
          fill: false,
          buster: '',
          files: completedFiles.map(c => ({ id: c.id, type: PreviewType.completed })),
          buildCoverPage: coverSheetMode === CoverSheetMode.Standard,
          fullyMergedPdf: signing.session?.certifiedDocument
            ? {
              id: signing.session.certifiedDocument.id,
              type: PreviewType.completed
            }
            : undefined
        }
        : { fill: false, buster: '', files: [] };
    }
    case FormSigningState.SignedPendingUpload:
    case FormSigningState.SignedPendingDistribution:
    case FormSigningState.OutForSigning:
    case FormSigningState.OutForSigningPendingUpload:
    case FormSigningState.OutForSigningPendingServerProcessing: {
      const parties = signing.parties || [];
      const wetFileIds = new Set(parties.map(p => p.signedPdf?.id).filter(Predicate.isNotNull));
      const wetFiles = [...wetFileIds.keys()].map(id => ({ id, type: PreviewType.completed }));
      return {
        files: [{
          id: getIntermediateFile(signing, preferLatestIntermediate)?.id,
          type: PreviewType.intermediate
        }].concat(wetFiles),
        fill: true,
        buster: JSON.stringify(parties.map(p => p.signedTimestamp || 0))
      };
    }
    case FormSigningState.Configuring:
    case FormSigningState.None:
    default:
      return form?.formCode === FormCode.UploadedDocument || form?.formCode === ExtraFormCode.Form1Upload
        ? {
          files: getFormUploadRefs(form).map<PreviewFile>(f => ({
            id: f.id,
            type: PreviewType.completed,
            cover: f.cover,
            action: f.actions,
            name: f.name
          })),
          buildCoverPage: false,
          fill: false,
          buster: '',
          buildNew: false,
          applyActions: true
        }
        : {
          files: [],
          fill: false,
          buster: '',
          buildNew: true,
          customCover: form?.cover?.mode === CoverSheetMode.Custom && form.cover.custom?.file
            ? {
              code: FormCode.UploadedDocument,
              ...form.cover.custom.file
            }
            : undefined
        };
  }
}

export function generateDocumentDropdownInfo(docNumber: number, docCount: number, fileId: string | undefined, parties: SigningParty[]): { text: string, signingPartyType?: SigningPartyType, signers: SigningParty[] } {
  const signersMatchedByFileId = parties.filter(p => p.signedPdf?.id && p.signedPdf.id === fileId);
  const signers = signersMatchedByFileId.length
    ? signersMatchedByFileId
    // if it wasn't wet-signed, then it must be the common digital signed doc.
    : parties.filter(p => p.signedTimestamp && p.type !== SigningPartyType.SignWet);
  const names = signers.map(p => p.snapshot?.name).filter(Predicate.isNotNull).join(', ') || 'unsigned';

  return {
    signingPartyType: signers[0]?.type,
    text: `${docNumber} of ${docCount} - ${names}`,
    signers
  };
}

export interface IPartyDetailPaths {
  /**
   * name, email, phone can vary based on party source
   */
  data: {
    base: string;
    name: string;
    email: string;
    phone: string;
    partyType?: string;
    authority?: string;
    // update: MaybeUpdateFn<MaterialisedPropertyData>
  },
  meta: {
    base: string;
  }
}

/**
 * keep in sync with SigningPartyConfiguration.tsx.
 * future: remove the copy in SigningPartyConfiguration.tsx.
 */
export function getPartyDetailPaths(
  signingParty: SigningParty,
  propertyData: Maybe<MaterialisedPropertyData>,
  formInstancePath: string,
  ignoreRemoving?: boolean
): Maybe<IPartyDetailPaths> {

  if (!propertyData) {
    return undefined;
  }

  const metaPath = `${formInstancePath}.signing.parties.[${signingParty.id}]`;
  const isRemoving = !ignoreRemoving && signingParty.source.isRemoving;
  switch (signingParty.source.type) {
    case SigningPartySourceType.Salesperson:
      return getSalespersonPaths(signingParty, propertyData, metaPath, isRemoving);
    case SigningPartySourceType.Vendor:
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'fullLegalName',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'vendors', isRemoving);
    case SigningPartySourceType.VendorFirstParty:
      // Deprecated. Persists for compatibility with old sessions. Same for all other First|Second
      // types
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName1',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'vendors', isRemoving);
    case SigningPartySourceType.VendorSecondParty:
      // Deprecated. Persists for compatibility with old sessions.
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName2',
          email: 'email2',
          phone: 'phone2',
          partyType: 'partyType',
          authority: 'authority'
        }, 'vendors', isRemoving);
    case SigningPartySourceType.Purchaser:
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'fullLegalName',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'purchasers', isRemoving);
    case SigningPartySourceType.PurchaserFirstParty:
      // Deprecated. Persists for compatibility with old sessions.
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName1',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'purchasers', isRemoving);
    case SigningPartySourceType.PurchaserSecondParty:
      // Deprecated. Persists for compatibility with old sessions.
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName2',
          email: 'email2',
          phone: 'phone2',
          partyType: 'partyType',
          authority: 'authority'
        }, 'purchasers', isRemoving);
    case SigningPartySourceType.Other:
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'fullLegalName',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'otherContacts', isRemoving);
    case SigningPartySourceType.OtherFirstParty:
      // Deprecated. Persists for compatibility with old sessions.
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName1',
          email: 'email1',
          phone: 'phone1',
          partyType: 'partyType',
          authority: 'authority'
        }, 'otherContacts', isRemoving);
    case SigningPartySourceType.OtherSecondParty:
      // Deprecated. Persists for compatibility with old sessions.
      return getPartyPaths(signingParty, propertyData, metaPath,
        {
          name: 'personName2',
          email: 'email2',
          phone: 'phone2',
          partyType: 'partyType',
          authority: 'authority'
        }, 'otherContacts', isRemoving);
  }
}

function getSalespersonPaths(
  signingParty: SigningParty,
  data: MaterialisedPropertyData,
  metaPath: string,
  isRemoving?: boolean
): Maybe<IPartyDetailPaths> {
  const basePathBase = signingParty.source.isAuthRep
    ? 'authRep'
    : 'agent';
  const sourceAgentArray = signingParty.source.isAuthRep
    ? data.authRep
    : data.agent;
  for (const agent of sourceAgentArray || []) {
    for (const salesperson of agent.salesp || []) {
      if (salesperson.id === signingParty.source.id) {
        return {
          data: {
            base: isRemoving
              ? `${metaPath}.snapshot`
              : `${basePathBase}.[${agent.id}].salesp.[${salesperson.id}]`,
            name: 'name',
            email: 'email',
            phone: 'phone'
          },
          meta: {
            base: metaPath
          }
        };
      }
    }
  }

  return undefined;
}

function getPartyPaths(
  signingParty: SigningParty,
  data: MaterialisedPropertyData,
  metaPath: string,
  dataPropertyKeys: {
    name: string,
    email: string,
    phone: string,
    partyType?: string,
    authority?: string
  },
  typePathKey: 'vendors' | 'purchasers' | 'otherContacts' = 'vendors',
  isRemoving?: boolean
): Maybe<IPartyDetailPaths> {
  if (!data[typePathKey]) {
    return undefined;
  }
  if (signingParty.source.representationHierarchy && signingParty.source.representationHierarchy.at(0)?.accessKey !== typePathKey) return undefined;

  const expectedBasePath = signingParty.source.representationHierarchy?.length > 0
    ? signingParty.source.representationHierarchy.map(segment => {
      if (['dualPartyPseudoLevel', 'singlePartyRep', 'legalRepresentatives'].includes(segment.accessKey)) return;
      return `${segment.accessKey}.[${segment.itemId ?? segment.position}]`;
    }).filter(Predicate.isNotNull).join('.')
    : `${typePathKey}.[${signingParty.source.id}]`;

  if (!getValueByPath(expectedBasePath, data, true)) {
    return undefined;
  }
  const dataKeys = structuredClone(dataPropertyKeys);
  if (!isRemoving && signingParty.source.representationHierarchy?.length > 0) {
    const rh = signingParty.source.representationHierarchy;
    if (rh.length > 1) {
      // While there are only 3 possible levels, this should be fine
      const endNode = rh[rh.length-1];
      // These 3 values should be the special nodes that we actually need to adjust what the data
      // paths actually are
      if (endNode.accessKey === 'legalRepresentatives') {
        const nextBase = `legalRepresentatives.[${endNode.itemId??endNode.position}]`;
        dataKeys.name = `${nextBase}.name`;
        dataKeys.phone = `${nextBase}.phone`;
        dataKeys.email = `${nextBase}.email`;
      } else if (
        (endNode.accessKey === 'dualPartyPseudoLevel' && endNode.position === 0)
        || endNode.accessKey === 'singlePartyRep'
      ) {
        dataKeys.name = 'personName1';
        dataKeys.phone = 'phone1';
        dataKeys.email = 'email1';
      } else if (endNode.accessKey === 'dualPartyPseudoLevel' && endNode.position === 1) {
        dataKeys.name = 'personName2';
        dataKeys.phone = 'phone2';
        dataKeys.email = 'email2';
      }
    }
  }

  return {
    data: {
      base: isRemoving ? `${metaPath}.snapshot` : expectedBasePath,
      ...dataKeys
    },
    meta: {
      base: metaPath
    }
  };
}

export function ensureFormStatesInstances(draft: TransactionMetaData, familyCode: FormCodeUnion): FormInstance[] {
  if (!draft.formStates || typeof draft.formStates !== 'object') {
    draft.formStates = {};
  }

  const formStates = draft.formStates;
  if (!formStates[familyCode]) {
    formStates[familyCode] = {};
  }

  const formState = formStates[familyCode];
  if (!formState.clauseChildId) {
    formState.clauseChildId = v4();
  }
  if (!Array.isArray(formState.instances)) {
    formState.instances = [];
  }
  if (!Array.isArray(formState.recipients)) {
    formState.recipients = [];
  }

  return formState.instances;
}

export function rsaaExecuted(meta?: TransactionMetaData): boolean {
  return rsaaFamilyExecuted(meta?.formStates?.[FormCode.RSAA_SalesAgencyAgreement]);
}

export function rsaaFamilyExecuted(family: FormFamilyState | undefined) {
  return family?.instances?.at(0)?.signing?.state === FormSigningState.Signed;
}

export function archiveFormInstancesOfTypes(meta: TransactionMetaData | undefined, instances: FormInstance[], types: FormCodeUnion[] | undefined, skipId?: string | undefined, timestamp?: number) {
  if (!meta || !types?.length) return;
  for (const instance of instances) {
    if (instance.archived) continue;
    if (skipId && instance.id === skipId) continue;
    if (!types.includes(instance.formCode)) continue;

    console.log('archiving instance', instance.formCode, instance.id);

    //archive php document if there is one
    if (instance.subscription?.documentId) {
      AjaxPhp.archiveDocument(instance.subscription?.documentId);
    }

    //cancel signing if configuring or out for signing
    if ([FormSigningState.Configuring, ...SigningStates].includes(instance.signing?.state??FormSigningState.None) && instance.signing) {
      instance.signing.state = FormSigningState.None;
      FormUtil.clearSigningSession(instance);
      FormUtil.clearSigningPartyResponses(instance.signing);
    }

    const family = FormTypes[instance.formCode].formFamily;
    const recipients = meta.formStates?.[family]?.recipients;

    //save a copy of recipients - and clear their serving data
    for (const recipient of recipients||[]) {
      if (!recipient.servedInstances) recipient.servedInstances = {};
      recipient.servedInstances[instance.id] = omit(recipient, 'servedInstances');
      resetRecipient(recipient);
    }

    instance.archived = true;
    instance.dataModified = timestamp || Date.now();
  }
}

export function resetRecipient(recipient: ServeStateRecipient) {
  recipient.signingSessionId = '';
  recipient.lastServedSigningSessionId = '';
  recipient.servedBy = undefined;
  recipient.manuallyServed = undefined;
  recipient.viewedOnline = undefined;
  recipient.servedByEmail = undefined;
  recipient.servedBySms = undefined;
  recipient.portal = undefined;
  recipient.lastEmailEvent = undefined;
  recipient.downloaded = undefined;
  recipient.publishedDocumentId = undefined;
}

export function restoreArchivedForm(meta: TransactionMetaData | undefined, formCode: FormCodeUnion, formId: string) {
  if (!meta) return;
  const formType = FormTypes[formCode];
  const family = formType.formFamily;
  const instances = ensureFormStatesInstances(meta, family);

  const instance = instances.find(i => i.id === formId);
  if (!instance) return;
  const now = Date.now();
  archiveFormInstancesOfTypes(meta, instances, formType.archiveSiblingTypesOnCreate, formId, now);

  instance.archived = false;
  //Add 1ms to unarchived timestamp so that it is processed later
  instance.dataModified = now+1;
}

export function reServeRecipients(meta: TransactionMetaData | undefined, formCode: FormCodeUnion, recipientIdFilter: string[]) {
  if (!meta) return;
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(formCode, meta)?.filter(r => recipientIdFilter.includes(r.id));
  for (const recipient of recipients??[]) {
    recipient.forceReServe = true;
    recipient.timestamp = Date.now();
  }
}

export function unarchiveRecipients(meta: TransactionMetaData | undefined, formCode: FormCodeUnion, formId: string, recipientIdFilter: string[]) {
  if (!meta) return;
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(formCode, meta)?.filter(r => recipientIdFilter.includes(r.id));
  recipients?.filter(r => r.servedInstances?.[formId])?.forEach((recipient, idx) => {
    recipients.splice(idx, 1, {
      ...recipient.servedInstances[formId],
      servedInstances: recipient.servedInstances
    });
  });
}

export function setAutoServeRecipeints(meta: TransactionMetaData | undefined, formCode: FormCodeUnion, recipientIdFilter: string[]) {
  if (!meta) return;
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(formCode, meta);

  recipients?.forEach(recipient => {
    recipient.autoServe = recipientIdFilter?.includes(recipient.id);
  });
}

/**
 * Field can be considered as signed[/filled]
 */
export function fieldIsSigned(field: Pick<SigningSessionField, 'type' | 'file' | 'isWetSigned' | 'text'>): boolean {
  switch (field.type) {
    case SigningSessionFieldType.Radio:
    case SigningSessionFieldType.Check:
      return Boolean(field.text);
    case SigningSessionFieldType.Text:
      return Boolean(field.text != null);
    case SigningSessionFieldType.Signature:
    case SigningSessionFieldType.Initials:
      return Boolean(field.file || field.isWetSigned);
  }
}
