import {
  AuthorityParty, ExtraFormCode,
  InstanceHistory,
  MaterialisedPropertyData,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { SignatureSection } from '@property-folders/common/util/pdfgen/sections/signatureSection';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Binder } from 'immer-yjs/src/immer-yjs';
import { FormUtil } from '@property-folders/common/util/form';
import { Maybe } from '@property-folders/common/types/Utility';
import { propertyFolderMaskDataNotRelevant, transformPropertyFolderDataForDisplay } from '@property-folders/common/util/pdfgen/display-transformations';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';

import { FormTypes } from '@property-folders/common/yjs-schema/property/form';

import { getValueByPath } from '@property-folders/common/util/pathHandling';
import { find } from 'lodash';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { mergeSigningMaps } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { salesAgreementVariation } from '@property-folders/common/util/pdfgen/definitions';
import { CustomObjects, DefinitionMode, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { BelongingEntityMeta } from '../../../../redux-reducers/entityMeta';

const thisFormCode = ExtraFormCode.AAV_SalesAgencyAgreementVariation;
const title = FormTypes[thisFormCode].label;

type PartyComparisonFunction = <T extends { id: string }>(oldVal: T, newValT: T) => boolean;

const idCompare: PartyComparisonFunction = (ov, nv) => {
  return ov.id === nv.id;
};

export function filterForRemovingParties<T extends { id: string }>(oldPartyList: T[], newPartyList: T[], overrideCompare?: PartyComparisonFunction) {
  const result = oldPartyList.filter(old => !find(newPartyList, np => (overrideCompare ?? idCompare)<T>(old, np)));
  return result;
}

function filterForAddedParties<T extends { id: string }>(oldPartyList: T[], newPartyList: T[], overrideCompare?: PartyComparisonFunction) {
  const result = newPartyList.filter(newP => !find(oldPartyList, op => (overrideCompare ?? idCompare)<T>(op, newP)));
  return result;
}

function filterForExistingParties<T extends { id: string }>(oldPartyList: T[], newPartyList: T[], overrideCompare?: PartyComparisonFunction) {
  const result = newPartyList.filter(newP => find(oldPartyList, op => (overrideCompare ?? idCompare)<T>(op, newP)));
  return result;
}

export function preparePartySigningDiff(path: PathType, currentData?: MaterialisedPropertyData, lastSignedSnap?: MaterialisedPropertyData, sourceType: SigningPartySourceType = SigningPartySourceType.Error, replacementCompare?: PartyComparisonFunction) {
  const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
  const removingPartyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
  const addedPartyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
  const currentPartyData = getValueByPath(path, currentData ?? {}, true) ?? [];
  const previousPartyData = getValueByPath(path, lastSignedSnap ?? {}, true) ?? [];
  if (lastSignedSnap) {
    removingPartyMap.set(sourceType, filterForRemovingParties(previousPartyData, currentPartyData, replacementCompare));
    addedPartyMap.set(sourceType, filterForAddedParties(previousPartyData, currentPartyData, replacementCompare));
    partyMap.set(sourceType, filterForExistingParties(previousPartyData, currentPartyData, replacementCompare));
  } else {
    partyMap.set(sourceType, currentPartyData);
  }
  const result = { removing: removingPartyMap, adding: addedPartyMap, existing: partyMap };
  return result;
}

export class SalesAgreementVariationPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<Binder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean
  ) {
  }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  getCoverPage() {
    return Promise.resolve(undefined);
  }

  async getCoverPageDefinitionForPdfWorker(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    lastSignedSnapData?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride: TransactionMetaData
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts??{};
    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }
    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const familyState = FormUtil.getFormFamilyState(this.formCode, meta);
    const formInstance = FormUtil.getFormState(this.formCode, this.formId, meta);
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formInstance) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const previousInstance = snapshotHistory?.instanceList?.[snapshotHistory.instanceList.length-1];

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const { adding: addingVendor, existing: existingVendor, removing: removingVendor } = preparePartySigningDiff('vendors', property, lastSignedSnapData, SigningPartySourceType.Vendor);
    const { adding: addingAgent, existing: existingAgent, removing: removingAgent } = preparePartySigningDiff(
      '',
      property?.agent?.map(agency=>(agency.salesp || []).map(sp=>({ ...sp, agency }))).flat()??[],
      lastSignedSnapData?.agent?.map(agency=>(agency.salesp || []).map(sp=>({ ...sp, agency }))).flat()??[],
      SigningPartySourceType.Salesperson,
      (oa,na)=>oa.linkedSalespersonId === na.linkedSalespersonId && oa.agency.linkedEntityId === na.agency.linkedEntityId);
    const existing = mergeSigningMaps(existingVendor, existingAgent);
    const removing = mergeSigningMaps(removingVendor, removingAgent);
    const adding = mergeSigningMaps(addingVendor, addingAgent);
    const normalisedSigners = mode === DefinitionMode.Signing
      ? await SignatureSection.buildSignersFromSigningSession(formInstance, propertyRaw, previousInstance)
      : await SignatureSection.buildSignersForPreview({
        authorityParties: existing,
        removedAuthorityParties: removing,
        addedAuthorityParties: adding,
        previousInstance,
        propertyData: propertyRaw,
        formCode: this.formCode,
        instHistory: snapshotHistory,
        previousData: lastSignedSnapData,
        memberEntities
      });
    return {
      brand,
      formType: 'SalesAgreementVariationPDF',
      annexureBlobs: [],
      signers: normalisedSigners,
      property,
      previousFormInstance: previousInstance,
      objects,

      propertyRaw,
      snapshotHistory,
      lastSignedSnapData,
      annexures,
      changeSet,
      noBoldContentMode: noBoldContentMode??false,
      memberEntities,
      formFamilyState: familyState,
      currentFormInstance: formInstance
    };
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    lastSignedSnapData?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions> {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      lastSignedSnapData,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );

    return salesAgreementVariation(definition);
  }
}
