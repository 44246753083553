import { Canvas, Image } from 'canvas';

export function removeCanvas(canvas: Canvas) {
  const asHtmlCanvas = canvas as unknown as HTMLCanvasElement;
  if ('remove' in asHtmlCanvas && typeof asHtmlCanvas.remove === 'function') {
    // cleanup phase
    asHtmlCanvas.remove();
  }
}

export function removeImage(image: Image) {
  const asHtmlImage = image as unknown as HTMLImageElement;
  if ('remove' in asHtmlImage && typeof asHtmlImage.remove === 'function') {
    asHtmlImage.remove();
  }
}
