import { eraseBackground } from './erase-background';
import { trimCanvas } from './trim-canvas';
import { scaleCanvas } from './scale-canvas';
import { createCanvas } from 'canvas';

const createImage = (url: string): any => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });
};

const useProcessSignature = () => {
  const getProcessedImage = async (imageSrc: string): Promise<string> => {
    const image = await createImage(imageSrc);
    const canvas = createCanvas(image.width, image.height);
    const ctx = canvas.getContext('2d');// as any;

    ctx.drawImage(image, 0, 0);
    eraseBackground(canvas, ctx);
    trimCanvas(canvas, ctx);

    if (canvas.height > 150) {
      scaleCanvas(canvas, 150 / canvas.height);
    }

    // return await exportFromCanvas(canvas);
    return canvas.toDataURL();
  };
  return { getImage: getProcessedImage };
};

export default useProcessSignature;
