import { MaterialisedPropertyData } from '@property-folders/contract';
import { MatchesExpectationRules } from '@property-folders/contract/yjs-schema/model';
import { CustomValidationReturnType } from '@property-folders/contract/yjs-schema/model/custom-point-validator';
import { processExpectedValue } from '../process-validation';

export function matchesExpectations(
  nodeTree: MaterialisedPropertyData,
  updatedPath: string[] | undefined,
  params?: {rules: MatchesExpectationRules}
): CustomValidationReturnType {
  if (!params?.rules) {
    return ['badDefinition'];
  }

  const expectationsMet = processExpectedValue({ ...params.rules, ...{ mode: 'self' } }, updatedPath, nodeTree);
  if (expectationsMet) return [];
  return ['expectationsNotMet'];
}